window.Bizneo.recruitment_process_external_status_matchings_controller = {
  index() {
    window.Bizneo.recruitment_process_external_status_matchings.fetch_phases();
    window.Bizneo.recruitment_process_external_status_matchings.fetch_statuses();
  },
};

jQuery(() => {
  if ($('.recruitment-process-external-status-matchings-controller-js').length) {
    window.Bizneo.recruitment_process_external_status_matchings_controller.index();
  }
});
