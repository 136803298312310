function bizSearch() {
  $(document).on('click', '#country_select', (e) => {
    e.stopPropagation();
  });

  $(document).on('change', '.biz-search', (e) => {
    e.stopPropagation();
  });

  $(document).on('keyup', '.biz-search', (e) => {
    const strSearch = $(e.target).val();

    $(e.target).data('target').each((index, element) => {
      if (element.innerHTML.toLowerCase().indexOf(strSearch) === -1) {
        $(element).parent().hide();
      } else {
        $(element).parent().show();
      }
    });
  });
}

function changeCountry() {
  $(document).on('click', '.select-offer-permission', (e) => {
    e.preventDefault();

    $('#country').val($(e.target).closest('.select-offer-permission').data('toggle'));
    $('#country-form').submit();
  });
}

export function dropdownSearch() {
  bizSearch();
  changeCountry();
}

export function startPublishing() {
  $('#start-publishing-btn').attr('disabled', false);
  $(document).on('click', '#start-publishing-btn', (e) => {
    const selectProfileName = $('.headhunter-portals-index.portals-offer').data('select-profile-name');
    $('.profile_name').each((index, element) => {
      if (element.value === '') {
        // eslint-disable-next-line no-alert
        alert(selectProfileName);
        e.preventDefault();
      }
    });

    const selectPublicationOptions = $('.headhunter-portals-index.portals-offer').data('select-publication-options');
    $('.publication_option').each((index, element) => {
      if (element.value === '') {
        // eslint-disable-next-line no-alert
        alert(selectPublicationOptions);
        e.preventDefault();
      }
    });

    const noAvailableOffers = $('.headhunter-portals-index.portals-offer').data('no-available-offers');
    $('.no-external-offers-left').each(() => {
      // eslint-disable-next-line no-alert
      alert(noAvailableOffers);
      e.preventDefault();
    });

    const failedLoginMessage = $('.headhunter-portals-index.portals-offer').data('failed-login-message');
    $('.failed-account-login').each(() => {
      const portal = $(this).parent().parent().children()
        .attr('title');
      const message = failedLoginMessage.replace('%{portal}', portal);
      // eslint-disable-next-line no-alert
      alert(message);
      e.preventDefault();
    });

    let requiredFieldsNotFilled = 0;
    $(".headhunter_job_publication-options-list [required='required']").each((index, element) => {
      if (element.value === '') {
        requiredFieldsNotFilled += 1;
      }
    });

    if (requiredFieldsNotFilled > 0) {
      const requiredSettingsMissing = $('.headhunter-portals-index.portals-offer').data('required-settings-missing');
      // eslint-disable-next-line no-alert
      alert(requiredSettingsMissing);
      e.preventDefault();
    }

    if ($('.validation-info-container').length > 0) {
      const failedPublicationsValidation = $('.headhunter-portals-index.portals-offer').data('failed-publications-validation');
      // eslint-disable-next-line no-alert
      alert(failedPublicationsValidation);
      e.preventDefault();
    }

    const ids = [];
    $('.landing-redhh-btn').each((index, element) => {
      ids.push($(element).parent().attr('id').split('-')[1]);
    });
    $('#portal_ids').val(ids);
  });
}

window.Bizneo.headhunter_job = {
  prevent_get_away_of_view(canPublish) {
    if ((canPublish && $('.landing-redhh-btn').length) || (canPublish && $('.button.danger').length)) {
      window.onbeforeunload = () => '';
    }

    $('.publish-form').submit(() => {
      window.onbeforeunload = null;
    });
  },
  might_lose_data() {
    window.onbeforeunload = () => '';
  },
};

$(document).on('change', '#accounts-list select', (e) => {
  if ($(e.target).val() !== '') {
    $('.loading-background-dark').show();
    $('.modal-content form.affiliated-body').submit();
    $(document).off('change', '#accounts-list select');
  }
});
