function categoryButtonSubmit() {
  const $inputName = $('#company_tag_category_name');
  const $parent = $inputName.closest('form');

  if ($inputName.val() !== '') {
    $parent.find(':submit').removeClass('is-disabled');
  } else {
    $parent.find(':submit').addClass('is-disabled');
  }
}

function updateSelectCompanyTagFields(companyTagId, companyTagName) {
  if ($(`#company_tag_category_company_tags option[value=${companyTagId}]`).length > 0) {
    $(`#company_tag_category_company_tags option[value=${companyTagId}]`).removeAttr('disabled');
  } else {
    $('#company_tag_category_company_tags').append($('<option>', {
      value: companyTagId,
      text: companyTagName,
    }));
  }

  $('#company_tag_category_company_tags').val('').trigger('chosen:updated');
}

$(document).on('change', '#company_tag_category_name', (e) => {
  e.preventDefault();
  categoryButtonSubmit();
});

$(document).on('change', "select[name='company_tag_category[company_tags][]']", (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('form');
  if ($(e.target).val().length > 0) {
    $parent.find('.add-tags-buttons').removeClass('is-disabled');
  } else {
    $parent.find('.add-tags-buttons').addClass('is-disabled');
  }
});

$(document).on('click', '.add-tags-buttons', (e) => {
  e.preventDefault();

  $(e.target).addClass('is-disabled');
  const tagIds = $("select[name='company_tag_category[company_tags][]']").val();
  if (tagIds.length > 0) {
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: $('#add_tags_url').val(),
      data: { company_tag_categories: { tag_ids: tagIds } },
      success() {
        categoryButtonSubmit();
      },
      error() {},
    });
  }
});

$(document).on('click', '.action-company-tag-field-remove', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-form-item');
  const $companyTagId = $parent.find('input.company-tag-id').val();
  const $companyTagName = $parent.find('div.default-text').html().trim();
  $parent.remove();

  updateSelectCompanyTagFields($companyTagId, $companyTagName);
  categoryButtonSubmit();
});
