import { actions, reorder } from '../../features/companies/requisition_statuses';

window.Bizneo.requisition_statuses_controller = {
  index() {
    actions();
    reorder();
  },
};

jQuery(() => {
  if ($('.requisition-statuses-controller-js').length) {
    window.Bizneo.requisition_statuses_controller.index();
  }
});
