import { filter } from '../../../features/companies/requisitions';

window.Bizneo.requisition_review_workflows_controller = {
  search() {
    filter();
  },
};

jQuery(() => {
  if ($('.requisition-review-workflows-controller-js').length) {
    window.Bizneo.requisition_review_workflows_controller.search();
  }
});
