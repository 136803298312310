import {
  changeIframe, navReportsTabs,
} from '../../features/companies/custom_reports';

window.Bizneo.custom_reports_controller = {
  index() {
    changeIframe();
    navReportsTabs();
  },
};

jQuery(() => {
  if ($('.custom-reports-controller-js').length) {
    window.Bizneo.custom_reports_controller.index();
  }
});
