const SELECTOR = '.tabs.base-show .tab-item';

$(document).on('click', SELECTOR, (e) => {
  e.preventDefault();
  const $target = $(e.target).hasClass('tab-item') ? $(e.target) : $(e.target).closest('.tab-item');
  const idsTabContents = $target.closest('.tabs').find('.tab-item').removeClass('active').map((i, el) => $(el).attr('href'));
  $target.addClass('active');
  $.each(idsTabContents, (i, id) => {
    $(id).addClass('is-hidden');
  });
  $($target.attr('href')).removeClass('is-hidden');
});
