import loadOptions from '../../features/companies/recruiter_notifications';

window.Bizneo.recruiter_notifications_controller = {
  load() {
    loadOptions();
  },
};

jQuery(() => {
  if ($('.recruiter-notifications-controller-js').length) {
    window.Bizneo.recruiter_notifications_controller.load();
  }
});
