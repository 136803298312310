import '@rails/actioncable';
import { } from 'jquery-ujs';
import { } from 'remotipart';
import { } from 'chosen-js';
import 'jquery_nested_form';
import { } from './libs/jquery.highlight';
import Tagify from '@yaireo/tagify';

import './channels/consumer';
import './channels/index';

import './bizneo/controllers/global_vars';
import './bizneo/controls';

import './services/alert';
import './services/accordion';
import './services/contextual-menu';
import './services/dropdown';
import './services/modal';
import './services/modal_confirm';
import './services/modal_content';
import './services/search-filters';
import './services/tables';
import './services/tabs';
import './services/tooltip';
import './services/fetch_statuses';
import './services/reports';
import './services/custom_form_check';
import './services/pb-iframe';
import './services/link_select_account';
import './services/select_account';
import './services/publication_settings';
import './services/async_publication_change_status';
import './services/card_files_documents';
import './services/step_validation';
import './services/nested_link';
import './services/base_file_input';
import './services/selector';
import './services/zendesk';

import './bizneo/controllers/reports/report_charts_controller';
import './bizneo/controllers/reports/reports_controller';
import './bizneo/controllers/reports/activity';
import './bizneo/controllers/headhunter_jobs_controller';
import './bizneo/features/headhunter_job_action';
import './bizneo/features/setting_prototyping';
import './bizneo/controllers/recruitment_process_statuses_controller';
import './bizneo/controllers/companies/candidacy_statuses_controller';
import './bizneo/controllers/jobs_suggester_controller';
import './bizneo/controllers/locations_suggester_controller';
import './bizneo/controllers/companies/requisitions_controller';
import './bizneo/controllers/companies/requisitions/requisition_review_workflows_controller';
import './bizneo/controllers/companies/requisition_field_controller';
import './bizneo/controllers/companies/company_questions_controller';
import './bizneo/controllers/companies/scorecards_controller';
import './bizneo/controllers/companies/email_communications_controller';
import './bizneo/controllers/companies/pipelines_controller';
import './bizneo/features/companies/qualifiers';
import './bizneo/features/companies/recruitment_process_external_status_matchings';
import './bizneo/controllers/companies/recruitment_process_external_status_matchings_controller';
import './bizneo/controllers/companies/easy_recrue_controller';
import './bizneo/controllers/companies/whatsapp_business_controller';
import './bizneo/controllers/companies/company_teams_controller';
import './bizneo/controllers/companies/aggregations_controller';
import './bizneo/controllers/companies/legals_controller';
import './bizneo/controllers/companies/companies_external_accounts_controller';
import './bizneo/controllers/companies/custom_fields_controller';
import './bizneo/controllers/companies/recruitment_process_workflow_messages_controller';
import './bizneo/controllers/companies/conversations_controller';
import './bizneo/controllers/recruitment_sources_suggester_controller';
import './bizneo/controllers/companies/companies_channels_controller';
import './bizneo/controllers/companies/companies_feeds_controller';
import './bizneo/features/companies/custom_field_sections';
import './bizneo/controllers/companies/assessments_controller';
import './bizneo/features/companies/submit_company_question_response_set';
import './bizneo/controllers/companies/requisition_statuses_controller';
import './bizneo/controllers/companies/removed_resources_controller';
import './bizneo/controllers/companies/recruiter_notifications_controller';
import './bizneo/controllers/companies/custom_reports_controller';
import './bizneo/controllers/companies/campaigns_controller';
import './bizneo/controllers/companies/document_templates/variables_controller';
import './bizneo/controllers/reports/pass_rate_controller';

import './settings/candidate_registration_form';
import './settings/candidate_form';
import './settings/menu';
import './settings/requisition_review_workflow';
import './settings/sites';
import './settings/recruitment_group_form';
import './settings/prevent_form_out';
import './settings/companies/requisitions/phases/rules';
import './settings/companies/requisitions/phases/groups';
import './settings/company_tag_categories';
import './settings/legal_translations';
import './settings/shared_candidates_profiles';
import './settings/chat_bot';
import './settings/company_notification_message';
import './settings/document_templates';
import './settings/whatsapp_business/template';
import './settings/workflows/form';

jQuery(() => {
  $(document).trigger('dom-update');

  $(document).on('change', '.custom-form-check input:not([type=hidden])', (e) => {
    const $this = $(e.target);
    const $parent = $this.closest('.custom-form-check');
    $this.prop('checked') ? $parent.addClass('checked') : $parent.removeClass('checked');
  });

  $(document).on('click', '#new-integration-aws-ses-domain', (e) => {
    e.preventDefault();
    $('#aws-ses-domain-integration-form').toggleClass('is-hidden');
  });

  $(document).on('click', '.show-video-input', (e) => {
    e.preventDefault();
    $(e.target).hide().closest('.video-input').find('.url')
      .removeClass('is-hidden');
  }).on('click', '.show-translations-inputs', (e) => {
    $(e.target).addClass('is-hidden');
    $('.translations-inputs').removeClass('is-hidden');
  });
});

$(document).on('dom-update', (e) => {
  $(e.target)
    .find('.input_email_action_external_emails:not(.tagify)')
    .each((_, element) => new Tagify(element, {
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/,
      editTags: false,
      templates: {
        tag(tagData) {
          return `<tag title="${(tagData.title || tagData.value)}"
                  contenteditable='false'
                  spellcheck='false'
                  tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
                  class="tag-item ${this.settings.classNames.tag} ${tagData.class ? tagData.class : ''}"
                  ${this.getAttributes(tagData)}>
                    <div>
                      <span class="${this.settings.classNames.tagText}">${tagData[this.settings.tagTextProp] || tagData.value}</span>
                    </div>
                    <button type="button" role='button' aria-label='remove tag' class="${this.settings.classNames.tagX}">×</button>
                  </tag>`;
        },
      },
    }));
});

window.Bizneo.dom = {
  update(e, data) {
    const d = data !== undefined ? data : {};
    $(e).trigger('dom-update', d);
  },
};

window.jq = {
  active() {
    return $.active;
  },
};

$(document).on('click', '.toggle-sidebar', (e) => {
  const $this = $(e.target);
  const $sidebar = $this.closest('.big-sidebar');
  $sidebar.toggleClass('closed');
  $sidebar.find('.main-filters').toggleClass('is-hidden');
});
