class RecruitmentSourceSuggester {
  constructor(suggester) {
    this.suggester = suggester;
    this.select = $(`.modal.active #${this.suggester.attr('id').replace('_chosen', '')}`);
    this.chosen_results = suggester.find('.chosen-results');
    this.chosen_search = suggester.find('.search-field input');
    this.chosen_choices = suggester.find('.chosen-choices');
    this.source_ids = $('.sources-wrapper input[name=\'recruitment_group[source_ids][]\']').map((i, el) => $(el).val()).get();
    this.selected_source_ids = [];
  }

  clearSelect() {
    this.select.html("<option value=''></option>");
    this.chosen_results.empty();
  }

  appendNewOption(position, source) {
    let optionDisabled = source.disabled ? "disabled='disabled'" : '';
    if (source.selected && this.source_ids.includes(source.id.toString())) {
      optionDisabled = "disabled='disabled'";
    }

    const optionSelected = source.selected ? 'selected' : '';
    this.select.append(`
      <option value='${source.id}' ${optionDisabled} ${optionSelected}>${source.name}</option>
    `);

    let chosenClass = 'active-result';
    if (source.disabled) {
      if (this.selected_source_ids.includes(source.id.toString())) {
        chosenClass = 'result-selected';
      } else {
        chosenClass = 'disabled-result';
      }
    }

    if (
      source.selected &&
      (this.source_ids.includes(source.id.toString()) ||
      this.selected_source_ids.includes(source.id.toString()))
    ) {
      chosenClass = 'disabled-result';
    }

    this.chosen_results.append(`
      <li class='${chosenClass}' data-option-array-index='${Number(position) + 1}'>
        ${source.name}
      </li>
    `);
  }
}

function selectedSourceIds(suggester) {
  const s = suggester;
  const modalSelectedSourceIds = $('.modal.active .recruitment-sources-suggester').val();
  if (modalSelectedSourceIds != null) {
    s.selected_source_ids = $.unique(s.selected_source_ids.concat(modalSelectedSourceIds));
  }
}

function getSuggestionsFor(suggester, value, forceUpdate = false) {
  const companyId = window.location.pathname.split('/')[2];
  const groupId = $('input[name=\'recruitment_group[group_id]\']').val();
  selectedSourceIds(suggester);

  const sourceIds = suggester.source_ids.concat(suggester.selected_source_ids);
  const uniqueSourceIds = sourceIds.filter((item, i, sites) => i === sites.indexOf(item));

  return $.ajax({
    type: 'POST',
    dataType: 'json',
    url: `/trabajar/${companyId}/recruitment/sources/suggest`,
    data: {
      sources: {
        name: value,
        group_id: groupId,
        selected_source_ids: uniqueSourceIds,
      },
    },
    success(result) {
      const {
        sources,
      } = result;
      suggester.clearSelect();

      $.each(sources, (index, val) => {
        suggester.appendNewOption(index, val);
      });

      suggester.chosen_results.find('.active-result').click(() => suggester.select.trigger('chosen:updated'));

      if (forceUpdate) {
        suggester.select.trigger('chosen:updated');
      }
    },
  });
}

function addSuggesterEvents(suggester) {
  suggester.chosen_search.keyup((e) => {
    if (
      (e.target.value !== undefined) &&
      (e.target.value !== null) &&
      (e.target.value.length >= 3)
    ) {
      getSuggestionsFor(suggester, e.target.value);
    }
  });
}

function initializeSuggesters() {
  $('.modal.active .chosen-container').each((index, element) => {
    const sourcesSuggester = new RecruitmentSourceSuggester($(element));
    addSuggesterEvents(sourcesSuggester);
    const $select = $(`#${$(element).attr('id').replace('_chosen', '')}`);
    if ($select.find('option:selected').text()) {
      $('.modal.active #recruitment_group_source_ids_chosen > a:nth-child(1) > span:nth-child(1)').text($select.find('option:selected').text());
    }
    getSuggestionsFor(sourcesSuggester, '', true);
  });
}

export default function initializeRecruitmentSourcesSuggesters() {
  initializeSuggesters();
}
