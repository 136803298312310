import { initReportChart, createReportChart } from './reports_charts';
import { datepicker } from '../../features/companies/requisitions';

window.Bizneo.reports_controller = {
  init_pipeline() {
    initReportChart();
  },
  pipeline_chart(selectedJob) {
    createReportChart(selectedJob);
  },
  special_fields() {
    datepicker();
  },
};

$(document).on('click', '.job-title', (event) => {
  event.preventDefault();
  const jobId = $(event.target).closest('tr').data('job');
  $('#report_job_id').val(jobId);
  $('#job-chart-form').trigger('submit');
});

jQuery(() => {
  if ($('.reports-controller-js').length) {
    window.Bizneo.reports_controller.init_pipeline();
  }

  if ($('.reports-page-pass_rate').length) {
    window.Bizneo.reports_controller.special_fields();
  }

  if ($('.reports-page-gender').length) {
    window.Bizneo.reports_controller.special_fields();
  }
});
