function fetchData(phaseId) {
  const companyId = $('.settings-nested > form').data().company;
  const statusId = $('#workflow_message_group_destination_status_id').val();

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: `/trabajar/${companyId}/recruitment_process/phases/${phaseId}/statuses`,
    success(result) {
      const emptyOption = "<option value=''></option>";
      const options = result.reduce((acc, data) => `${acc}<option value='${data[1]}'>${data[0]}</option>`, emptyOption);

      $('#workflow_message_group_destination_status_id').html(options);
      $('#workflow_message_group_destination_status_id').val(statusId);
      $('#workflow_message_group_destination_status_id').trigger('chosen:updated');
    },
  });
}

export default function fetchStatuses() {
  const $destinationPhaseId = $('#workflow_message_group_destination_phase_id');
  if ($destinationPhaseId.val().length) fetchData($destinationPhaseId.val());

  $destinationPhaseId.on('change', (e) => {
    const phaseId =
      e.target.value ? e.target.value : 0;
    fetchData(phaseId);
  });
}
