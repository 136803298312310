$(document).on('click', '.nested-link', (e) => {
  const $this = $(e.target);
  const $dropdown = $this.closest('.dropdown');
  const $localeError = $dropdown.find('.help-inline');
  const { locale } = $this.data();
  const $tabs = $this.closest('.settings-nested').find('.tabs-cnt').find('.tabs');
  const tabsLength = $tabs.find('.tab-item').length;
  const id = $('#messages').find('.fields:last-child').find('> div').attr('id');
  $tabs.append(`
    <a 
      class="tab-item nav-changer tab-nested-${tabsLength} active"
      data-locale="${locale}"
      href="#${id}">${$this.text()}
    </a>
  `);
  $(`.tab-nested-${tabsLength}`).trigger('click');
  $(`#${id}`).find('.locale-hidden-input').val(locale);
  $(`#${id}`).find('.active-shipping-status .checkbox input').attr('checked', true).trigger('change');
  $dropdown.find(`[data-locale='${$this.data('locale')}']`).addClass('is-hidden');
  if ($localeError.length) $localeError.hide();
}).on('click', '.remove_nested_fields', (e) => {
  const $this = $(e.target);
  const $tabsCnt = $this.closest('.settings-nested').find('.tabs-cnt');
  const type = $tabsCnt.data('type');
  const id = $this.closest('.tab-content').attr('id');
  const $thisTab = $(`[href="#${id}"]`);
  const $link = $tabsCnt.find('.dropdown-menu').find(`[data-locale="${$thisTab.data('locale')}"]`);

  if ($link.length) {
    $link.removeClass('is-hidden');
  } else {
    $tabsCnt.find('.dropdown-menu').append(`
      <a
        class="menu-item nested-link add_nested_fields this"
        data-target="#messages"
        data-locale="${$thisTab.data('locale')}"
        data-association="${type}"
        data-blueprint-id="${type}_fields_blueprint"
        href="javascript:void(0)">
          ${$thisTab.text()}
        </a>
      `);
  }
  $tabsCnt.find('.dropdown').show();
  $($thisTab.attr('href')).find('[required]').removeAttr('required');
  $thisTab.remove();
  $tabsCnt.find('.tabs').find('.tab-item:first-child').trigger('click');
}).on('change', '.default-locale-check input', (e) => {
  const $this = $(e.target);
  const isChecked = $this.is(':checked');
  const parentId = $this.closest('.tab-content').attr('id');
  const $tab = $(`[href='#${parentId}']`);

  isChecked ? $tab.addClass('default') : $tab.removeClass('default');
});
