$(document).on('click', 'a.delete-rule', (e) => {
  e.preventDefault();

  const ruleId = $(e.target).data('modal-id').split('_')[1];
  $(`.rule-item#rule-${ruleId}`).remove();

  if ($('.rules-wrapper .group-rules .rule-item').length === 0) $('.rules-wrapper .info.inner-card').removeClass('is-hidden');

  const option = $(`#select-group-rules option[value='${ruleId}']`);
  option.attr('selected', false);
});
