import dragula from 'dragula';

function resetRange(limitsContainer) {
  limitsContainer.addClass('is-hidden');
  limitsContainer.find('input').val('');
  limitsContainer.find('input').prop('disabled', true);
}

function resetOptions(optionsContainer) {
  optionsContainer.addClass('is-hidden');
  optionsContainer.find('.fields').remove();
}

function manageSections(element) {
  const selectedOption = $(element).find('option:selected');
  const radioType = selectedOption.data('range');
  const optionsType = selectedOption.data('options');
  const parent = $(element).closest('.form-inputs');
  const optionsContainer = parent.find('.new-field-options');
  const limitsContainer = parent.find('.new-field-range-limits');

  if (optionsType) {
    resetRange(limitsContainer);
    if (optionsContainer.find('.options').length === 0) {
      optionsContainer.find('.add-options').click();
      optionsContainer.find('.add-options').click();
    }
    optionsContainer.find('.row > .delete-link').slice(0, 2).remove();
    optionsContainer.removeClass('is-hidden');
  } else if (radioType) {
    resetOptions(optionsContainer);
    limitsContainer.find('input').prop('disabled', false);
    limitsContainer.removeClass('is-hidden');
  } else {
    resetOptions(optionsContainer);
    resetRange(limitsContainer);
  }
}

export function addNewOption() {
  $(document).on('click', '.new-field-options .add-options', () => {
    $('.new-field-options').trigger('dom-update');
  });
}

export function currentSelectType() {
  manageSections(document.getElementById('requisitions_field_kind'));
}

export function fieldTypeChange() {
  $(document).on('change', '#requisitions_field_kind', (e) => {
    manageSections(e.target);
  });
}

export function deleteAnswer() {
  $(document).on('click', '.new-field-options a.remove_nested_fields', (e) => {
    e.preventDefault();
    $(e.target).closest('.fields').remove();
  });
}

export function dragAndDop() {
  dragula([document.getElementsByClassName('requisition-fields-container')[0]], {
    invalid(el) {
      return $(el).hasClass('not_move');
    },
  // eslint-disable-next-line consistent-return
  }).on('drop', (el, container) => {
    const orderIds = [];

    $('.requisition-field', container).each((index, element) => {
      orderIds.push($(element).data('requisition-field-id'));
    });

    $.ajax({
      type: 'POST',
      url: $('#reorder_url').val(),
      data: { requisition_field_ids: orderIds },
    });
  });
}
