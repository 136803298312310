import dragula from 'dragula';

function updateSelectRegistrationFields($parent) {
  const key = $parent.find('input.key').val();
  const fieldType = $parent.find('input.field_type').val();
  const fieldValue = `${fieldType}__${key}`;

  $(`#select-candidate-registration-fields option[value=${fieldValue}]`).removeAttr('disabled');
  $(`#select-candidate-registration-fields option[value=${fieldValue}]`).removeAttr('selected');
  $('#select-candidate-registration-fields').val('').trigger('chosen:updated');
}

$(document).on('click', '.candidate-registration-form-item .action-field-optional', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-registration-form-item');
  $parent.find('input.required').val('false');
  $parent.find('.action-field-optional').addClass('is-hidden');
  $parent.find('.action-field-required').removeClass('is-hidden');
  $parent.find('span.required').addClass('is-hidden');
  $parent.find('span.optional').removeClass('is-hidden');
});

$(document).on('click', '.candidate-registration-form-item .action-field-required', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-registration-form-item');
  $parent.find('input.required').val('true');
  $parent.find('.action-field-optional').removeClass('is-hidden');
  $parent.find('.action-field-required').addClass('is-hidden');
  $parent.find('span.required').removeClass('is-hidden');
  $parent.find('span.optional').addClass('is-hidden');
});

$(document).on('click', '.candidate-registration-form-item .action-custom-field-remove', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-registration-form-item');
  $parent.addClass('is-hidden');
  $parent.find('input.destroy').val('1');

  $('form .system-fields-wrapper .candidate-registration-form-item').not('.is-hidden').each((index, element) => {
    $(element).find('input.position').val(index);
  });

  if ($('form .custom-fields-wrapper .candidate-registration-form-item').not('.is-hidden').length === 0) {
    $('.custom-fields p.info').show();
  }

  updateSelectRegistrationFields($parent);
});

$(document).on('click', '.candidate-registration-form-item .action-system-field-remove', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-registration-form-item');
  $parent.addClass('is-hidden');
  $parent.find('input.destroy').val('1');

  $('form .system-fields-wrapper .candidate-registration-form-item').not('.is-hidden').each((index, element) => {
    $(element).find('input.position').val(index);
  });

  if ($('form .system-fields-wrapper .candidate-registration-form-item').not('.is-hidden').length === 0) {
    $('.system-fields p.info').show();
  }

  updateSelectRegistrationFields($parent);
});

$(document).ready(() => {
  dragula([document.getElementsByClassName('custom-fields-wrapper')[0]]).on('drop', () => {
    $('form .custom-fields-wrapper .candidate-registration-form-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });
});

$(document).on('click', '.action-id-document-registration-form-remove', (e) => {
  e.preventDefault();
  $(e.target).closest('.candidate-registration-form-item').remove();
  $('#select-candidate-registration-fields option[value=id_document__id_document]').removeAttr('disabled');
  $('#select-candidate-registration-fields option[value=id_document__id_document]').removeAttr('selected');
  $('#select-candidate-registration-fields').val('').trigger('chosen:updated');
});
