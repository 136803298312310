async function requestStatus(statusUrl, publicationUrl) {
  const res = await fetch(statusUrl, { headers: { Accept: 'application/json' } });
  const data = await res.json();
  if (!data.finalStatus) {
    setTimeout(() => {
      requestStatus(statusUrl, publicationUrl);
    }, 5000);
  } else {
    window.$.ajax({
      url: publicationUrl,
      dataType: 'script',
      type: 'GET',
      processData: false,
      contentType: false,
    });
  }
}

window.requestPublicationChangeStatus = (statusUrl, publicationUrl) => {
  requestStatus(statusUrl, publicationUrl);
};
