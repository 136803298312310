import dragula from 'dragula';

function updateSelectSectionCustomFields(customFieldId, customFieldText) {
  if ($(`select#custom_field_id option[value=${customFieldId}]`).length === 1) {
    $(`select#custom_field_id option[value=${customFieldId}]`).removeAttr('disabled');
  } else {
    $('select#custom_field_id').append(new Option(customFieldText, customFieldId));
  }
  $('select#custom_field_id').val('').trigger('chosen:updated');
}

window.Bizneo.custom_field_sections = {
  reorder() {
    dragula([document.getElementById('custom-field-section-items')], {
      invalid(el, handle) {
        return handle.classList.contains('handle');
      },
    // eslint-disable-next-line consistent-return
    }).on('drop', (el, container) => {
      if (container) {
        const orderIds = [];
        $('.section-item', container).each((index, element) => {
          orderIds.push($(element).data('section-id'));
        });

        $.ajax({
          type: 'PUT',
          url: $('#reorder_url').val(),
          data: { sections: { ids: orderIds } },
          success() {},
          error() {},
        });
      }
    });
  },

  add_custom_field() {
    $(document).on('click', '#add-section-group', (e) => {
      e.preventDefault();
      const $this = $(e.target);
      const customFieldGroupId = $('#custom_field_id option:selected').val();

      let params = {};
      if ($this.data('section') === 'private-area') {
        params = { custom_field_group: { id: customFieldGroupId } };
      } else {
        params = { section_group: { custom_field_group_id: customFieldGroupId } };
      }

      if (customFieldGroupId !== '') {
        $.ajax({
          type: 'GET',
          url: $('#add_section_group_url').val(),
          data: params,
        });
      }
    });
  },

  remove_custom_field() {
    $(document).on('click', '.remove-custom-field-group', (e) => {
      e.preventDefault();
      let customFieldId = null;
      let customFieldText = '';

      const $this = $(e.target);
      const $link = $this.closest('a');
      const $parent = $this.closest('.section-group-item');

      if ($link.data('section') === 'private-area') {
        $parent.remove();
        if ($('#custom-field-groups .section-group-item').length === 0) {
          $('.custom_field_section div.info').removeClass('is-hidden');
        }
        customFieldId = $parent.find('input').val();
        customFieldText = $parent.find('.default-text').html();
      } else {
        $parent.addClass('is-hidden');
        $parent.find('input.destroy').val('1');

        $('#section-groups .section-group-item').not('.is-hidden').each((index, element) => {
          $(element).find('input.position').val(index);
        });

        if ($('#section-groups .section-group-item').not('.is-hidden').length === 0) {
          $('.custom_field_section div.info').removeClass('is-hidden');
        }
        customFieldId = $parent.find('input.custom_field_group_id').val();
        customFieldText = $parent.find('.default-text span').html();
      }

      updateSelectSectionCustomFields(customFieldId, customFieldText);
    });
  },

  reorder_custom_fields() {
    dragula([document.getElementById('section-groups')]).on('drop', () => {
      $('#section-groups .section-group-item').not('.is-hidden').each((index, element) => {
        $(element).find('input.position').val(index);
      });
    });
  },
};

jQuery(() => {
  if ($('.custom-field-sections-edit-js').length) {
    window.Bizneo.custom_field_sections.add_custom_field();
    window.Bizneo.custom_field_sections.remove_custom_field();
    window.Bizneo.custom_field_sections.reorder_custom_fields();
  }
  if ($('.custom-field-sections-edit-private-js').length) {
    window.Bizneo.custom_field_sections.add_custom_field();
    window.Bizneo.custom_field_sections.remove_custom_field();
  }
  if ($('.custom-field-sections-index-js').length) {
    window.Bizneo.custom_field_sections.reorder();
  }
});
