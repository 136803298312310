export const locale = document.documentElement.lang;

function fallback(params, msgid, msgidPlural) {
  const replaceParams = (msg) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        // eslint-disable-next-line no-param-reassign
        msg = msg.replace(`%(${key})s`, params[key]);
      }
    }
    return msg;
  };

  if (msgidPlural) {
    // messageformat uses the first interpolated variable of the plural message to choose which
    // message to return
    const firstKey = msgidPlural.match(/%\((.*?)\)s/)[1];
    return params[firstKey] !== 1 ? replaceParams(msgidPlural) : replaceParams(msgid);
  }
  return replaceParams(msgid);
}

export function gettext(msgid, params = {}) {
  try {
    return window.localeStore[msgid](params);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Key not available:', msgid);
    return fallback(params, msgid);
  }
}

export function ngettext(msgid, msgidPlural, params) {
  try {
    return window.localeStore[msgid](params);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Key not available:', msgid);
    return fallback(params, msgid, msgidPlural);
  }
}

// eslint-disable-next-line no-console
console.log('Current i18n', {
  locale,
  date_format: document.documentElement.dataset.dateFormat,
  week_start: document.documentElement.dataset.weekStart,
});
