import chroma from 'chroma-js';
import html2canvas from 'html2canvas';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel';
import 'chartjs-plugin-deferred';

// register controller in chart.js and ensure the defaults are set
Chart.register(ChartDataLabels, FunnelController, TrapezoidElement);

const COLORS = {
  red: {
    bg: '#FFCFD5',
    border: '#CE7B91',
  },
  blue: {
    bg: '#DDF5FB',
    border: '#8DCAE6',
  },
  green: {
    bg: '#EDF4EC',
    border: '#B3D1C1',
  },
  purple: {
    bg: '#E5DAEF',
    border: '#D1BCE3',
  },
  yellow: {
    bg: '#FDFACF',
    border: '#ECE25B',
  },
  grey: {
    bg: '#EEEEEE',
    border: '#A0A0A0',
  },
  brown: {
    bg: '#F9E1C0',
    border: '#CEAC7D',
  },
  pigeonPost: {
    bg: '#B2C5DD',
    border: '#828F9F',
  },
  sandyBrown: {
    bg: '#F29863',
    border: '#AC6D49',
  },
  yellowGreen: {
    bg: '#CDE496',
    border: '#98A970',
  },
  malta: {
    bg: '#BEB4A5',
    border: '#7B7367',
  },
  newYorkPink: {
    bg: '#DB8587',
    border: '#A66768',
  },
  halfBaked: {
    bg: '#94C9CE',
    border: '#789FA3',
  },
  sweetCorn: {
    bg: '#FBF490',
    border: '#ECE25B',
  },
  bizarre: {
    bg: '#e9dbd3',
    border: '#ccc0ba',
  },
  loblolly: {
    bg: '#bccdcf',
    border: '#a5b3b5',
  },
  teaGreen: {
    bg: '#caefb7',
    border: '#b5d6a5',
  },
  lavenderGray: {
    bg: '#BABAD5',
    border: '#a4a4bd',
  },
  whiteRock: {
    bg: '#ECE5D4',
    border: '#d4cebe',
  },
  pacificBlue: {
    bg: '#0baeb7',
    border: '#09979e',
  },
  wistful: {
    bg: '#B5A9D4',
    border: '#9f95ba',
  },
  cornField: {
    bg: '#FAF7C5',
    border: '#e0deb1',
  },
  gossip: {
    bg: '#e1fab4',
    border: '#cae0a2',
  },
  blueHaze: {
    bg: '#c3c7df',
    border: '#abafc4',
  },
  swampGreen: {
    bg: '#b9b88c',
    border: '#a1a07a',
  },
  ziggurat: {
    bg: '#b7e0d8',
    border: '#a3c7c0',
  },
  shakespeare: {
    bg: '#5aaad3',
    border: '#5097ba',
  },
  cadetBlue: {
    bg: '#afb6c3',
    border: '#979da8',
  },
  conch: {
    bg: '#d4dbd7',
    border: '#bcc2bf',
  },
  beautyBush: {
    bg: '#f2c9c7',
    border: '#d9b4b2',
  },
  snuff: {
    bg: '#e2d3e4',
    border: '#c8bbc9',
  },
  spindle: {
    bg: '#a1b8e6',
    border: '#8fa3cc',
  },
  londonHue: {
    bg: '#d0a9d2',
    border: '#b693b8',
  },
  mojo: {
    bg: '#c9503d',
    border: '#b04535',
  },
  regentGray: {
    bg: '#7d8b9b',
    border: '#697582',
  },
  roti: {
    bg: '#bdbc45',
    border: '#a3a13c',
  },
  whiskey: {
    bg: '#daa36f',
    border: '#bf8f62',
  },
  matrix: {
    bg: '#b05b5b',
    border: '#964e4e',
  },
  mongoose: {
    bg: '#b8ad83',
    border: '#9e9570',
  },
  submarine: {
    bg: '#bdc9ca',
    border: '#a5afb0',
  },
  cavernPink: {
    bg: '#e3b6b5',
    border: '#c9a2a1',
  },
  foggyGray: {
    bg: '#cac7b4',
    border: '#b0ad9d',
  },
  primrose: {
    bg: '#d9f199',
    border: '#c3d989',
  },
  amethystSmoke: {
    bg: '#a79db1',
    border: '#8e8696',
  },
  fallGreen: {
    bg: '#edebc6',
    border: '#d4d2b2',
  },
  mystic: {
    bg: '#d6e0e8',
    border: '#bec7cf',
  },
};

const AXIS_OPTIONS = {
  bar: {
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  },
  bubble: {
    scales: {
      xAxes: [
        {
          type: 'category',
          offset: true,
        },
      ],
      yAxes: [
        {
          offset: true,
          ticks: {
            min: 0,
          },
        },
      ],
    },
  },
};

function chartColorType(type) {
  const result = [];
  $.each(COLORS, (i, value) => {
    result.push(value[type]);
  });
  return result;
}

function calcPercentage(currentData, allData) {
  const total = allData.reduce((x, y) => x + y);
  return ((currentData / total) * 100).toFixed(1);
}

export default function initCharts(element) {
  const $el = $(element);
  const ctx = $el.find('canvas');
  const chartId = $el.data('chart-id');
  const reportId = $el.data('chart-report-id');
  const dataElement = $el.data('chart-data');
  const chartType = $el.data('chart-type');
  const options = $el.data('chart-options') || {};
  const indexAxis = $el.data('chart-axis') || 'x';
  const showPercentage = $el.data('chart-percentage') || false;
  const chartLabels = $el.data('chart-labels');
  let datasetsInit = [];
  let sortedLabels = [];

  if (chartLabels) {
    sortedLabels = chartLabels;
  } else {
    sortedLabels = Object.keys(dataElement);
  }

  const labelsColors = chroma.scale('blues').colors(sortedLabels.length);

  if ($.isArray(dataElement)) {
    $.each(dataElement, (index, value) => {
      const key = Object.keys(value)[0];
      datasetsInit.push({
        label: key,
        data: value[key],
        backgroundColor: options.color ? COLORS[options.color].bg : chartColorType('bg')[index],
        borderColor: options.color ? COLORS[options.color].border : chartColorType('border')[index],
        borderWidth: 1,
        fill: false,
      });
    });
  } else {
    const dataset = {
      data: sortedLabels.map((label) => dataElement[label]),
      borderWidth: 1,
    };

    if (chartType === 'funnel') {
      dataset.backgroundColor = labelsColors;
    } else {
      dataset.backgroundColor = options.color ? COLORS[options.color].bg : chartColorType('bg');
      dataset.borderColor = options.color ? COLORS[options.color].border : chartColorType('border');
      dataset.fill = true;
    }

    datasetsInit = [dataset];
  }

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.className = 'legend';
      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendFunnelPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, _, legendOptions) {
      const ul = getOrCreateLegendList(chart, legendOptions.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      sortedLabels.forEach((label, index) => {
        const li = document.createElement('li');
        const boxSpan = document.createElement('span');
        boxSpan.className = 'legend-color';
        boxSpan.style.background = labelsColors[index];
        boxSpan.style.borderColor = labelsColors[index];
        const text = document.createTextNode(label);

        li.appendChild(boxSpan);
        li.appendChild(text);
        ul.appendChild(li);
      });
    },
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, _, legendOptions) {
      const ul = getOrCreateLegendList(chart, legendOptions.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      const numElements = items.length;
      let hiddenLiElements = false;

      const extraElements = document.createElement('div');
      extraElements.className = 'extra-elements is-hidden';
      items.forEach((item, index) => {
        if ((numElements > 10) && (index === 10)) {
          const li = document.createElement('li');
          li.className = 'show-more';

          const showMore = document.createElement('a');
          const icon = document.createElement('i');
          icon.className = 'huge-icon huge-arrow-down-01 is-xsmall';

          showMore.appendChild(icon);
          showMore.onclick = () => {
            li.className = 'show-more is-hidden';
            extraElements.className = 'extra-elments';
          };

          li.appendChild(showMore);
          ul.appendChild(li);

          hiddenLiElements = true;
        }

        const li = document.createElement('li');

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        const boxSpan = document.createElement('span');
        boxSpan.className = 'legend-color';
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        const text = document.createTextNode(item.text);

        li.appendChild(boxSpan);
        li.appendChild(text);
        if (hiddenLiElements) {
          extraElements.appendChild(li);
        } else {
          ul.appendChild(li);
        }
      });

      if (hiddenLiElements) {
        ul.appendChild(extraElements);
      }
    },
  };

  const plugins = [];
  plugins.push(ChartDataLabels);
  if ($el.data('chart-label')) {
    if (chartType === 'funnel') {
      plugins.push(htmlLegendFunnelPlugin);
    } else {
      plugins.push(htmlLegendPlugin);
    }
  }

  let color = 'grey';
  if (chartType !== 'funnel') {
    color = options.color ? COLORS[options.color].border : chartColorType('border');
  }

  let scales = {};
  if (chartType === 'bar') {
    scales = {
      y: {
        ticks: {
          callback(value) {
            const label = this.getLabelForValue(value);
            if (typeof label === 'string' && label.length > 50) {
              return `${label.substring(0, 50)}...`;
            }

            return label.substr(0, 50);
          },
        },
      },
    };
  }

  const chartInstance = new Chart(
    ctx,
    {
      type: chartType,
      data: {
        labels: sortedLabels,
        datasets: datasetsInit,
      },
      plugins,
      options: {
        indexAxis,
        layout: (() => {
          if (chartType === 'bar') { return { padding: { top: 24 } }; }
          if (chartType === 'horizontalBar') { return { padding: { top: 15, right: 40 } }; }
          if (chartType === 'line') { return { padding: { top: 24, right: 24 } }; }
          return 0;
        })(),
        responsive: true,
        animation: {
          duration: 1500,
          onComplete: () => {
            const downloadButton = document.getElementById(`download-image-${reportId}`);
            if (reportId && downloadButton) {
              downloadButton.classList.remove('is-hidden');
              const canvasOptions = { backgroundColor: null };
              html2canvas(document.querySelector(`#chart-${reportId}`), canvasOptions).then(canvas => {
                downloadButton.href = canvas.toDataURL();
                downloadButton.download = `${reportId}.png`;
              });
            }
          },
        },
        scales,
        plugins: {
          htmlLegend: {
            containerID: `legend-container-${chartId}`,
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title(context) {
                return context.label;
              },
              label(context) {
                const allData = context.dataset.data;
                const tooltipData = allData[context.dataIndex];

                if (chartType === 'funnel') {
                  if (context.dataIndex === 0) { return `${tooltipData} (100%)`; }
                  const previousValue = context.dataset.data?.[context.dataIndex - 1];
                  return `${tooltipData} (${calcPercentage(tooltipData, [previousValue])}%)`;
                }

                const tooltipPercentage = calcPercentage(tooltipData, allData);
                return `${tooltipData} (${tooltipPercentage}%)`;
              },
            },
          },
          deferred: {
            enable: true,
            delay: 200,
          },
          datalabels: {
            align: chartType === 'funnel' ? 'center' : 'end',
            anchor: () => {
              if (chartType === 'funnel') { return 'start'; }
              if ((chartType !== 'doughnut') && (chartType !== 'pie')) { return 'end'; }
              return 'center';
            },
            display: showPercentage,
            color,
            font: {
              size: 14,
              weight: 600,
            },
            formatter(value, context) {
              if (chartType === 'funnel') {
                if (context.dataIndex === 0) { return '100%'; }
                const previousValue = context.dataset.data?.[context.dataIndex - 1];
                return `${calcPercentage(value, [previousValue])}%`;
              }
              return `${calcPercentage(value, context.dataset.data)}%`;
            },
          },
        },
      },
    },
    AXIS_OPTIONS[chartType],
  );

  if (chartType === 'pie') {
    chartInstance.canvas.parentNode.style.height = '340px';
    chartInstance.canvas.parentNode.style.width = '680px';
  }
}
