import initCharts from '../../../services/charts';

window.Bizneo.report_charts_controller = {
  init_reports() {
    $('[data-chart]').each((i, element) => {
      initCharts(element);
    });
  },
  init_report(element) {
    initCharts(element);
  },
  actions() {
    $(document).on('click', 'ul.legend .show-more, ul.legend .show-less', (e) => {
      const parent = $(e.target).closest('ul.legend');
      $(parent).find('.extra-elements').toggleClass('is-hidden');
      $(parent).find('.show-less').toggleClass('is-hidden');
      $(parent).find('.show-more').toggleClass('is-hidden');
    });
  },
};

jQuery(() => {
  if ($('.report-charts-controller-js').length) {
    window.Bizneo.report_charts_controller.init_reports();
    window.Bizneo.report_charts_controller.actions();
  }
});
