class JobsSuggester {
  constructor(suggester) {
    this.suggester = suggester;
    this.select = $(`#${this.suggester.attr('id').replace('_chosen', '')}`);
    this.chosen_results = suggester.find('.chosen-results');
    this.chosen_search = suggester.find('.chosen-search input');
  }

  clearSelect() {
    this.select.html("<option value=''></option>");
    return this.chosen_results.empty();
  }

  appendNewOption(position, job) {
    const optionText = `${job.friendly_title} ${job.anonymous ? $('.anonymous').text() : ''}`;
    this.select.append(`<option value='${job.id}'>${optionText}</option>`);
    return this.chosen_results.append(`<li class='active-result' data-option-array-index='${position}'>${optionText}</li>`);
  }
}

function loadSuggestedJobs(suggester, jobs) {
  suggester.clearSelect();
  // eslint-disable-next-line
  for (let position in jobs) {
    const job = jobs[position];
    if (job.id != null) {
      suggester.appendNewOption(position, job);
    }
  }

  suggester.chosen_results.find('.active-result').click((e) => {
    const selectedOption = $($(suggester.select).find('option')[parseInt(e.target.dataset.optionArrayIndex, 10) + 1]);
    selectedOption.prop('selected', 'selected');
    const selectedJob = jobs.find(element => element.id === parseInt(selectedOption.attr('value'), 10));
    $(suggester.select).find('option').not(':selected').remove();
    const pipelineRemoteUrl = $('#fetch-pipeline-url').attr('value');
    if (selectedJob && pipelineRemoteUrl) {
      $.ajax({
        type: 'GET',
        url: pipelineRemoteUrl,
        data: { campaign: { job_id: selectedJob.id } },
      });
    }
    if (selectedJob) {
      $('#selected-job-locale, #job_locale').attr('value', selectedJob.language);
      $('#action_response_type').attr('data-params', `job_locale=${selectedJob.language}`);
      $('#job_locale:not(:disabled)').prop('checked', true).change();
    }

    if (selectedJob && selectedJob.anonymous) {
      $('.anonymous-info').removeClass('is-hidden');
      $('.anonymous-info').show();
    } else {
      $('.anonymous-info').addClass('is-hidden');
    }
    suggester.select.trigger('chosen:updated');
  });
}

function getSuggestionsFor(suggester, value) {
  if (value !== undefined && value !== null && value.length >= 3) {
    const suggestUrl = $('#job').data('url');
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `${suggestUrl}&title=${value}`,
      success(result) {
        loadSuggestedJobs(suggester, result);
      },
    });
  } else {
    loadSuggestedJobs(suggester, []);
  }
}

function addSuggesterEvents(suggester) {
  suggester.chosen_search.keyup(e => getSuggestionsFor(suggester, e.target.value));
}

function initializeSuggesters() {
  $('.job-suggester.chosen-container').each((index, element) => {
    const jobsSuggester = new JobsSuggester($(element));
    addSuggesterEvents(jobsSuggester);
    const select = $(`#${$(element).attr('id').replace('_chosen', '')}`);
    if (select.find('option:selected').text()) {
      $('#job_chosen > a:nth-child(1) > span:nth-child(1)').text(select.find('option:selected').text());
    }
  });
}

export default function initializeJobSuggesters() {
  initializeSuggesters();
}
