import { open, close } from '../../../services/modal';
import flatpickrSetLocale from './flatpickr_set_locale';

const defaultOptions = {
  dateFormat: 'd/m/Y',
  animate: false,
  nextArrow: '<i class="huge-icon is-xsmall huge-arrow-right-01"></i>',
  prevArrow: '<i class="huge-icon is-xsmall huge-arrow-left-01"></i>',
  allowInput: true,
  disableMobile: true,
};

export async function initDatepicker() {
  const { default: flatpickr } = await import(/* webpackChunkName: "flatpickr" */ 'flatpickr');
  let endDate;
  await flatpickrSetLocale(flatpickr);
  const initDate = flatpickr('#init-date', $.extend({}, defaultOptions, {
    minDate: $('#init-date').data('startdate'),
    maxDate: $('#init-date').data('enddate'),
    onChange: (selectedDates, dateStr) => {
      endDate.set('minDate', dateStr);
    },
  }));
  endDate = flatpickr('#end-date', $.extend({}, defaultOptions, {
    minDate: $('#end-date').data('startdate'),
    maxDate: $('#end-date').data('enddate'),
    onChange: (selectedDates, dateStr) => {
      initDate.set('maxDate', dateStr);
    },
  }));
}

export function historicalRemovedModal() {
  $(document).on('click', '.prevent-removed-resources-submit', (e) => {
    if ($('#init-date').val() !== '' && $('#end-date').val() !== '') {
      open($('#modal-removed-resources-form').html(), $(e.target).parents('body')[0]);
    }
  });

  $(document).on('click', '.submit-removed-resources-form', (e) => {
    e.preventDefault();
    const $this = $(e.target);

    $this.addClass('disabled').text($this.data('disable-with'));

    $('.prevent-removed-resources-submit').addClass('is-hidden');
    $('#submit-removed-resources').removeClass('is-hidden').click();
    close();
  });
}

$(document).on('change', '#init-date, #end-date', () => {
  if ($('#init-date').val() !== '' && $('#end-date').val() !== '') {
    $('.prevent-removed-resources-submit').removeClass('is-disabled');
  } else {
    $('.prevent-removed-resources-submit').addClass('is-disabled');
  }
});
