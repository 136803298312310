import { initAccordion } from '@bizneo/accordion/accordion';

$(document).on('dom-update', (e) => {
  initAccordion(e.target);
});

const SELECTOR = '.accordion-action';

$(document).on('click', SELECTOR, (e) => {
  const $target = $(e.target);
  const $parent = $target.closest('.accordion');
  $parent.find('.button-wrapper').toggle();
});
