export function fillActionStatusSelect(phaseElement) {
  const $this = phaseElement;
  const $parent = $this.closest('.action-item');
  const $statusesSelect = $($parent).find('.workflow_action_status');

  $($statusesSelect).html('<option value="" label=" "></option>');
  $this.find('option:selected').data('statuses').forEach((element) => {
    $($statusesSelect).append(`<option value="${element[1]}">${element[0]}</option>`);
  });
}

export function fillTriggerEventSelect() {
  const $workflowEvent = $('.workflows-settings #workflows_workflow_event');
  const $triggerEventSelect = $('.workflows-settings .workflow_trigger_event');

  const selectedValue = $triggerEventSelect.data('selected');

  $('#trigger_event option').each((_index, element) => {
    const dataEvents = $(element).attr('data-events');
    if (dataEvents.includes($workflowEvent.val())) {
      const $newOption = $(element).clone();
      if ($newOption.val() === selectedValue) {
        $newOption.attr('selected', 'selected');
      }
      $('.workflow_trigger_event').append($newOption);
    }
  });
}

export const changeActionCategory = () => {
  $(document).on('change', '.action_category_ids', (e) => {
    const $this = $(e.target);
    const $objectsSelect = $this.closest('.action-item').find('.action_element_id');

    const isTagCategory = $this.hasClass('tag-categories');

    $($objectsSelect).html('');
    $($objectsSelect).append('<option value="" label=" "></option>');

    let categoryObjects = $this.find('option:selected').data('elements');
    if (categoryObjects === undefined) {
      categoryObjects = $objectsSelect.data('elements');
    }

    categoryObjects.forEach((element) => {
      let option;

      if (isTagCategory) {
        const parent = element[2]['data']['parent'] || '';
        const color = element[2]['data']['color'] || 'default';
        option = `<option class="${element[2]['class']}" data-color="${color}" data-parent="${parent}" value="${element[1]}">${element[0]}</option>`;
      } else {
        option = `<option value="${element[1]}">${element[0]}</option>`;
      }

      $($objectsSelect).append(option);
    });
  });
};
