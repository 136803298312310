const SELECTOR = '.field-container .attach input';

$(document).on('change', SELECTOR, (e) => {
  $(e.target).closest('.attach').find('.file-name').text(e.target.value);
});

$(document).on('mouseenter', SELECTOR, (e) => {
  $(e.target).closest('.attach').addClass('hover');
});

$(document).on('mouseleave', SELECTOR, (e) => {
  $(e.target).closest('.attach').removeClass('hover');
});
