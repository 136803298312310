function updateSelectRecruitmenteGroupSources($parent) {
  const sourceId = $parent.find('input').val();
  $(`#select-recruitment-group-sources option[value=${sourceId}]`).removeAttr('disabled');
  $(`#select-recruitment-group-sources option[value=${sourceId}]`).removeAttr('selected');
  $('#select-recruitment-group-sources').val('').trigger('chosen:updated');
}

$(document).on('click', '.action-recruitment-source-remove', (e) => {
  e.preventDefault();

  const $parent = $(e.target).closest('.recruitment-group-source-item');
  $parent.remove();

  if (!$('form .sources-wrapper .recruitment-group-source-item').length) {
    $('.sources p.info').show();
  }

  updateSelectRecruitmenteGroupSources($parent);
});
