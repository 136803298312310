export default function companyTeamsEdit() {
  function getElementType(element) {
    return element.target.id.split('-').slice(1).join('-');
  }

  $(document).on('click', '.select-all', (e) => {
    const $this = $(e.target);
    const isChecked = $this.is(':checked');
    $this.closest('.table').find('tr input').prop('checked', isChecked);
  });

  $('#expand-recruiters, #expand-collaborators').on('click', (e) => {
    e.preventDefault();
    const elementType = getElementType(e);
    $(`#show-${elementType}`).removeClass('hide');
    $(`#expand-${elementType}`).addClass('hide');
    $(`#close-${elementType}`).removeClass('hide');
  });

  $('#expand-jobs, #expand-requisitions, #expand-custom-reports').on('click', (e) => {
    e.preventDefault();
    const elementType = getElementType(e);
    $(`#show-${elementType}`).addClass('loading');
    $(`#show-${elementType}`).removeClass('hide');
    $(`#expand-${elementType}`).addClass('hide');
    $(`#close-${elementType}`).removeClass('hide');
    if (!$(`#show-${elementType}`).hasClass('show')) {
      const newSpan = document.createElement('span');
      newSpan.innerHTML = $(`#show-${elementType}`).attr('loading_translation');
      newSpan.id = 'loading';
      $(`#show-${elementType}`).append(newSpan);
    }
  });

  $('#close-recruiters, #close-collaborators, #close-jobs, #close-requisitions, #close-custom-reports').on('click', (e) => {
    e.preventDefault();
    const elementType = getElementType(e);
    $(`#expand-${elementType}`).removeClass('hide');
    $(`#expand-${elementType}`)[0].removeAttribute('href');
    $(`#close-${elementType}`).addClass('hide');
    $(`#show-${elementType}`).addClass('hide');
  });

  $(document).on('click', '.select-recruiters, .select-collaborators, .select-jobs, .select-requisitions, .select-custom_reports', (e) => {
    const $this = $(e.target);
    const isChecked = $this.is(':checked');
    $this.closest('.control-group').find('.controls input').prop('checked', isChecked);
  });

  $(document).on('click', '#company_team_form_button', (e) => {
    if ($('.exclusive-permission:checkbox:checked').length > 0 && $('.collaborator_element:checkbox:checked').length > 0) {
      e.preventDefault();
      $('main').animate({ scrollTop: 0 }, 'slow');
      $('#permissions_error').fadeIn(10);
      $('#permissions_error').delay(4600).fadeOut(1000);
    } else {
      $('#company-team-form').submit();
    }
  });
}
