import _ from 'lodash';

const selectFieldOptions = (fieldOptions, selectedOptions = []) => {
  const options = [];

  if (fieldOptions && fieldOptions.length) {
    for (let index = 0; index < fieldOptions.length; index += 1) {
      const option = fieldOptions[index];
      let selectedOption = false;
      if (selectedOptions.includes(parseInt(option[1], 10))) { selectedOption = true; }
      options.push(new Option(option[0], option[1], selectedOption, selectedOption));
    }
  }

  return options;
};

const removeSelectedConditionalOptions = () => {
  $('.conditional-field-options .fields input.conditional-field-option-option-id').each((_index, element) => {
    const $fields = $(element).closest('.fields');
    if ($fields.find('input.conditional-field-option-id').length) {
      $fields.find('input.conditional-field-option-destroy').val('1');
    } else {
      $fields.remove();
    }
  });
};

export const changeConditionalType = () => {
  $(document).on('change', '#candidate_questions_field_conditional', (e) => {
    const $this = $(e.target);

    $('#candidate_questions_field_conditional_field_id').val('');
    $('#candidate_questions_field_conditional_field_id').prop('required', false);
    $('#candidate_questions_field_conditional_option_ids').val('');
    $('#candidate_questions_field_conditional_option_ids').prop('required', false);
    $('.chatbot-field-responses').trigger('dom-update');
    $('#candidate_questions_field_system_custom_field').val('');
    $('#candidate_questions_field_system_custom_field').prop('required', false);
    $('.system-custom-field').trigger('dom-update');
    $('#candidate_questions_field_conditional_custom_field_group_id').val('');
    $('#candidate_questions_field_conditional_system_field').val('');

    const conditionalType = $this.val();
    if (conditionalType === 'chat_bot_field') {
      $('#candidate_questions_field_conditional_field_id').prop('required', true);
      $('.chatbot-field-responses').removeClass('is-hidden');
      $('.system-custom-field').addClass('is-hidden');
    } else if (conditionalType === 'system_or_custom_field') {
      $('#candidate_questions_field_system_custom_field').prop('required', true);
      $('.chatbot-field-responses').addClass('is-hidden');
      $('.chatbot-field-options').addClass('is-hidden');
      $('.system-custom-field').removeClass('is-hidden');
      removeSelectedConditionalOptions();
    } else {
      $('.chatbot-field-responses').addClass('is-hidden');
      $('.chatbot-field-options').addClass('is-hidden');
      $('.system-custom-field').addClass('is-hidden');
      removeSelectedConditionalOptions();
    }
  });
};

export const changeConditionalField = () => {
  $(document).on('change', '#candidate_questions_field_conditional_field_id', (e) => {
    const $this = $(e.target);

    removeSelectedConditionalOptions();
    const fieldOptions = $this.data('field-options').get(parseInt($this.val(), 10));
    const options = selectFieldOptions(fieldOptions);
    $('#candidate_questions_field_conditional_option_ids').prop('required', true);
    $('#candidate_questions_field_conditional_option_ids').html(options);
    $('.chatbot-field-options').removeClass('is-hidden');
    $('.chatbot-field-options').trigger('dom-update');
  });
};

export const changeConditionalOptions = () => {
  $(document).on('change', '#candidate_questions_field_conditional_option_ids', (e) => {
    const selectedOptions = $(e.target).val();
    const includedOptions = [];
    const $conditionalFieldOptions = $(e.target).closest('.chatbot-field-options').find('.conditional-field-options');
    $conditionalFieldOptions.find('input.conditional-field-option-option-id').each((_index, option) => {
      const optionValue = $(option).val();
      const $fields = $(option).closest('.fields');
      if (selectedOptions.includes(optionValue)) {
        $fields.find('input.conditional-field-option-destroy').val('0');
        includedOptions.push(optionValue);
      } else if ($fields.find('input.conditional-field-option-id').length) {
        $fields.find('input.conditional-field-option-destroy').val('1');
      } else {
        $fields.remove();
      }
    });

    _.difference(selectedOptions, includedOptions).forEach((optionId) => {
      const $fields = $("<div class='fields' />");
      const conditionalOptionIndex = $conditionalFieldOptions.find('.fields').length;
      const $inputOptionId = $("<input type='hidden' />")
        .attr('id', `candidate_questions_field_conditional_field_options_attributes_${conditionalOptionIndex}_option_id`)
        .attr('name', `candidate_questions_field[conditional_field_options_attributes][${conditionalOptionIndex}][option_id]`)
        .attr('class', 'conditional-field-option-option-id')
        .val(optionId);
      $fields.append($inputOptionId);
      $conditionalFieldOptions.append($fields);
    });
  });
};

export const changeSystemOrCustomField = () => {
  $(document).on('change', '#candidate_questions_field_system_custom_field', (e) => {
    const $this = $(e.target);

    const customFieldPrefix = $this.data('custom-field-prefix');
    if ($this.val().startsWith(customFieldPrefix)) {
      const customFieldGroupId = $this.val().split(customFieldPrefix)[1];
      $('#candidate_questions_field_conditional_custom_field_group_id').val(customFieldGroupId);
      $('#candidate_questions_field_conditional_system_field').val('');
    } else {
      $('#candidate_questions_field_conditional_custom_field_group_id').val('');
      $('#candidate_questions_field_conditional_system_field').val($this.val());
    }
  });
};

export const loadConditionalFields = (fieldId, conditionalFieldId, conditionalOptionIds) => {
  const selectFieldTypes = $('#candidate_questions_field_conditional_field_id').data('select-field-type');
  const fieldPath = `form .chat-bot-fields-table .chat-bot-item#chat-bot-field-${fieldId}`;
  const fieldPosition = $(fieldPath).length ? parseInt($(fieldPath).find('input.position').val(), 10) : null;
  let fieldConditionFieldId = null;
  if (conditionalFieldId.length) {
    fieldConditionFieldId = parseInt(conditionalFieldId, 10);
  }
  const fields = [];
  const options = new Map();

  $('form .chat-bot-fields-table .chat-bot-item').not('.is-hidden').each((_index, element) => {
    const position = parseInt($(element).find('input.position').val(), 10);

    if (selectFieldTypes.includes($(element).find('input.type').val()) && (fieldPosition === null || fieldPosition > position)) {
      const currentFieldId = parseInt($(element).prop('id').split('-').pop(), 10);
      let selectedOption = false;
      if (fieldConditionFieldId === currentFieldId) { selectedOption = true; }
      const option = new Option($(element).find('input.alias').val(), currentFieldId, selectedOption, selectedOption);
      fields.push(option);
      if ($(element).find('.hidden-fields .option-hidden input.option-destroy').not("[value='1']")) {
        options.set(currentFieldId, []);
        $(element).find('.hidden-fields .option-hidden').each((_i, optionEl) => {
          if ($(optionEl).find('input.option-destroy').val() !== '1') {
            const optionValue = [$(optionEl).find('input.option-name').val(), $(optionEl).prop('id').split('-')[2]];
            options.get(currentFieldId).push(optionValue);
          }
        });
        if (selectedOption) {
          const fieldOptionValues = options.get(currentFieldId);
          const fieldOptions = selectFieldOptions(fieldOptionValues, conditionalOptionIds);
          $('#candidate_questions_field_conditional_option_ids').html(fieldOptions);
        }
      }
    }
  });

  if (fields.length) { $('#candidate_questions_field_conditional_field_id').append(fields); }
  if (options.size) { $('#candidate_questions_field_conditional_field_id').data('field-options', options); }
};

const changeFromChatBotConditionalToFixed = ($hiddenFields) => {
  const $chatbotTable = $('.chat-bot-fields-table');
  const defaultConditionalOption = $chatbotTable.data('fixed-option');
  $hiddenFields.find('input.conditional').val(defaultConditionalOption);
  $hiddenFields.find('input.conditional-field-id').val('');
  const fileRowId = $hiddenFields.closest('.chat-bot-item').prop('id');
  const currentFieldId = parseInt(fileRowId.split('-').pop(), 10);
  const inputConditionalOptionIds = $("<input type='hidden' multiple />")
    .attr('id', `chat_bot_chat_historicals_attributes__fields_attributes_${currentFieldId}_conditional_option_ids`)
    .attr('name', `chat_bot_chat[historicals_attributes][][fields_attributes][${currentFieldId}][conditional_option_ids][]`)
    .attr('class', 'conditional-option-ids')
    .val('');
  $hiddenFields.find('input.conditional-field-id').after(inputConditionalOptionIds);
  $(`#${fileRowId} .flat-box .conditional-column`).empty();
  const conditionalFields = $chatbotTable.data('conditional-fields');
  delete conditionalFields[currentFieldId];
  $chatbotTable.data('conditional-fields', conditionalFields);

  const $editModalLink = $hiddenFields.closest('.chat-bot-item').find('.contextual-menu a.open-field-form');
  const urlString = $editModalLink.prop('href');
  const url = new URL(urlString);
  const { searchParams } = url;
  searchParams.set('candidate_questions_field[conditional]', defaultConditionalOption);
  searchParams.set('candidate_questions_field[conditional_field_id]', '');
  for (const key of searchParams.keys()) {
    if (key.startsWith('candidate_questions_field[conditional_field_options_attributes]') && !key.endsWith('[id]')) {
      let attributeKey = '';
      if (key.endsWith('[_destroy]')) {
        attributeKey = '[_destroy]';
      } else {
        attributeKey = '[option_id]';
      }
      const conditionalFieldOptionIdKey = key.replace(attributeKey, '[id]');
      if (searchParams.has(conditionalFieldOptionIdKey)) {
        if (key.endsWith('[_destroy]')) { searchParams.set(key, '1'); }
      } else {
        searchParams.delete(key);
      }
    }
  }
  $editModalLink.prop('href', url.pathname + url.search);
};

const fieldConditionalAlias = ($chatbotTable, conditionalFieldId) => {
  const fieldConditionalNames = [];
  $chatbotTable.find(`.chat-bot-item .hidden-fields input.conditional-field-id[value='${conditionalFieldId}']`).each((_i, conditionalFieldInput) => {
    const $hiddenFields = $(conditionalFieldInput).closest('.hidden-fields');
    if ($hiddenFields.find('input.destroy').val() !== '1') {
      const fieldConditionalName = $hiddenFields.find('input.alias').val();
      fieldConditionalNames.push(fieldConditionalName);
    }
  });

  return fieldConditionalNames;
};

const removeFieldConfirmMessage = ($chatbotTable, $removeFieldLink, fieldConditionalNames) => {
  let translationCount = '';
  if (fieldConditionalNames.length === 0) {
    translationCount = 'zero';
  } else if (fieldConditionalNames.length === 1) {
    translationCount = 'one';
  } else {
    translationCount = 'other';
  }

  const confirmationTranslation = $chatbotTable.data(`field-remove-confirmation-${translationCount}-translation`);
  $removeFieldLink.attr('data-description', confirmationTranslation.replace('%{field_names}', fieldConditionalNames.join(', ')));
};

const updateFieldRemoveConfirmationMessage = ($chatbotTable, conditionalFieldId) => {
  const fieldConditionalNames = fieldConditionalAlias($chatbotTable, conditionalFieldId);
  const $removeFieldLink = $(`#chat-bot-field-${conditionalFieldId} .contextual-menu a.remove-field`);
  removeFieldConfirmMessage($chatbotTable, $removeFieldLink, fieldConditionalNames);
};

export const removeRelatedConditionalFields = (fieldId, newRecord) => {
  const $fieldRow = $(`#chat-bot-field-${fieldId}`);
  const conditionalFieldId = $fieldRow.find('.hidden-fields input.conditional-field-id').val();
  if (newRecord) {
    $fieldRow.remove();
  } else {
    $fieldRow.addClass('is-hidden');
    $fieldRow.find('input.destroy').val('1');
  }
  $(`input.conditional-field-id[value='${fieldId}']`).each((_index, element) => {
    const $hiddenFields = $(element).closest('.hidden-fields');
    $hiddenFields.find('input.conditional-option-ids').remove();
    changeFromChatBotConditionalToFixed($hiddenFields);
  });

  if (conditionalFieldId.length) {
    const $chatbotTable = $('.chat-bot-fields-table');
    updateFieldRemoveConfirmationMessage($chatbotTable, conditionalFieldId);
  }
};

export const removeConditionalOptions = (fieldId, removedOptionIds) => {
  if (removedOptionIds.length) {
    const selectorOptions = removedOptionIds.map(optionId => `input.conditional-option-ids[value='${optionId}']`);
    $(selectorOptions.join(', ')).each((_index, element) => {
      const $hiddenFields = $(element).closest('.hidden-fields');
      const removedOptionId = $(element).val();
      $(element).remove();
      if (!$hiddenFields.find('input.conditional-option-ids').length) {
        changeFromChatBotConditionalToFixed($hiddenFields);
      } else {
        const conditionalFieldAlias = $(`#chat-bot-field-${fieldId} .hidden-fields`).find('input.alias').val();

        const conditionalOptionIds = $hiddenFields.find('input.conditional-option-ids').map((_i, optionElement) => optionElement.value);
        const conditionalOptionNames = [];
        conditionalOptionIds.each((_i, optionId) => {
          conditionalOptionNames.push($(`input[name='chat_bot_chat[historicals_attributes][][fields_attributes][${fieldId}][options_attributes][${optionId}][name]']`).val());
        });

        const $conditionalTooltip = $hiddenFields.closest('.chat-bot-item').find('.flat-box .conditional-column span');
        const conditionalFieldTranslation = $conditionalTooltip.data('default-translation');
        $conditionalTooltip.prop('title', conditionalFieldTranslation.replace('%{field_name}', conditionalFieldAlias).replace('%{option_names}', conditionalOptionNames.join(', ')));

        const $editModalLink = $hiddenFields.closest('.chat-bot-item').find('.contextual-menu a.open-field-form');
        const urlString = $editModalLink.prop('href');
        const url = new URL(urlString);
        const { searchParams } = url;
        for (const key of searchParams.keys()) {
          if (key.startsWith('candidate_questions_field[conditional_field_options_attributes]') && key.endsWith('[option_id]')) {
            if (searchParams.get(key) === removedOptionId) {
              const conditionalFieldOptionIdKey = key.replace('[option_id]', '[id]');
              const conditionalFieldOptionDestroyKey = key.replace('[option_id]', '[_destroy]');
              if (searchParams.has(conditionalFieldOptionIdKey)) {
                searchParams.set(conditionalFieldOptionDestroyKey, '1');
              } else {
                searchParams.delete(key);
                searchParams.delete(conditionalFieldOptionDestroyKey);
              }
            }
          }
        }
        $editModalLink.prop('href', url.pathname + url.search);
      }
    });
    const $chatbotTable = $('.chat-bot-fields-table');
    updateFieldRemoveConfirmationMessage($chatbotTable, fieldId);
  }
};

export const conditionalRemoveFieldConfirmation = (fieldId) => {
  const $chatbotTable = $('.chat-bot-fields-table');
  const conditionalFields = $chatbotTable.data('conditional-fields');
  const $fieldRow = $(`#chat-bot-field-${fieldId}`);
  const currentConditionalFieldId = parseInt($fieldRow.find('.hidden-fields input.conditional-field-id').val(), 10);

  if (conditionalFields.hasOwnProperty(fieldId) || !Number.isNaN(currentConditionalFieldId)) {
    const conditionalFieldId = conditionalFields[fieldId];
    if (currentConditionalFieldId !== conditionalFieldId) {
      if (!conditionalFields.hasOwnProperty(fieldId)) {
        updateFieldRemoveConfirmationMessage($chatbotTable, currentConditionalFieldId);
        conditionalFields[fieldId] = currentConditionalFieldId;
      } else if (Number.isNaN(currentConditionalFieldId)) {
        updateFieldRemoveConfirmationMessage($chatbotTable, conditionalFieldId);
        delete conditionalFields[fieldId];
      } else {
        updateFieldRemoveConfirmationMessage($chatbotTable, currentConditionalFieldId);
        updateFieldRemoveConfirmationMessage($chatbotTable, conditionalFieldId);
        conditionalFields[fieldId] = currentConditionalFieldId;
      }
      $chatbotTable.data('conditional-fields', conditionalFields);
    }
  }
};

export const conditionalRemoveOptionsConfirmation = (fieldId) => {
  const $fieldRow = $(`#chat-bot-field-${fieldId}`);
  const $hiddenFields = $fieldRow.find('.hidden-fields');
  const conditionalFieldNames = {};
  $hiddenFields.find('.option-hidden').each((_index, optionContent) => {
    if ($(optionContent).find('input.option-destroy').val() !== '1') {
      const optionId = $(optionContent).prop('id').split('field-option-')[1];
      $(`.chat-bot-item .hidden-fields input.conditional-option-ids[value='${optionId}']`).each((_idx, conditionalOption) => {
        if ($(conditionalOption).closest('.hidden-fields').find('input.destroy').val() !== '1') {
          if (!conditionalFieldNames.hasOwnProperty(optionId)) {
            conditionalFieldNames[optionId] = [];
          }
          conditionalFieldNames[optionId].push($(conditionalOption).closest('.hidden-fields').find('input.alias').val());
        }
      });
    }
  });

  Object.entries(conditionalFieldNames).forEach(([optionId, fieldNames]) => {
    const $linkToRemoveOption = $(`.new-field-forms #field-options #option-${optionId} .confirm-modal`);
    $linkToRemoveOption.removeClass('is-hidden');
    $(`.new-field-forms #field-options #option-${optionId} .remove_nested_fields`).addClass('is-hidden');
    const $chatbotTable = $('.chat-bot-fields-table');
    const titleTranslation = $chatbotTable.data('option-remove-confirmation-title-translation');
    const optionName = $(`.new-field-forms #field-options #option-${optionId} input.name`).val();
    const translationCount = fieldNames.length === 1 ? 'one' : 'other';
    const confirmationTranslation = $chatbotTable.data(`option-remove-confirmation-${translationCount}-translation`);
    $linkToRemoveOption.attr('data-modal-confirm', titleTranslation.replace('%{name}', optionName))
      .attr('data-description', confirmationTranslation.replace('%{field_names}', fieldNames.join(', ')));
  });
};

export const updateModalFieldRemoveConfirmationMessage = (fieldId) => {
  const $chatbotTable = $('.chat-bot-fields-table');
  const fieldConditionalNames = fieldConditionalAlias($chatbotTable, fieldId);
  const $removeFieldLink = $('.new-field-forms .buttons-row a.remove-field');
  if ($removeFieldLink.length) {
    removeFieldConfirmMessage($chatbotTable, $removeFieldLink, fieldConditionalNames);
  }
};

export const conditionalTooltip = (fieldId, conditionalFieldId, conditionalOptionIds) => {
  const $conditionalFieldHidden = $(`#chat-bot-field-${conditionalFieldId} .hidden-fields`);

  const conditionalFieldAlias = $conditionalFieldHidden.find('input.alias').val();
  const conditionalOptionNames = conditionalOptionIds.map(conditionalOptionId =>
    $conditionalFieldHidden.find(`#field-option-${conditionalOptionId} input.option-name`).val());

  const $conditionalTooltip = $(`#chat-bot-field-${fieldId} .flat-box .conditional-column span`);
  const conditionalFieldTranslation = $conditionalTooltip.data('default-translation');
  $conditionalTooltip.prop('title', conditionalFieldTranslation.replace('%{field_name}', conditionalFieldAlias).replace('%{option_names}', conditionalOptionNames.join(', ')));
};
