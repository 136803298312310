$(document).on('change', '#company_notification_message_company_notification_message_template_id', (e) => {
  const $this = $(e.target);
  const company = $('#notification_company_id').val();
  $.ajax({
    type: 'GET',
    dataType: 'script',
    url: Routes.new_company_company_notification_message_path(company, { company_notification_message: { company_notification_message_template_id: $this.val() } }),
    success() { },
    error() { },
  });
});
