import navChanger from '../../features/companies/email_communications';

window.Bizneo.email_communications_controller = {
  index() {
    navChanger();
  },
};

jQuery(() => {
  if ($('.settings-importation-emails').length) {
    window.Bizneo.email_communications_controller.index();
  }
});
