import { edit, actions } from '../../features/companies/candidacy_statuses';

window.Bizneo.candidacy_statuses_controller = {
  edit() {
    edit();
    actions();
  },
};

jQuery(() => {
  if ($('.candidacy-statuses-controller-js').length) {
    window.Bizneo.candidacy_statuses_controller.edit();
  }
});
