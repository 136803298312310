import { dropdownSearch, startPublishing } from '../features/headhunter_job_action';

window.Bizneo.headhunter_jobs_controller = {
  portals_index(canPublish, settingPrototypes) {
    dropdownSearch();
    startPublishing();
    window.Bizneo.setting_prototyping.create_or_update_settings(settingPrototypes);
    window.Bizneo.headhunter_job.prevent_get_away_of_view(canPublish);
    window.Bizneo.headhunter_job.might_lose_data();
  },
};
