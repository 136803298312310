import initDatepicker from '../../features/companies/pass_rate';

window.Bizneo.pass_rate_controller = {
  index() {
    initDatepicker();
  },
};

jQuery(() => {
  if ($('.pass-rate-report').length) {
    window.Bizneo.pass_rate_controller.index();
  }
});
