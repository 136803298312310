import dragula from 'dragula';

function updateSelectFormFields($parent) {
  const key = $parent.find('input.key').val();
  const component = $parent.find('input.component').val();
  const fieldValue = `${component}__${key}`;

  if ($(`#select-candidate-form-fields option[value=${fieldValue}]`).length) {
    $(`#select-candidate-form-fields option[value=${fieldValue}]`).removeAttr('disabled');
    $(`#select-candidate-form-fields option[value=${fieldValue}]`).removeAttr('selected');
    $('#select-candidate-form-fields').val('').trigger('chosen:updated');
  }
}

function updateFieldPosition(contentClass) {
  $(`form .${contentClass} .candidate-form-item`).not('.is-hidden').each((index, element) => {
    $(element).find('input.position').val(index);
  });
}

function showHideSection(contentClass) {
  if ($(`form .${contentClass} .candidate-form-item`).not('.is-hidden').length === 0) {
    $(`form .${contentClass}`).addClass('is-hidden');
  }
}

function showHideEmptyContent() {
  let someElement = false;
  $('form .video-question-fields, form .system-fields, form .custom-fields, form .killer-question-fields').each((_index, element) => {
    if ($(element).find('.candidate-form-item').not('.is-hidden').length) {
      someElement = true;
    }
  });

  const emptyContent = $('.info.inner-card.disabled');
  if (someElement && !emptyContent.hasClass('is-hidden')) {
    emptyContent.addClass('is-hidden');
  } else if (!someElement && emptyContent.hasClass('is-hidden')) {
    emptyContent.removeClass('is-hidden');
  }
}

$(document).on('nested:fieldRemoved:landing_video', () => {
  $('#landing-video-link-add').removeClass('is-hidden');
});

$(document).on('nested:fieldAdded:landing_video', () => {
  $('#landing-video-link-add').addClass('is-hidden');
});

$(document).on('change', '#candidate_form_form_landing_video_attributes_file', () => {
  $('#candidate_form_form_landing_video_attributes__destroy').val('0');
});

$(document).on('nested:fieldRemoved:completed_video', () => {
  $('#completed-video-link-add').removeClass('is-hidden');
});

$(document).on('nested:fieldAdded:completed_video', () => {
  $('#completed-video-link-add').addClass('is-hidden');
});

$(document).on('change', '#candidate_form_form_completed_video_attributes_file', () => {
  $('#candidate_form_form_completed_video_attributes__destroy').val('0');
});

$(document).on('nested:fieldRemoved:landing_image', () => {
  $('#landing-image-link-add').removeClass('is-hidden');
});

$(document).on('nested:fieldAdded:landing_image', () => {
  $('#landing-image-link-add').addClass('is-hidden');
});

$(document).on('change', '#candidate_form_form_landing_image_attributes_file', () => {
  $('#candidate_form_form_landing_image_attributes__destroy').val('0');
});

$(document).on('nested:fieldRemoved:completed_image', () => {
  $('#completed-image-link-add').removeClass('is-hidden');
});

$(document).on('nested:fieldAdded:completed_image', () => {
  $('#completed-image-link-add').addClass('is-hidden');
});

$(document).on('change', '#candidate_form_form_completed_image_attributes_file', () => {
  $('#candidate_form_form_completed_image_attributes__destroy').val('0');
});

$(document).on('nested:fieldRemoved:header_image', () => {
  $('#header-image-link-add').removeClass('is-hidden');
});

$(document).on('nested:fieldAdded:header_image', () => {
  $('#header-image-link-add').addClass('is-hidden');
});

$(document).on('change', '#candidate_form_form_header_image_attributes_file', () => {
  $('#candidate_form_form_header_image_attributes__destroy').val('0');
});

$(document).on('nested:fieldRemoved:header_image_mobile', () => {
  $('#header-image-mobile-link-add').removeClass('is-hidden');
});

$(document).on('nested:fieldAdded:header_image_mobile', () => {
  $('#header-image-mobile-link-add').addClass('is-hidden');
});

$(document).on('change', '#candidate_form_form_header_image_mobile_attributes_file', () => {
  $('#candidate_form_form_header_image_mobile_attributes__destroy').val('0');
});

$(document).on('nested:fieldRemoved:video_explanation', (event) => {
  event.field.show();
  event.field.find('.attach .file-name').text('');
  event.field.find('.action-icons a.remove-fields').addClass('is-hidden');
});

$(document).on('click', '#btn-landing-video', () => {
  $('#btn-landing-video').addClass('is-selected');
  $('#landing-video-field').removeClass('is-hidden');
  $('#landing-image-field').addClass('is-hidden');
  $('#btn-landing-image').removeClass('is-selected');
});

$(document).on('click', '#btn-completed-video', () => {
  $('#btn-completed-video').addClass('is-selected');
  $('#completed-video-field').removeClass('is-hidden');
  $('#completed-image-field').addClass('is-hidden');
  $('#btn-completed-image').removeClass('is-selected');
});

$(document).on('click', '#btn-landing-image', () => {
  $('#btn-landing-image').addClass('is-selected');
  $('#landing-image-field').removeClass('is-hidden');
  $('#landing-video-field').addClass('is-hidden');
  $('#btn-landing-video').removeClass('is-selected');
});

$(document).on('click', '#btn-completed-image', () => {
  $('#btn-completed-image').addClass('is-selected');
  $('#completed-image-field').removeClass('is-hidden');
  $('#completed-video-field').addClass('is-hidden');
  $('#btn-completed-video').removeClass('is-selected');
});

$(document).on('change', '.form-group.video .attach input:file', (e) => {
  const $parent = $(e.target).closest('.fields');
  if (e.target.files.length) {
    $parent.find('.action-icons a.remove-fields').removeClass('is-hidden');
    $parent.find('.action-icons input#question_video_explanation_attributes__destroy').val('0');
  } else {
    $parent.find('.action-icons a.remove-fields').addClass('is-hidden');
    $parent.find('.action-icons input#question_video_explanation_attributes__destroy').val('1');
  }
});

$(document).on('change', '.candidate_form_form_landing_video_file', () => {
  $('#landing-video-link-show').addClass('is-hidden');
});

$(document).on('change', '.candidate_form_form_completed_video_file', () => {
  $('#completed-video-link-show').addClass('is-hidden');
});

$(document).on('click', '#show-video-question-description', (e) => {
  e.preventDefault();
  const $this = $(e.target);
  $this.closest('.link-cnt').addClass('is-hidden');
  $this.closest('form').find('.video-question-description').removeClass('is-hidden');
});

$(document).on('click', '.candidate-form-item .action-field-optional', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-form-item');
  $parent.find('input.required').val('false');
  $parent.find('.action-field-optional').addClass('is-hidden');
  $parent.find('.action-field-required').removeClass('is-hidden');
  $parent.find('span.required').addClass('is-hidden');
  $parent.find('span.optional').removeClass('is-hidden');
});

$(document).on('click', '.candidate-form-item .action-field-required', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-form-item');
  $parent.find('input.required').val('true');
  $parent.find('.action-field-optional').removeClass('is-hidden');
  $parent.find('.action-field-required').addClass('is-hidden');
  $parent.find('span.required').removeClass('is-hidden');
  $parent.find('span.optional').addClass('is-hidden');
});

$(document).on('click', '.action-video-question-field-remove, .candidate-form-item .action-system-field-remove, .candidate-form-item .action-custom-field-remove, .action-killer-question-field-remove', (e) => {
  e.preventDefault();
  const $parent = $(e.target).closest('.candidate-form-item');
  $parent.addClass('is-hidden');
  $parent.find('input.destroy').val('1');

  const elementClasses = $(e.target).closest('a').prop('class');
  let sectionClass = '';
  if (elementClasses.includes('action-system-field-remove')) {
    sectionClass = 'system-fields';
  } else if (elementClasses.includes('action-custom-field-remove')) {
    sectionClass = 'custom-fields';
  } else if (elementClasses.includes('action-killer-question-field-remove')) {
    sectionClass = 'killer-question-fields';
  } else if (elementClasses.includes('action-video-question-field-remove')) {
    sectionClass = 'video-question-fields';
  }

  updateFieldPosition(sectionClass);
  showHideSection(sectionClass);
  showHideEmptyContent();
  updateSelectFormFields($parent);
});

$(document).on('click', '.action-id-document-candidate-form-remove', (e) => {
  e.preventDefault();
  $(e.target).closest('#candidate-form-field-id_document__other').remove();
  $('#select-candidate-form-fields option[value=id_document__id_document]').removeAttr('disabled');
  $('#select-candidate-form-fields option[value=id_document__id_document]').removeAttr('selected');
  $('#select-candidate-form-fields').val('').trigger('chosen:updated');

  updateFieldPosition('system-fields');
  showHideSection('system-fields');
  showHideEmptyContent();
});

$(document).on('click', '.select-type button', (e) => {
  e.preventDefault();

  const elementClasses = e.target.classList;

  let sectionToShow = 'select-fields';
  let sectionToHidde = 'add-video-question';
  if (elementClasses.contains('add-video-question')) {
    sectionToShow = 'add-video-question';
    sectionToHidde = 'select-fields';
  }

  $(`.select-type .${sectionToShow}`).addClass('is-selected');
  $(`.select-type .${sectionToHidde}`).removeClass('is-selected');
  $(`.${sectionToShow}-form`).removeClass('is-hidden');
  $(`.${sectionToHidde}-form`).addClass('is-hidden');
});

$(document).ready(() => {
  dragula([document.getElementsByClassName('custom-fields-form-wrapper')[0]]).on('drop', () => {
    $('form .custom-fields-form-wrapper .candidate-form-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });

  dragula([document.getElementsByClassName('system-fields-wrapper')[0]]).on('drop', () => {
    $('form .system-fields-wrapper .candidate-form-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });

  dragula([document.getElementsByClassName('killer-question-fields-wrapper')[0]]).on('drop', () => {
    $('form .killer-question-fields-wrapper .candidate-form-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });

  dragula([document.getElementsByClassName('video-questions-form-wrapper')[0]]).on('drop', () => {
    $('form .video-questions-form-wrapper .candidate-form-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });
});
