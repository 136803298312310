const SELECTOR = '#zd-btn';
const SESSION_STORAGE_ITEM = 'ZD-widgetOpen';

$(document).on('dom-update', () => {
  if (!document.getElementById('ze-snippet')) {
    const script = document.createElement('script');

    script.id = 'ze-snippet';
    script.type = 'text/javascript';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5a180c3f-4d0c-4e87-afe6-d22105fec8f2';

    document.body.appendChild(script);

    script.onload = () => {
      if (typeof zE !== 'undefined') {
        // eslint-disable-next-line no-undef
        zE('messenger', 'close');
      }
    };
  }
});

// As Tippyjs dynamically generates and injects the tooltip content into the DOM,
// is needed to use event delegation to attach an event listener
// to an element that exists before the tooltip is created
$(document).on('click', SELECTOR, () => {
  const isVisible = sessionStorage.getItem(SESSION_STORAGE_ITEM);
  // eslint-disable-next-line no-undef
  zE('messenger', isVisible === 'true' ? 'close' : 'open');
});
