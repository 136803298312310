import dragula from 'dragula';

$(document).on('click', '.delete-review-phase', (e) => {
  e.preventDefault();

  $(`#${$(e.target).data('modal-id')}`).closest('.review-phase').addClass('is-hidden');
  $(`#${$(e.target).data('modal-id')}`).closest('.review-phase').find('input.destroy').val('1');
  $('form.requisition-review-workflow-form .review-phases .review-phase').not('.is-hidden').each((index, element) => {
    $(element).find('input.position').val(index);
  });
});

$(document).on('change', '#check-add-conditional-phase', (e) => {
  const conditional = (e.target).checked;

  if (conditional) {
    $(e.target).closest('form').find('.conditional-form').removeClass('is-hidden');
  } else {
    $(e.target).closest('form').find('.conditional-form').addClass('is-hidden');
  }
  $(e.target).closest('form').find('.conditional-form #requisition_review_phase_initial_requisition_status_id').prop('disabled', !conditional);
  $(e.target).closest('form').find('.conditional-form #select-groups-rules-requisition-phase').prop('disabled', !conditional);
  $(e.target).closest('form').find('.conditional-form #requisition_review_phase_initial_requisition_status_id').trigger('chosen:updated');
  $(e.target).closest('form').find('.conditional-form #select-groups-rules-requisition-phase').trigger('chosen:updated');
});

$(document).ready(() => {
  if ($('.review-phases').length) {
    dragula([document.getElementsByClassName('review-phases')[0]], {
    }).on('drop', () => {
      $('form.requisition-review-workflow-form .review-phases .review-phase').not('.review-phase.is-hidden').each((index, element) => {
        $(element).find('input.position').val(index);
      });
    });
  }
});
