import dragula from 'dragula';

export function selectAppend() {
  $(document).on('keydown', '.chosen-container', (e) => {
    if (e.keyCode === 13) {
      const value = $(e.target).val();
      if (((value.length > 0) && ($('.active-result').first().text().toLowerCase() !== value.toLowerCase())) || ($('.active-result').length === 0)) {
        $('#scorecard_template_title').append(`<option value="${value}" selected="selected">${value}</option>`);
        $('#scorecard_template_title').trigger('chosen:updated');
      }
    }
  });
}

export function disableRequired() {
  $(document).on('nested:fieldRemoved', (e) => {
    const requiredFields = e.field.find('.required');
    requiredFields.prop('disabled', true);
  });
}

export function dragAndDop() {
  dragula([$('#questions')[0]], {
    invalid(el) {
      return $(el).hasClass('not_move');
    },
  // eslint-disable-next-line consistent-return
  }).on('drop', (el, container) => {
    $('.scorecard-question-order', container).each((index, element) => {
      $(element).val(index);
    });
  });
}

export function deleteQuestionPosition() {
  $(document).on('nested:fieldRemoved', () => {
    $('#questions > .fields:visible').find('.scorecard-question-order').each((index, element) => {
      $(element).val(index);
    });
  });
}

export function addNewQuestionPosition() {
  $(document).on('nested:fieldAdded', () => {
    $('.scorecard-question-order').last().val($('.scorecard-question-order').length - 1);
  });
}
