export default function actions() {
  $(document).on('click', '.new-integration', (e) => {
    e.preventDefault();
    const $integrationForm = $(e.target).closest('.integration-info').find('.integration-form');

    if ($integrationForm.hasClass('is-hidden')) {
      $($integrationForm[0]).removeClass('is-hidden');
    } else {
      $($integrationForm[0]).addClass('is-hidden');
    }
  });
}
