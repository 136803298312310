import {} from '../../../libs/jquery.caret';
import {} from '../../../libs/jquery.atwho';

export function expandReplyForm() {
  $(document).on('click', '.reply-button', (e) => {
    e.preventDefault();
    const parent = $(e.target).closest('.reply-message');
    $(parent).find('.reply-form').toggle();
  });

  $(document).on('click', '.reply-form .cancel-button', (e) => {
    e.preventDefault();
    const parent = $(e.target).closest('.reply-message');
    $(parent).find('.reply-form').toggle();
    const conversationId = $(parent).closest('.conversation-expanded-info').prop('id').split('-')[1];
    // eslint-disable-next-line no-undef
    CKEDITOR.instances[`textarea-note-response-${conversationId}`].setData('');
  });
}

export function expandUpdateForm() {
  $(document).on('click', '.message .edit-message', (e) => {
    e.preventDefault();
    const parent = $(e.target).closest('.message');
    $(parent).find('.message-cnt').toggle();
    $(parent).find('.message-form').toggle();
  });

  $(document).on('click', '.message-form .cancel-button', (e) => {
    e.preventDefault();
    const parent = $(e.target).closest('.message');
    $(parent).find('.message-cnt').toggle();
    $(parent).find('.message-form').toggle();
    const messageId = $(e.target).closest('.message-form').prop('id').split('-')[1];
    // eslint-disable-next-line no-undef
    CKEDITOR.instances[`edit-message-${messageId}`].setData($(`#edit-message-${messageId}`).val());
  });
}
