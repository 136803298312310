import { imageInputPreviewAction } from '../../features/companies/campaigns';

window.Bizneo.campaigns_controller = {
  imageInputPreview() {
    imageInputPreviewAction();
  },
};

jQuery(() => {
  window.Bizneo.campaigns_controller.imageInputPreview();
});
