import {
  selectAppend, disableRequired, dragAndDop, addNewQuestionPosition, deleteQuestionPosition,
} from '../../features/companies/scorecards';

window.Bizneo.scorecards_controller = {
  append_to_suggester() {
    selectAppend();
  },
  disableDestroyedRequiredFields() {
    disableRequired();
  },
  order() {
    dragAndDop();
    addNewQuestionPosition();
    deleteQuestionPosition();
  },
};

jQuery(() => {
  if ($('.scorecards-controller-js').length) {
    window.Bizneo.scorecards_controller.append_to_suggester();
    window.Bizneo.scorecards_controller.disableDestroyedRequiredFields();
    window.Bizneo.scorecards_controller.order();
  }
});
