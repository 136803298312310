export default async function setLocale(flatpickr) {
  const localeConversion = {
    'es-CL': 'es',
    'sr-RS': 'sr',
    'sr-ME': 'sr',
    'pt-BR': 'pt',
    mx: 'es',
    zh: 'zh-CN',
    ca: 'cat',
  };

  let userLocale = document.documentElement.lang;
  userLocale = localeConversion[userLocale] || userLocale;

  if (userLocale !== 'en') {
    // eslint-disable-next-line prefer-template
    const { default: locale } = await import(/* webpackChunkName: "flatpickr-locale" */ 'flatpickr/dist/l10n/' + userLocale + '.js');
    // eslint-disable-next-line no-param-reassign
    flatpickr.l10ns = locale;
  }
  // eslint-disable-next-line no-param-reassign
  flatpickr.defaultConfig.locale = userLocale;
}
