const TYPES_NEED_OPTIONS = [
  'CustomField::Fields::SelectType',
  'CustomField::Fields::CheckboxType',
  'CustomField::Fields::RadioType',
  'CustomField::Fields::MultiSelectType',
  'CustomField::Fields::RangeValueType',
  'CustomField::Fields::RangeType',
];

function showOptionsSection(element) {
  const typeSelected = $(element, 'option:selected').val();
  const $parent = $(element).closest('.fields');
  const optionsContainer = $parent.find('.new-custom-field-options').first();
  const limitsContainer = $parent.find('.new-custom-field-range-limits').first();

  if (TYPES_NEED_OPTIONS.includes(typeSelected)) {
    if (typeSelected === 'CustomField::Fields::RangeType') {
      optionsContainer.addClass('is-hidden');
      optionsContainer.find('.fields').remove();
      if (limitsContainer.find('.limits').length === 0) {
        limitsContainer.find('.add-range-limits').click();
      }
      limitsContainer.removeClass('is-hidden');
    } else {
      limitsContainer.addClass('is-hidden');
      limitsContainer.find('.fields').remove();
      if (optionsContainer.find('.options').length === 0) {
        optionsContainer.find('.add-options').click();
        optionsContainer.find('.add-options').click();
      }
      optionsContainer.find('.row').slice(0, 2).find('.delete-link').remove();
      optionsContainer.removeClass('is-hidden');
    }
  } else {
    optionsContainer.addClass('is-hidden');
    limitsContainer.addClass('is-hidden');
    optionsContainer.find('.fields').remove();
    limitsContainer.find('.fields').remove();
  }

  const $stepTwo = $(element).closest('.step_two');
  const $customFieldGroupSearchable = $stepTwo.find('.custom_field_group_searchable').first();
  if (typeSelected === 'CustomField::Fields::FileType') {
    $customFieldGroupSearchable.closest('.row').addClass('is-hidden');
    $('input:last', $customFieldGroupSearchable).click();
  } else {
    $customFieldGroupSearchable.closest('.row').removeClass('is-hidden');
  }
}

function changeCustomFieldLocale() {
  $('.translation_locale').each((_, element) => {
    $(element).val($('#custom_field_group_locale').val());
  });
}

function showFirstLocaleTab() {
  $('.fields-translation').addClass('is-hidden');
  $('.field-option-translation').addClass('is-hidden');

  const $activeTab = $('.step_translate .tabs-cnt .tabs a.active');
  if ($activeTab.length) {
    const { id } = $activeTab.data();

    $(`.${id}:not(.deleted)`).removeClass('is-hidden');
  }
}

export function checkSelectType() {
  showOptionsSection($('.select_field_type'));
}

export function changeSelectType() {
  $(document).on('change', '.select_field_type', (e) => {
    showOptionsSection(e.target);
    changeCustomFieldLocale();
  });
}

export function deleteOptions() {
  $(document).on('click', 'a.remove_nested_fields', (e) => {
    $(e.target).closest('.fields').remove();
  });
}

export function showStepTwo() {
  $('.step_one').addClass('is-hidden');
  $('.step_two').removeClass('is-hidden');
}

export function showStepOne() {
  $('.step_two').addClass('is-hidden');
  $('.step_one').removeClass('is-hidden');
}

export function clickToStepTwo() {
  $(document).on('click', '.link-continue-create-custom-field-group', () => {
    $('.step_one').addClass('is-hidden');
    $('.step_two').removeClass('is-hidden');
  });
}

export function navTranslationsTabs() {
  showFirstLocaleTab();

  $(document).on('click', '.nav-changer', () => {
    showFirstLocaleTab();
  });
}

export function enableOrDisableSectionSelect() {
  $(document).on('click', 'input[type=radio][name="custom_field_group[privated]"]', (event) => {
    if ($(event.target).val() === 'true') {
      $('#custom_field_group_section').prop('disabled', 'disabled');
      $('#custom_field_group_section').val('');
    } else {
      $('#custom_field_group_section').prop('disabled', false);
    }
    $('#custom_field_group_section').trigger('chosen:updated');
  });
}

export function changeSelectLocale() {
  changeCustomFieldLocale();

  $(document).on('change', '#custom_field_group_locale', () => {
    changeCustomFieldLocale();
  });

  $(document).on('click', '.add-options', () => {
    changeCustomFieldLocale();
  });
}

export function changeCustomFieldDefaultTranslation() {
  $(document).on('click', '.default-custom-field-translation', (e) => {
    const $this = $(e.target);
    const $parentId = $this.closest('.fields-translation').data('id');

    if ($this.is(':checked')) {
      $(`.fields-translation:not(.${$parentId}) input.default-custom-field-translation`).prop('checked', false);
      $(`.field-option-translation:not(.${$parentId}) input.default-option-translation`).val('false');
    }

    $(`.field-option-translation.${$parentId} input.default-option-translation`).val($this.is(':checked'));
  });
}

export function removeCustomFieldTranslation() {
  $(document).on('click', '.remove-custom-field-translation', (e) => {
    const $this = $(e.target);
    const $parentId = $this.closest('.fields-translation').data('id');

    $(`.fields-translation.${$parentId} input.input-remove-custom-field-translation`).val('true');
    $(`.field-option-translation.${$parentId} input.input-remove-custom-field-translation`).val('true');

    $(`.fields-translation.${$parentId}`).addClass('is-hidden deleted');
    $(`.field-option-translation.${$parentId}`).addClass('is-hidden deleted');

    $(`.step_translate .dropdown-menu .menu-${$parentId}`).removeClass('is-hidden');
    $(`.step_translate .tabs-cnt .tabs .${$parentId}`).addClass('is-hidden deleted');

    $('.step_translate .tabs-cnt .tabs .tab-item:not(.deleted):first').click();

    $(`.fields-translation.${$parentId} input:required`).prop('disabled', true);
    $(`.field-option-translation.${$parentId} input:required`).prop('disabled', true);

    if ($('.dropdown-menu.small').find('a').not('.is-hidden').length !== 0) {
      $('.button.dropdown-btn').removeClass('disabled');
    }
  });
}

export function validateEditCustomFieldTranslations() {
  $(document).on('click', '#submit-custom-field-translations', (e) => {
    if (!$('#edit_custom_field')[0].checkValidity()) {
      e.preventDefault();
      $('.translation-form-error').removeClass('is-hidden');
      $('.translation-form-error').show();
    }
  });
}
