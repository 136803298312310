export default function navChanger() {
  $(document).on('click', '.nav-changer', (e) => {
    e.preventDefault();
    const $this = $(e.target);
    window.getSelection().empty();
    $('.nav-changer').removeClass('active');
    $this.addClass('active');
    $('.email-nav-body').hide();

    if ($this.attr('href') === '#all') {
      $('.email-nav-body').show();
    } else {
      $(`${$this.attr('href')}-table`).show();
    }
  });
}
