const SELECTOR = '[data-alert]';

export default async function mountAlert() {
  const { default: defaultMountAlert } = await import(/* webpackChunkName: "alert" */ '@bizneo/alert/alert');

  /* eslint-disable prefer-rest-params */
  const alert = defaultMountAlert(...arguments);
  $(alert.getContainer()).trigger('dom-update', { source: 'alert' });
}

$(document).on('dom-update', (e) => {
  $(e.target)
    .find(SELECTOR)
    .each((_, element) => mountAlert(element));
});

const BASE_ALERT = '.alert:not(.disclaimer):not(.no-fading)';

$(document).on('dom-update', (e) => {
  $(e.target)
    .find(BASE_ALERT)
    .delay(6000)
    .fadeOut();
}).on('click', '.alert .close', (e) => {
  $(e.target).parent('.alert').fadeOut();
});
