import { questionTypeChange, deleteAnswer, edit } from '../../features/companies/company_questions';

window.Bizneo.company_questions_controller = {
  index() {
    questionTypeChange();
    deleteAnswer();
  },
  edit() {
    edit();
  },
};

jQuery(() => {
  window.Bizneo.company_questions_controller.index();
});
