import * as d3 from 'd3';

export function initReportChart() {
  const tableHeadWidth = [];

  $('.report-table.flex .table-heading .table-head').not(':first-child').each((index) => {
    tableHeadWidth[index] = $(this).width();
  });
  $('.report-table.flex .table-body .table-row').each(() => {
    $(this).find('.table-cell').not(':first-child').each((index) => {
      $(this).width(tableHeadWidth[index]);
    });
  });
  $('#pipeline-chart svg').remove();
}

export function createReportChart(data) {
  const rectSize = (d) => {
    let v;
    $.each(d, (obj, val) => {
      v = val;
    });
    return v;
  };

  const statusName = (d) => {
    let v;
    $.each(d, (obj) => {
      v = obj;
    });
    return v;
  };

  const statusData = (d) => {
    let obj;
    $.each(d, (key, value) => {
      let dataValue;
      if (Object.keys(value).length === 0) {
        dataValue = value;
      } else {
        dataValue = value.count;
      }
      obj = { [key]: dataValue };
    });

    return obj;
  };

  const {
    total,
  } = data.dataset[0];
  const statusesData = data.dataset.map((d) => statusData(d));
  const topTitle = 40;
  const width = 540;
  const leftWidth = 150;
  const barHeight = 20;
  const marginBottom = 35;
  const height = (barHeight + marginBottom) * data.dataset.length;

  const chart = d3.select($('#pipeline-chart')[0])
    .append('svg')
    .attr('class', 'chart-pipeline')
    .attr('width', '60%')
    .attr('height', height);

  chart.selectAll('text.title')
    .data([data.job_name])
    .enter().append('text')
    .attr('class', 'title')
    .attr('x', (width / 2) + leftWidth)
    .attr('y', topTitle / 2)
    .attr('text-anchor', 'middle')
    .text(String);

  const x = d3.scale.linear()
    .domain([0, total])
    .range([0, width]);

  chart.selectAll('rect.unfilled')
    .data(statusesData)
    .enter().append('rect')
    .attr('class', 'unfilled-rect')
    .attr('x', 0 + leftWidth)
    .attr('y', (d, i) => (50 * i) + topTitle)
    .attr('width', width)
    .attr('height', 27);

  chart.selectAll('rect.filled')
    .data(statusesData)
    .enter().append('rect')
    .attr(
      'class',
      (() => `filled-rect color-${(Math.floor(Math.random() * 18) + 1)}`),
    )
    .attr('x', (d) => ((width / 2) - (((rectSize(d) * width) / total) / 2)) + leftWidth)
    .attr('y', (d, i) => (50 * i) + topTitle)
    .attr('width', (d) => x(rectSize(d)))
    .attr('height', 27);

  chart.selectAll('text.score')
    .data(statusesData)
    .enter().append('text')
    .attr('class', 'score')
    .attr('x', () => (width / 2) + leftWidth)
    .attr('y', (d, i) => (50 * i) + topTitle + 35)
    .attr('dy', '-1em')
    .attr('text-anchor', 'middle')
    .text((d) => rectSize(d));

  chart.selectAll('text.score-percentage')
    .data(statusesData)
    .enter().append('text')
    .attr('class', 'score-percentage')
    .attr('x', () => (width / 2) + leftWidth + 315)
    .attr('y', (d, i) => (50 * i) + topTitle + 27)
    .attr('dy', '-.4em')
    .attr('text-anchor', 'middle')
    .text((d) => {
      if (total === 0) {
        return '0%';
      }
      return `${((rectSize(d) / total) * 100).toFixed(0)}%`;
    });

  return chart.selectAll('text.name')
    .data(statusesData)
    .enter().append('text')
    .attr('x', 0)
    .attr('y', (d, i) => (50 * i) + topTitle + 14)
    .attr('class', 'name')
    .text((d) => statusName(d));
}
