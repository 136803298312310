$(document).on('change', '.conditions-wrapper select.operator', (e) => {
  const operation = $(e.target).val();
  const parent = $(e.target).closest('tr');

  if (operation === 'in' || operation === 'are_not_in') {
    const component = parent.find('.control-group.select.is-hidden');
    component.find('select').prop('disabled', false);
    component.find('select').chosen();
    component.find('select').trigger('chosen:updated');
    component.removeClass('is-hidden');
  } else {
    const firstMatchedComponent = parent.find('.control-group.rule_conditions_first_matched_value');
    const firstMatchedInput = firstMatchedComponent.find('input');

    if (firstMatchedComponent.hasClass('is-hidden')) {
      firstMatchedInput.prop('disabled', false);
      firstMatchedComponent.removeClass('is-hidden');
    }

    const secondMatchedComponent = parent.find('.control-group.rule_conditions_second_matched_value');
    const secondMatchedInput = secondMatchedComponent.find('input');

    if (operation === 'range') {
      const placeholder = firstMatchedInput.data('placeholder-range');
      firstMatchedInput.prop('placeholder', placeholder);
      firstMatchedInput.val('');
      if (secondMatchedComponent.hasClass('is-hidden')) {
        secondMatchedComponent.removeClass('is-hidden');
        secondMatchedInput.prop('disabled', false);
      }
    } else if (!secondMatchedComponent.hasClass('is-hidden')) {
      const placeholder = firstMatchedInput.data('placeholder-numeric');
      firstMatchedInput.prop('placeholder', placeholder);
      firstMatchedInput.val('');
      secondMatchedComponent.addClass('is-hidden');
      secondMatchedInput.val('');
      secondMatchedInput.prop('disabled', 'disabled');
    }
  }
});

$(document).on('click', 'a.delete-condition', (e) => {
  e.preventDefault();

  const conditionRowId = $(e.target).data('modal-id').split('_')[1];
  window.deleteCondition(conditionRowId);
  if ($('.conditions-table tr:not(.is-hidden)').length === 0) {
    $('.conditions-wrapper .info.inner-card').removeClass('is-hidden');
    $('.conditions-wrapper .table').addClass('is-hidden');
  }
});

window.deleteCondition = (conditionRowId) => {
  const $cardContent = $(`.conditions-table tr#condition-${conditionRowId}`);
  const conditionId = $cardContent.find('.rule_conditions_id input').val();

  if (conditionId) {
    $cardContent.addClass('is-hidden');
    $cardContent.find('.rule_conditions__destroy input').val(true);
    $cardContent.find('.rule_conditions_operator select').val('').trigger('chosen:updated');
    $cardContent.find('.rule_conditions_operator select').prop('disabled', 'disabled');
    $cardContent.find('.rule_conditions_template_ids select').val('').trigger('chosen:updated');
    $cardContent.find('.rule_conditions_template_ids select').prop('disabled', 'disabled');
    $cardContent.find('.rule_conditions_template_ids').addClass('is-hidden');
    $cardContent.find('.rule_conditions_aggregation_ids select').val('').trigger('chosen:updated');
    $cardContent.find('.rule_conditions_aggregation_ids select').prop('disabled', 'disabled');
    $cardContent.find('.rule_conditions_aggregation_ids').addClass('is-hidden');
    $cardContent.find('.rule_conditions_option_ids select').val('').trigger('chosen:updated');
    $cardContent.find('.rule_conditions_option_ids select').prop('disabled', 'disabled');
    $cardContent.find('.rule_conditions_option_ids').addClass('is-hidden');
    $cardContent.find('.rule_conditions_first_matched_value input').val('').trigger('chosen:updated');
    $cardContent.find('.rule_conditions_first_matched_value input').prop('disabled', 'disabled');
    $cardContent.find('.rule_conditions_first_matched_value').addClass('is-hidden');
    $cardContent.find('.rule_conditions_second_matched_value input').val('').trigger('chosen:updated');
    $cardContent.find('.rule_conditions_second_matched_value input').prop('disabled', 'disabled');
    $cardContent.find('.rule_conditions_second_matched_value').addClass('is-hidden');
  } else {
    $cardContent.remove();
  }

  const $option = $(`#select-rule-condition-fields option[value='${conditionRowId}']`);
  $option.attr('selected', false);
  $option.attr('disabled', false);
};
