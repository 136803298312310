import { breakpoint } from '@bizneo/core/utils';
import { createTooltip } from '@bizneo/tooltip/tooltip';

const SELECTOR = '.main-menu';
const BTN_SELECTOR = '#main-menu-btn';

function createDesktopSubmenu(i, el) {
  const $el = $(el);
  const submenuTmpl = el.querySelector('.main-submenu');
  const childs = submenuTmpl ? submenuTmpl.content.querySelectorAll('.tab-item') : false;
  $el.append(`<span class="text">${el.title}</span>`);
  if (!childs) {
    createTooltip(el, { title: el.title, type: 'none', tooltip: 'right' });
  } else {
    $('<div class="submenu-element"></div>').html(submenuTmpl.content.cloneNode(true)).insertAfter($el);
    submenuTmpl.content.prepend($(`<div class="main-submenu-title">${el.title}</div>`)[0]);
    if (breakpoint.value === 'tablet') {
      $(el).on('click', e => {
        if (!$('body').hasClass('menu-toggled')) e.preventDefault();
      });
    }
    createTooltip(el, {
      title: submenuTmpl.content,
      type: 'none',
      tooltip: 'right',
      trigger: breakpoint.value === 'tablet' ? 'click' : 'mouseenter focus',
      theme: 'main-submenu-dropdown light',
      interactive: true,
      delay: 100,
    });
  }
  $el.removeAttr('title');
}

function createMobileSubmenu(i, el) {
  const $el = $(el);
  const submenuTmpl = el.querySelector('.main-submenu');
  const childs = submenuTmpl ? submenuTmpl.content.querySelectorAll('.tab-item') : false;
  $el.append(`<span>${el.title}</span>`);
  if (childs) {
    $el.on('click', e => {
      e.preventDefault();
      $el.toggleClass('opened');
    });
    $('<div class="submenu-element"></div>').html(submenuTmpl.content).insertAfter($el);
  }
}

function toggleMenu() {
  $('body').toggleClass('menu-toggled');
}

function initMainMenu(i, el) {
  if (breakpoint.value === 'mobile') {
    $(el).find('.menu-element').each(createMobileSubmenu);
  } else {
    $(el).find('.menu-element').each(createDesktopSubmenu);
  }
}

$(document).on('click', BTN_SELECTOR, () => toggleMenu());

// Hide menu on click outside any menu elements
$(document).on('click', e => {
  const $target = $(e.target);
  if (!$target.closest(SELECTOR).length && !$target.closest('[data-tippy-root]').length && !$target.is(BTN_SELECTOR)) {
    $('body').removeClass('menu-toggled');
  }
});

$(document).on('dom-update', e => {
  const $target = $(e.target);
  if ($target.is(SELECTOR)) initMainMenu(null, e.target);
  $target.find(SELECTOR).each(initMainMenu);
}).on('click', 'a.link-submenu', (e) => {
  e.preventDefault();
  $(e.target).closest('li.menu-item').find('ul.menu-block').toggle();
});
