import { expandReplyForm, expandUpdateForm } from '../../features/companies/conversations';

window.Bizneo.conversations_controller = {
  actions() {
    expandReplyForm();
    expandUpdateForm();
  },
};

jQuery(() => {
  if ($('.conversations-controller-js').length) {
    window.Bizneo.conversations_controller.actions();
  }
});
