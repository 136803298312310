import initializeLocationSuggesters from '../features/locations_suggester';

window.Bizneo.location_suggester_controller = {
  initialize_location_suggesters() {
    window.Bizneo.controls.initialize_chose_plugin();
    initializeLocationSuggesters();
  },
};

jQuery(() => {
  window.Bizneo.location_suggester_controller.initialize_location_suggesters();
});
