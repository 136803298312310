export function changeIframe() {
  $(document).on('change', '#select-company-custom-reports', (e) => {
    const $iframe = $('#show-custom-reports');

    if (!e.target.value.length) {
      $iframe.addClass('is-hidden').prop('src', '');
      $('.no-results').removeClass('is-hidden');
    } else {
      const companyId = window.location.pathname.split('/')[2];
      const customReportId = e.target.value;
      $iframe.removeClass('is-hidden').prop('src', '');
      $('.no-results').addClass('is-hidden');
      $.ajax({
        type: 'GET',
        url: `/trabajar/${companyId}/reports/custom_reports/${customReportId}`,
        success(result) {
          const { url } = result;

          $iframe.prop('src', url);
        },
      });
    }
  });
}

function showStandarReportsTab() {
  $('.standard-reports').addClass('is-hidden');
  $('.custom-reports').addClass('is-hidden');

  const $activeTab = $('.main-container .tabs-cnt .tabs a.active');
  if ($activeTab.length) {
    const { id } = $activeTab.data();

    $(`.${id}`).removeClass('is-hidden');
  }
}

export function navReportsTabs() {
  showStandarReportsTab();

  $(document).on('click', '.nav-changer:not(.active)', () => { showStandarReportsTab(); });
}
