import { actions } from '../features/companies/candidacy_statuses';

window.Bizneo.recruitment_process_statuses_controller = {
  edit() {
    actions();
  },
};

jQuery(() => {
  if ($('.recruitment-process-statuses-controller-js').length) {
    window.Bizneo.recruitment_process_statuses_controller.edit();
  }
});
