import { locale } from './i18n';

function getPlaceholder(element) {
  const firstOption = element.options[0];
  if (element.getAttribute('placeholder')) {
    return element.getAttribute('placeholder');
  } else if (firstOption && firstOption.value === '') {
    return firstOption.text;
  }
  return false;
}

const formatSelect2Option = (opt) => {
  let classes = '';

  if (opt.element?.classList.length > 0) {
    classes = ` class="${opt.element.classList}"`;
  }
  if (opt.element?.dataset?.color) {
    let { color } = opt.element.dataset;
    if (color === 'default') { color = 'blue'; }
    let text = '';
    if (opt.element?.dataset?.parent) {
      const { parent } = opt.element.dataset;
      text = `<div class='text'><span class='title'>${parent}</span><span class='info'><div class='location'>${opt.text}</div></span></div>`;
    } else {
      text = `<div class='text'><span class='title'>${opt.text}</span></div>`;
    }
    const colorOption = `<div class='status-dot color is-${color}'><i class='dot'></i></div>${text}`;
    return $(`<div${classes}>${colorOption}</div>`);
  }

  let fawIcon = '';
  if (opt.element?.dataset?.icon) {
    fawIcon = `<i class="huge-icon is-xsmall ${opt.element.dataset.icon}"></i>`;
  }
  return $(`<span${classes}>${fawIcon}${opt.text}</span>`);
};

export default async function mountSelect(element) {
  const placeholder = getPlaceholder(element);
  const $el = $(element);
  const creatable = element.dataset.creatable !== undefined;
  const parentSelector = element.dataset.dropdownparent;
  await import(/* webpackChunkName: "select2" */ 'select2');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/es');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/en');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/fr');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/it');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/hr');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/pt');
  await import(/* webpackChunkName: "select2" */ 'select2/dist/js/i18n/pt-BR');

  const availableLocales = ['es', 'en', 'fr', 'it', 'hr', 'pt', 'pt-BR'];
  // eslint-disable-next-line import/extensions, import/no-useless-path-segments

  // Before init check if select2 is already instanced
  const $next = $el.next();
  if ($next.is('.select2')) $next.remove();

  $el.select2({
    placeholder,
    allowClear: !element.required,
    selectOnClose: creatable,
    minimumResultsForSearch: creatable ? 0 : 10,
    language: availableLocales.find(availableLocale => (locale === availableLocale)) ? locale : 'es',
    tags: creatable,
    width: '100%',
    dropdownParent: parentSelector ? $(parentSelector) : $('body'),
    templateResult: formatSelect2Option,
    templateSelection: formatSelect2Option,
  });

  /* Workaround to avoid scroll lock when opened selector is removed from dom by intercooler
  Ref: https://github.com/select2/select2/issues/3320
  */
  $el.on('select2:unselecting', () => $el.data('unselecting', true));
  $el.on('select2:open', () => {
    if ($el.data('unselecting')) {
      $el.removeData('unselecting'); // you need to unset this before close
      $el.select2('close');
    }
  });
  /* Workaround end */

  // Avoid that the selector options remains opened when a modal is closed
  $(document).on('modal:close', () => {
    if ($el.data('select2')) $el.select2('close');
  });

  return $el;
}

$(document).on('dom-update', e =>
  $(e.target)
    .find('.with-select2')
    .each((_, element) => mountSelect(element)));
