import { initDatepicker, historicalRemovedModal } from '../../features/companies/removed_resources';

window.Bizneo.removed_resources_controller = {
  index() {
    initDatepicker();
    historicalRemovedModal();
  },
};

jQuery(() => {
  if ($('.removed-resources-controller-js').length) {
    window.Bizneo.removed_resources_controller.index();
  }
});
