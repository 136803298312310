import { updateAggregation, cancelUpdateAggregation } from '../../features/companies/aggregations';

window.Bizneo.aggregations_controller = {
  actions() {
    updateAggregation();
    cancelUpdateAggregation();
  },
};

jQuery(() => {
  if ($('.aggregations-controller-js').length) {
    window.Bizneo.aggregations_controller.actions();
  }
});
