const orderActivities = (activities, activity) => Object.keys(activities)
  .sort((a, b) => activities[b][activity] - activities[a][activity])
  .slice(0, 10)
  .map((key) => ({
    recruiter_id: key,
    metrics: activities[key],
  }));

function recruiterRanking(position, recruiterName, activityMetric) {
  let recruiterRankingHtml = `<span class='position'>${position}</span>`;
  recruiterRankingHtml += `<span class='name'>${recruiterName}</span>`;
  recruiterRankingHtml += `<span class='number'>${activityMetric}</span>`;
  return recruiterRankingHtml;
}

function recruitersRanking(element) {
  const activityText = element.text();
  $('.leader-board .bread-filters .filters .drop-btn .default').html(activityText);
  const activity = element.siblings('input').val();
  const recruiterActivities = JSON.parse($('#recruiter_activities').val());
  const recruitersInfo = JSON.parse($('#recruiters_info').val());
  const activities = orderActivities(recruiterActivities, activity);
  return (() => {
    const result = [];
    for (let index = 0; index < activities.length; index += 1) {
      let activityMetric = activities[index];
      const recruiterId = activityMetric.recruiter_id;
      activityMetric = activityMetric.metrics[activity];
      const recruiterName = recruitersInfo[recruiterId];
      const position = (index + 1);
      const recruiterRankingClass = `leaderboard-ranking-${position}`;
      const recruiterRankingHtml = recruiterRanking(position, recruiterName, activityMetric);
      result.push($(`.${recruiterRankingClass}`).html(recruiterRankingHtml));
    }
    return result;
  })();
}

$(document).on('click', '.leader-board .bread-filters .filters .filter-link', (e) => {
  e.preventDefault();
  recruitersRanking($(e.target));
});

$(document).on('mouseup', (e) => {
  const $container = $('.leader-board .bread-filters .drop-btn');
  if (!$container.is(e.target) && ($container.has(e.target).length === 0)) {
    $container.removeClass('active');
  }
});

$(document).on('click', '.leader-board .bread-filters .drop-btn', (e) => {
  e.preventDefault();
  $(e.target).toggleClass('active');
});

$(document).on('click', '.total-values, .average-values', (e) => {
  e.preventDefault();
  $('.total, .average').hide();
  $('.total-values, .average-values').removeClass('active');

  if ($(e.target).hasClass('total-values')) {
    $('.total').show();
    $('.total-values').addClass('active');
  }

  if ($(e.target).hasClass('average-values')) {
    $('.average').show();
    $('.average-values').addClass('active');
  }
});
