const PUB_OPT_SELECTOR = 'select.publication_options.autosubmit';
const SELECTOR = '.col-5.publication-preference-definitions.autosubmit select';
const INPUT = '.col-5.publication-preference-definitions.autosubmit input';
const GENERAL_CLASS = '.col-5.publication-preference-definitions';
const BTN = '.form-submit-btn';
const CLOSE_BTN = '.close-bottom-wrapper';

function formSubmit(form) {
  window.$(form).submit();
}

window.$(document).on('change', PUB_OPT_SELECTOR, (event) => {
  formSubmit($(event.target).closest('form'));
});

window.$(document).on('change', SELECTOR, (event) => {
  formSubmit($(event.target).closest('form'));
});

window.$(document).on('change', INPUT, (event) => {
  formSubmit($(event.target).closest('form'));
});

window.$(document).on('change', GENERAL_CLASS, (event) => {
  $(event.target).closest('div[id^=portal]').find('a[id^=commit-publication]').addClass('disabled');
});

window.$(document).on('click', BTN, (event) => {
  formSubmit($(event.target).closest('.portal-module').find('.bottom-wrapper form'));
});

window.$(document).on('click', CLOSE_BTN, (event) => {
  $(event.target).closest('.bottom-wrapper').toggle();
});
