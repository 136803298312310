import { copyToClipboardAction, verificationFlowActions } from '../../features/companies/whatsapp_business';

window.Bizneo.whatsapp_business_controller = {
  verificationFlow(whatsappBusinessAccountId) {
    verificationFlowActions(whatsappBusinessAccountId);
  },
  addCopyAction() {
    copyToClipboardAction();
  },
};

jQuery(() => {
  const $whatsappVerification = $('.whatsapp-business-controller-verification-js');
  if ($whatsappVerification.length) {
    const verification = $whatsappVerification.data('verification');
    window.Bizneo.whatsapp_business_controller.verificationFlow(verification);
  }

  window.Bizneo.whatsapp_business_controller.addCopyAction();
});
