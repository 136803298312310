const SELECTOR = '[data-tooltip]';

async function loadComponent() {
  const { default: mountTooltip } = await import(/* webpackChunkName: "tooltip" */ '@bizneo/tooltip/tooltip');

  return mountTooltip;
}

$(document).on('dom-update', (e) => {
  $(e.target)
    .find(SELECTOR)
    .each((_, element) => loadComponent().then((mountTooltip) => mountTooltip(element)));
});
