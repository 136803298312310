import waitForPassRatePromise from './common';

export function successGenderRecruitersReport(data) {
  const updateDOM = () => {
    if (Object.keys(data.data).length === 0) {
      $('.pass-rate-gender-recruiters-report-data-loading').addClass('is-hidden');
      $('.pass-rate-gender-recruiters-report-data-empty').removeClass('is-hidden');
      $('.recruiters-phases').html('');
    } else {
      $('.recruiters-phases').html(data.html);

      $('.pass-rate-gender-recruiters-report-data-loading').addClass('is-hidden');
      $('.pass-rate-gender-recruiters-report-data-empty').addClass('is-hidden');
      $('.recruiters-phases').removeClass('is-hidden');
    }
  };

  waitForPassRatePromise('recruiters').then(updateDOM);
}

export function successGenderSourcesReport(data) {
  const updateDOM = () => {
    if (Object.keys(data.data).length === 0) {
      $('.pass-rate-gender-sources-report-data-loading').addClass('is-hidden');
      $('.pass-rate-gender-sources-report-data-empty').removeClass('is-hidden');
      $('.sources-phases').html('');
    } else {
      $('.sources-phases').html(data.html);

      $('.pass-rate-gender-sources-report-data-loading').addClass('is-hidden');
      $('.pass-rate-gender-sources-report-data-empty').addClass('is-hidden');
      $('.sources-phases').removeClass('is-hidden');
    }
  };

  waitForPassRatePromise('sources').then(updateDOM);
}

export function errorGenderRecruitersReport() {
  $('.pass-rate-gender-recruiters-report-data-loading').addClass('is-hidden');
  $('.pass-rate-gender-recruiters-report-data-empty').addClass('is-hidden');
  $('.pass-rate-gender-recruiters-report-data-error').removeClass('is-hidden');
}

export function errorGenderSourcesReport() {
  $('.pass-rate-gender-sources-report-data-loading').addClass('is-hidden');
  $('.pass-rate-gender-sources-report-data-empty').addClass('is-hidden');
  $('.pass-rate-gender-sources-report-data-error').removeClass('is-hidden');
}
