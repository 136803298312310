import dragula from 'dragula';

function generateInputs() {
  const array = $('.container-target .phase-action .pipeline-item').toArray();
  document.getElementById('inputs').innerHTML = array.reduce(
    (initValue, currentValue, index) => {
      const quality = !!currentValue.getElementsByClassName('huge-icon huge-star fas').length;
      return `${initValue}<input name=pipeline[pipeline_phases_attributes][${index}][phase_id] type=hidden value=${currentValue.getAttribute('data-id')} >` +
        `<input name=pipeline[pipeline_phases_attributes][${index}][quality] type=hidden value=${quality} >` +
        `<input name=pipeline[pipeline_phases_attributes][${index}][order] type=hidden value=${index} >`;
    },
    '',
  );
}

export function dragAndDrop() {
  dragula([document.getElementById('source'), document.getElementById('target')], {
    moves(el, container, handle) {
      return handle.classList.contains('handle');
    },
    accepts(el, target) {
      return target !== document.getElementById('source');
    },
    copy(el, source) {
      return source === document.getElementById('source');
    },
  // eslint-disable-next-line consistent-return
  }).on('drop', (el) => {
    const { id } = $(el).data();
    $(el).addClass('target');
    if (el.parentNode === document.getElementById('target')) {
      $(`#phase-${id}`).addClass('selected');
      $(`#phase-${id}`).find('span.handle').remove();
    }
    generateInputs();
  });
}

export function initializeAlreadyDropped() {
  generateInputs();
  $(document).on('click', '.phase-action.target .huge-delete-02', (e) => {
    const parent = $(e.target).closest('.phase-action');
    const id = parent.data('id');
    $(`#phase-${id}`).removeClass('selected');
    $(`#phase-${id} .tag .text`).prepend("<i class='handle huge-icon huge-menu-01 is-xsmall' />");
    parent.remove();
    generateInputs();
  });
  $(document).on('click', '.phase-action.target .quality-phase', (e) => {
    $(e.target).toggleClass('fas');
    generateInputs();
  });
}
