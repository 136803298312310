function createOrUpdateSetting(name, type) {
  const $tags = $(`.${name}`);
  if (type === 'selector' || type === 'multiselector') {
    $tags.chosen();
  }
  $tags.each((index, element) => {
    // element == this
    $(element).off('change').change((e) => {
      const value = (() => {
        switch (type) {
          case 'checkbox_input':
            return $(e.target).prop('checked');
          default:
            return $(e.target).val();
        }
      })();
      if ($(e.target).data('configurable-id') !== null) {
        $.ajax({
          type: 'PUT',
          url: $(e.target).data('url'),
          data: {
            key: name,
            value,
            configurable_name: $(e.target).data('configurable-name'),
            configurable_id: $(e.target).data('configurable-id'),
            settings: $(`div[id=${$(e.target).data('configurable-name')}-options-list-${$(e.target).data('configurable-id')}] :input`.replace('null', '')).serializeArray(),
          },
        });
      }
    });
  });
}

window.Bizneo.setting_prototyping = {
  create_or_update_settings(settingDefinitions) {
    $.each(settingDefinitions, (index, definition) => {
      createOrUpdateSetting(
        definition.name,
        definition.type,
      );
    });
  },
};
