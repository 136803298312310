import {
  cloneRequisition, datepicker, formula,
  detailMembers, changeRequisitionTemplate,
  removeCloneAsset, highlighterKeywords,
  resetRadioButton,
} from '../../features/companies/requisitions';
import { showMoreOrLessHistorical } from '../../features/companies/legals';

window.Bizneo.requisitions_controller = {
  index() {
    highlighterKeywords();
  },
  special_fields() {
    datepicker();
    formula();
  },
  show() {
    showMoreOrLessHistorical();
    detailMembers();
  },
  new() {
    cloneRequisition();
    changeRequisitionTemplate();
    removeCloneAsset();
    resetRadioButton();
  },
  edit() {
    resetRadioButton();
  },
  highlighter_keywords() {
    highlighterKeywords();
  },
};

jQuery(() => {
  if ($('.requisitions-controller-edit-js').length) {
    window.Bizneo.requisitions_controller.edit();
  }
  if ($('.requisitions-controller-index-js').length) {
    window.Bizneo.requisitions_controller.index();
  }
  if ($('.requisitions-controller-new-js').length) {
    window.Bizneo.requisitions_controller.new();
  }
  if ($('.requisitions-controller-show-js').length) {
    window.Bizneo.requisitions_controller.show();
  }
  if ($('.requisitions-controller-special-fields-js').length) {
    window.Bizneo.requisitions_controller.special_fields();
  }
});
