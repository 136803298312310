export default function loadOptions() {
  $(document).on('change', '#recruiter_notification_model_to_notify', (e) => {
    const companyId = window.location.pathname.split('/')[2];
    $.ajax({
      type: 'GET',
      url: `/trabajar/${companyId}/recruiter_notifications_actions`,
      data: { recruiter_notification: { model_to_notify: $(e.target, 'option:selected').val() } },
      success(result) {
        const { actions } = result;

        $('#recruiter_notification_action').empty();

        $.each(actions, (i, val) => {
          $('#recruiter_notification_action').append(`<option value='${val[1]}'>${val[0]}</option>`);
        });
        $('#recruiter_notification_action').trigger('chosen:updated');
      },
    });
  });
}
