$(document).on('dom-update', (e) => {
  $(e.target).find('.custom-form-check input:not([type=hidden])').each((index, element) => {
    // element == this
    const $element = $(element);
    $element.is(':checked') ? $element.closest('.custom-form-check').addClass('checked') : $element.closest('.custom-form-check').removeClass('checked');
  });

  // checkbox for chatbot modals
  if (document.querySelector('.modal.active')) {
    if ($(e.target).attr('class') !== undefined && $(e.target).attr('class').includes('new-option')) {
      $(e.target).find('.form-check.in-modal').each((index, element) => {
        const $element = $(element);

        if ($element.prop('checked')) {
          $element.prop('checked', false);
          $element.closest('.form-check').removeClass('checked');
        } else {
          $element.prop('checked', true);
          $element.closest('.form-check').addClass('checked');
        }
      });
    }
  }
});

$(document).on('click', '.form-radio.in-modal label', (e) => {
  $(`.modal #${$(e.target).attr('for')}`).prop('checked', true);
});
