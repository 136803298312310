class LocationSuggester {
  constructor(suggester) {
    this.suggester = suggester;
    this.select = $(`#${this.suggester.attr('id').replace('_chosen', '')}`);
    this.chosen_results = suggester.find('.chosen-results');
    this.chosen_search = suggester.find('.chosen-search input');
  }

  clearSelect() {
    this.select.html("<option value=''></option>");
    return this.chosen_results.empty();
  }

  appendNewOption(position, location) {
    this.select.append(`<option value='${location.uid}'>${location.suggest_route}</option>`);
    return (
      this.chosen_results.append(`
        <li class='active-result' data-option-array-index='${position}'>
          ${location.suggest_route}
        </li>
      `)
    );
  }
}

function loadSuggestedLocations(suggester, locations) {
  suggester.clearSelect();

  // eslint-disable-next-line
  for (let position in locations) {
    const location = locations[position];
    if (location.uid != null) {
      suggester.appendNewOption(position, location);
    }
  }

  suggester.chosen_results.find('.active-result').click((e) => {
    $($(suggester.select).find('option')[parseInt(e.target.dataset.optionArrayIndex, 10) + 1]).prop('selected', 'selected');
    suggester.select.trigger('chosen:updated');
  });
}

function getSuggestionsFor(suggester, value) {
  if (value !== undefined && value !== null && value.length >= 3) {
    const countryCode = $('select.location-country-code').val();
    let suggestUrl = `/suggest/locations?q=${value}`;
    if (countryCode) {
      suggestUrl += `&country=${countryCode}`;
    } else {
      suggestUrl += '&type=city';
    }

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: suggestUrl,
      success(result) {
        loadSuggestedLocations(suggester, result.locations);
      },
    });
  } else {
    loadSuggestedLocations(suggester, []);
  }
}

function addSuggesterEvents(suggester) {
  suggester.chosen_search.keyup((e) => getSuggestionsFor(suggester, e.target.value));
}

function initializeSuggesters() {
  $('.location-suggester.chosen-container').each((index, element) => {
    const locationSuggester = new LocationSuggester($(element));
    addSuggesterEvents(locationSuggester);
    const select = $(`#${$(element).attr('id').replace('_chosen', '')}`);
    if (select.find('option:selected').text()) {
      $('#location_chosen > a:nth-child(1) > span:nth-child(1)').text(select.find('option:selected').text());
    }
  });
}

export default function initializeLocationSuggesters() {
  initializeSuggesters();
}
