function changeImageInputPreview(input) {
  const defaultSrc = document.querySelector('#header-image-preview img')?.dataset?.default;
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = ((e) => {
      $('#header-image-preview img').attr('src', e.target.result);
      $('#header-image-preview').removeClass('is-hidden');
    });
    reader.readAsDataURL(input.files[0]);
  } else if (defaultSrc !== undefined) {
    $('#header-image-preview img').attr('src', new URL(defaultSrc).href);
    $('#header-image-preview').removeClass('is-hidden');
  } else {
    $('#header-image-preview').addClass('is-hidden');
    $('#header-image-preview img').attr('src', '');
  }
}

// eslint-disable-next-line
export function imageInputPreviewAction() {
  $('#campaign_image, #whatsapp_business_template_image').change(function updatePreview() {
    changeImageInputPreview(this);
  });
}
