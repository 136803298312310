$(document).on('change', '#legals_translation_display_options', () => {
  $('.privacy-urls').toggleClass('is-hidden');
  $('.terms_and_conditions').toggleClass('is-hidden');
  $('#legal_translation_cookies_policy_url')[0].toggleAttribute('disabled');
  $('#legal_translation_privacy_policy_url')[0].toggleAttribute('disabled');
  $('#legal_translation_legal_notice_url')[0].toggleAttribute('disabled');
});

$(document).on('change', '#legal_translation_locales', (e) => {
  const $element = $('#sites-locales');
  const sitesLocales = $element.data('sites-locales');
  const locale = $(e.target).val();
  const $secondSelect = $('#legal_translation_sites');
  $secondSelect.find('option').remove();
  $.each(sitesLocales[locale], (key, value) => {
    $secondSelect.prepend(`<option value="${key === '' ? 'nil' : key}">${value}</option>`);
  });
  $secondSelect.removeAttr('disabled').val('').trigger('chosen:updated');
});
