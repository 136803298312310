$(document).on('change', '#document_templates_template_request_signature', (e) => {
  const textYes = $('#document_templates_template_request_signature').data('yes');
  const textNo = $('#document_templates_template_request_signature').data('no');
  const signatureIntegrated = $('#document_templates_template_request_signature').data('signature-integrated');
  const $this = $(e.target);

  if ($this.is(':checked')) {
    $('.document-template-signature-label').html(textYes);
    if (signatureIntegrated === false) {
      $('.document-template-send-editable-format-alert').removeClass('is-hidden');
      $('.document_template_submit').addClass('is-hidden');
    }
    $("input:radio[name='document_templates_template[send_editable_format]']").val(['false']);
    $('.row_document_templates_template_send_editable_format').addClass('is-hidden');
    $('.email-tab-link').addClass('is-hidden');
  } else {
    $('.document-template-signature-label').html(textNo);
    $('.document-template-send-editable-format-alert').addClass('is-hidden');
    $('.document_template_submit').removeClass('is-hidden');
    $('.row_document_templates_template_send_editable_format').removeClass('is-hidden');
    $('.email-tab-link').removeClass('is-hidden');
  }
});
