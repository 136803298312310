let removeNestedFieldsCounter = 0;

$(document).on('click', '.files-documents .card-actions .remove_nested_fields', () => {
  removeNestedFieldsCounter += 1;
});
$(document).on('click', '.files-documents .card-actions .remove_nested_fields, .add-assets-button .add_nested_fields', () => {
  $('#submit-company-question-response-set').show();
});
$(document).on('click', '.files-documents .actions .remove_nested_fields', (e) => {
  if (removeNestedFieldsCounter === 0 && !$(e.target).closest('.card').find('.requisition_assets_file:visible').length) {
    $('#submit-company-question-response-set').hide();
  }
});
