export function updateAggregation() {
  $(document).on('click', '.link-aggregation-edit', (e) => {
    const $element = $(e.target);
    const $form = $element.parents('[id*=aggregation-]').find('.simple_form');
    $form.removeClass('is-hidden');
    const options = $element.parents('[id*=aggregation-]').find('.table-column.options');
    options.addClass('is-hidden');
  });
}

export function cancelUpdateAggregation() {
  $(document).on('click', '.table-column.link.cancel', (e) => {
    const $element = $(e.target);
    const $options = $element.parents('[id*=aggregation-]').find('.table-column.options');
    $options.removeClass('is-hidden');
    const $form = $element.parents('[id*=aggregation-]').find('.simple_form');
    $form.addClass('is-hidden');
  });
}
