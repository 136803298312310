$(document).on('click', '.step-validation .next-step', (e) => {
  e.preventDefault();

  const $this = $(e.target).hasClass('next-step') ? $(e.target) : $(e.target).closest('.next-step');

  $this.closest('.step').addClass('is-hidden');
  $($this.prop('hash')).removeClass('is-hidden');
  if (!$('#comment-step-valid')[0].classList.contains('is-hidden')) {
    $('#rejected-comment').prop('disabled', true);
  }
  if (!$('#comment-step-rejected')[0].classList.contains('is-hidden')) {
    $('#valid-comment').prop('disabled', true);
  }

  if ($this.data('check')) $($this.data('check')).prop('checked', true);
});
