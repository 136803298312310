import initializeJobSuggesters from '../features/jobs_suggester';

window.Bizneo.jobs_suggester_controller = {
  initialize_jobs_suggesters() {
    window.Bizneo.controls.initialize_chose_plugin();
    initializeJobSuggesters();
  },
};

jQuery(() => {
  window.Bizneo.jobs_suggester_controller.initialize_jobs_suggesters();
});
