import dragula from 'dragula';

function updateSelectFormFields($profileItem) {
  const component = $profileItem.find('input.component').val();

  if (component === 'title') {
    return;
  }

  const allElements = $profileItem.find('input.all_elements').val();
  const section = $profileItem.find('input.section').val();

  let key;

  if (allElements === 'true' && ['scorecard', 'video_interview', 'chat_bot_chat'].includes(component)) {
    key = 'all';
  } else if (component === 'scorecard') {
    key = $profileItem.find('input.scorecard_template_id').val();
  } else if (component === 'video_interview') {
    key = $profileItem.find('input.video_interview_id').val();
  } else if (component === 'custom_field') {
    key = $profileItem.find('input.custom_field_group_id').val();
  } else if (component === 'chat_bot_chat') {
    key = $profileItem.find('input.chat_bot_chat_id').val();
  } else {
    key = $profileItem.find('input.key').val();
  }

  const fieldValue = `${section}__${component}__${key}`;

  $(`.select-shared-candidates-profile-fields-${section} option[value=${fieldValue}]`).removeAttr('disabled');
  $(`.select-shared-candidates-profile-fields-${section} option[value=${fieldValue}]`).removeAttr('selected');
  $(`.select-shared-candidates-profile-fields-${section}`).val('').trigger('chosen:updated');

  if (component === 'scorecard') {
    $(`.select-shared-candidates-profile-fields-scorecard option[value=${fieldValue}]`).removeAttr('disabled');
    $(`.select-shared-candidates-profile-fields-scorecard option[value=${fieldValue}]`).removeAttr('selected');
    $('.select-shared-candidates-profile-fields-scorecard').val('').trigger('chosen:updated');
  } else if (component === 'video_interview') {
    $(`.select-shared-candidates-profile-fields-video-interviews option[value=${fieldValue}]`).removeAttr('disabled');
    $(`.select-shared-candidates-profile-fields-video-interviews option[value=${fieldValue}]`).removeAttr('selected');
    $('.select-shared-candidates-profile-fields-video-interviews').val('').trigger('chosen:updated');
  } else if (component === 'chat_bot_chat') {
    $(`.select-shared-candidates-profile-fields-chat-bot-chats option[value=${fieldValue}]`).removeAttr('disabled');
    $(`.select-shared-candidates-profile-fields-chat-bot-chats option[value=${fieldValue}]`).removeAttr('selected');
    $('.select-shared-candidates-profile-fields-chat-bot-chats').val('').trigger('chosen:updated');
  }
}

function updateSectionSelects() {
  $('.select-shared-candidates-profile-fields-header').val('').trigger('chosen:updated');
  $('.select-shared-candidates-profile-fields-left').val('').trigger('chosen:updated');
  $('.select-shared-candidates-profile-fields-center').val('').trigger('chosen:updated');
  $('.select-shared-candidates-profile-fields-scorecard').val('').trigger('chosen:updated');
  $('.select-shared-candidates-profile-fields-video-interviews').val('').trigger('chosen:updated');
  $('.select-shared-candidates-profile-fields-chat-bot-chats').val('').trigger('chosen:updated');
}

$(document).on('change', '#shared_candidates_fields_section_header', () => {
  $('.modal-body .section-selects .header-section').removeClass('is-hidden');
  $('.modal-body .section-selects .left-section').addClass('is-hidden');
  $('.modal-body .section-selects .center-section').addClass('is-hidden');

  updateSectionSelects();
});

$(document).on('change', '#shared_candidates_fields_section_left', () => {
  $('.modal-body .section-selects .left-section').removeClass('is-hidden');
  $('.modal-body .section-selects .header-section').addClass('is-hidden');
  $('.modal-body .section-selects .center-section').addClass('is-hidden');

  updateSectionSelects();
});

$(document).on('change', '#shared_candidates_fields_section_center', () => {
  $('.modal-body .section-selects .center-section').removeClass('is-hidden');
  $('.modal-body .section-selects .header-section').addClass('is-hidden');
  $('.modal-body .section-selects .left-section').addClass('is-hidden');

  updateSectionSelects();
});

$(document).on('click', '.modal-shared-candidates-field-form .close-ds-modal', () => {
  $('.modal-body .section-selects .left-section').addClass('is-hidden');
  $('.modal-body .section-selects .header-section').addClass('is-hidden');
  $('.modal-body .section-selects .center-section').addClass('is-hidden');

  updateSectionSelects();
});

$(document).on('click', '.action-shared-candidates-field-remove', (e) => {
  e.preventDefault();
  const $profileItem = $(e.target).closest('.shared-candidates-profile-item');
  const $sectionWrapper = $($profileItem).parent();
  const $section = $(`.${$($sectionWrapper).data('section')}`);

  $profileItem.addClass('is-hidden');
  $profileItem.find('input.destroy').val('1');

  $($sectionWrapper).children('.shared-candidates-profile-item').not('.is-hidden').each((index, element) => {
    $(element).find('input.position').val(index);
  });

  if ($($sectionWrapper).children('.shared-candidates-profile-item').not('.is-hidden').length === 0) {
    $section.find('div.info').removeClass('is-hidden');
  }

  updateSelectFormFields($profileItem);
});

let fieldTitleValue;
let fieldIconValue;

$(document).on('click', '.action-shared-candidates-field-edit', (e) => {
  const $profileItem = $(e.target).closest('.shared-candidates-profile-item');
  const $wrapperInfo = $($profileItem).find('.wrapper-info');
  const $wrapperForm = $($profileItem).find('.wrapper-edit-form');
  const $buttonsRow = $($profileItem).find('.buttons-row');

  fieldTitleValue = $wrapperForm.find('.title').val();
  fieldIconValue = $wrapperForm.find('.icon').children('option:selected').val();

  $($wrapperInfo).addClass('is-hidden');
  $($wrapperForm).removeClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-edit').addClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-remove').addClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-update').removeClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-update-cancel').removeClass('is-hidden');
});

$(document).on('click', '.action-shared-candidates-field-update', (e) => {
  const $profileItem = $(e.target).closest('.shared-candidates-profile-item');
  const $wrapperInfo = $($profileItem).find('.wrapper-info');
  const $wrapperForm = $($profileItem).find('.wrapper-edit-form');
  const $buttonsRow = $($profileItem).find('.buttons-row');

  const titleValue = $wrapperForm.find('.title').val();

  $($wrapperInfo).find('.row span.field-title').html(titleValue);
  $($wrapperInfo).find('.row span.field-icon i').attr('class', 'icon is-xsmall');
  $($wrapperInfo).removeClass('is-hidden');
  $($wrapperForm).addClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-edit').removeClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-remove').removeClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-update').addClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-update-cancel').addClass('is-hidden');
});

$(document).on('click', '.action-shared-candidates-field-update-cancel', (e) => {
  const $profileItem = $(e.target).closest('.shared-candidates-profile-item');
  const $wrapperInfo = $($profileItem).find('.wrapper-info');
  const $wrapperForm = $($profileItem).find('.wrapper-edit-form');
  const $buttonsRow = $($profileItem).find('.buttons-row');

  $wrapperForm.find('.title').val(fieldTitleValue);
  $wrapperForm.find('.icon').val(fieldIconValue).trigger('chosen:updated');

  $($wrapperInfo).removeClass('is-hidden');
  $($wrapperForm).addClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-edit').removeClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-remove').removeClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-update').addClass('is-hidden');
  $($buttonsRow).find('.action-shared-candidates-field-update-cancel').addClass('is-hidden');
});

$(document).ready(() => {
  const headerDragula = dragula([document.getElementsByClassName('header-section-fields-wrapper')[0]], {
    invalid(el) {
      return $(el).hasClass('not_move');
    },
  });

  headerDragula.on('drop', (el) => {
    const elementPosition = $('form .header-section-fields-wrapper .shared-candidates-profile-item').index($(el));
    if (elementPosition === 0) {
      headerDragula.cancel(true);
      return false;
    }

    $('form .header-section-fields-wrapper .shared-candidates-profile-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });

    return true;
  });

  dragula([document.getElementsByClassName('left-section-fields-wrapper')[0]]).on('drop', () => {
    $('form .left-section-fields-wrapper .shared-candidates-profile-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });

  dragula([document.getElementsByClassName('center-section-fields-wrapper')[0]]).on('drop', () => {
    $('form .center-section-fields-wrapper .shared-candidates-profile-item').not('.is-hidden').each((index, element) => {
      $(element).find('input.position').val(index);
    });
  });
});

$(document).on('dom-update', (e, data) => {
  if (data !== undefined && data.source === 'modal') {
    if ($('.modal-shared-candidates-field-form').length > 0) {
      $('.section-selects .header-section').addClass('is-hidden');
      $('.section-selects .left-section').addClass('is-hidden');
      $('.section-selects .center-section').addClass('is-hidden');
      $('.modal-shared-candidates-field-form input:radio:checked').prop('checked', false);
    }
  }
});
