import {
  fillActionStatusSelect, fillTriggerEventSelect, changeActionCategory,
} from './actions';

import fillTriggerEvent from './configuration';

$(document).on('click', '.workflow-remove-action', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.action-item');
  const $destroyField = $($parent).find('.workflow-action-destroy');

  $destroyField.val('true');
  $($parent).addClass('is-hidden');

  if ($this.data('action') === 'move_status') {
    $(".action-buttons .menu-item[data-action='move_status']").removeClass('is-disabled');
  }
});

const cleanPhaseStatusesFilter = () => {
  const $accordionHead = $('.accordion.phase-statuses .accordion-head');
  const $accordionContent = $('.accordion.phase-statuses .accordion-content');
  $accordionHead.data('number-elements', 0);
  $accordionHead.html(`${$accordionHead.data('title')} (${$accordionHead.data('number-elements')})`);
  $accordionContent.find('.filters').html('');
  if (!$accordionHead.hasClass('collapsed')) {
    $accordionHead.addClass('collapsed');
    $accordionContent.hide();
  }
};

$(document).on('change', '.workflows-settings #workflows_workflow_event', (e) => {
  const $this = $(e.target);

  $('.trigger-options').html('');
  $('.audience-options').html('');
  $('.trigger-event-options').addClass('is-hidden');
  $('.workflow_trigger_event').html('');
  cleanPhaseStatusesFilter();

  if ($this.val() === 'task_completed' || $this.val() === 'task_expired') {
    $('.workflow_trigger_event').html('<option value="" label=" "></option>');
  }

  $('.workflows-settings .historical').removeClass('is-hidden');
  fillTriggerEvent($this.val());

  $('.advanced-configuration').trigger('dom-update');

  if ($('.workflow_trigger_event').val()) {
    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: {
        trigger: {
          workflow_event: $('#workflows_workflow_event').val(),
          event: $('.workflow_trigger_event').val(),
        },
      },
      url: $('.workflow-form').data('workflows-data-url'),
    });
  }
});

$(document).on('change', '.workflow_ambit', (e) => {
  const $this = $(e.target);

  $('.ambit-site-options').addClass('is-hidden');
  $('.workflow_ambit_site').val('');

  $('.ambit-aggregation-options').addClass('is-hidden');
  $('.workflow_ambit_aggregation').val('');

  if ($this.val() === 'site') {
    $('.ambit-site-options').removeClass('is-hidden');
  } else if ($this.val() === 'aggregation') {
    $('.ambit-aggregation-options').removeClass('is-hidden');
  }

  $('.ambit-site-options').trigger('dom-update');
  $('.ambit-aggregation-options').trigger('dom-update');
});

$(document).on('change', '.workflows-settings .workflow_trigger_event', (e) => {
  const $this = $(e.target);

  $.ajax({
    type: 'GET',
    dataType: 'script',
    data: {
      trigger: {
        event: $this.val(),
        workflow_event: $('#workflows_workflow_event').val(),
      },
    },
    url: $('.workflow-form').data('workflows-data-url'),
  });
});

$(document).on('change', '.workflows-settings .workflow_trigger_audience', (e) => {
  const $this = $(e.target);

  $('.workflow_trigger_task_element_ids').val('');

  if ($this.val() === 'for_all') {
    $('#task_element_category_wrapper').addClass('is-hidden');
    $('#task_element_category_filter_wrapper').addClass('is-hidden');
  }

  if ($this.val() === 'categories') {
    $('#task_element_category_wrapper').removeClass('is-hidden');
    $('#task_element_category_filter_wrapper').addClass('is-hidden');
    $('#workflow_trigger_task_element_categories').attr('disabled', false);
    $('#workflow_trigger_task_element_elements').attr('disabled', true);
  }

  if ($this.val() === 'objects') {
    $('#task_element_category_wrapper').addClass('is-hidden');
    $('#task_element_category_filter_wrapper').removeClass('is-hidden');
    $('#workflow_trigger_task_element_elements').attr('disabled', false);
    $('#workflow_trigger_task_element_categories').attr('disabled', true);
  }

  $('#task_element_category_wrapper').trigger('dom-update');
  $('#task_element_category_filter_wrapper').trigger('dom-update');
});

$(document).on('change', '.workflow_trigger_phase', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.row');
  const $statusesSelect = $($parent).find('.workflow_trigger_status');

  $($statusesSelect).html('<option value="" label=" "></option>');

  const phaseStatuses = $this.find('option:selected').data('statuses');
  if (phaseStatuses === undefined) {
    $statusesSelect.data('statuses').forEach((element) => {
      $($statusesSelect).append(`<option value="${element[1]}">${element[0]}</option>`);
    });
  } else {
    $this.find('option:selected').data('statuses').forEach((element) => {
      $($statusesSelect).append(`<option value="${element[1]}">${element[0]}</option>`);
    });
  }
});

$(document).on('change', '#task_element_category_ids', (e) => {
  const $this = $(e.target);
  const $objectsSelect = $('#task_element_category_filter_wrapper').find('.workflow_trigger_task_element_ids');

  $($objectsSelect).html('');

  const categoryObjects = $this.find('option:selected').data('elements');
  if (categoryObjects === undefined) {
    $objectsSelect.data('elements').forEach((element) => {
      $($objectsSelect).append(`<option value="${element[1]}">${element[0]}</option>`);
    });
  } else {
    $this.find('option:selected').data('elements').forEach((element) => {
      $($objectsSelect).append(`<option value="${element[1]}">${element[0]}</option>`);
    });
  }
});

$(document).on('change', '.workflow_action_phase', (e) => {
  const $this = $(e.target);
  fillActionStatusSelect($this);
});

$(document).on('change', '.workflow_action_kind', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.action-item');

  $($parent).find('.workflow-phase-status select').val('');
  $($parent).find('.workflow-phase-status').trigger('dom-update');

  $($parent).find('.workflow-phase-status').addClass('is-hidden');

  if ($this.val() === 'phase_status') {
    $($parent).find('.workflow-phase-status').removeClass('is-hidden');
  }
});

$(document).on('change', '.action-default-video-interview', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.action-item');

  if ($this.prop('checked')) {
    $($parent).find('.action_category_ids').val('');
    $($parent).find('.action_element_id').val('');

    $($parent).find('.video-interview-selects').trigger('dom-update');
    $($parent).find('.video-interview-selects').addClass('is-hidden');
  } else {
    $($parent).find('.video-interview-selects').removeClass('is-hidden');
  }
});

$(document).on('change', '.action-default-chat-bot', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.action-item');

  if ($this.prop('checked')) {
    $($parent).find('.action_category_ids').val('');
    $($parent).find('.action_element_id').val('');

    $($parent).find('.chat-bot-selects').trigger('dom-update');
    $($parent).find('.chat-bot-selects').addClass('is-hidden');
  } else {
    $($parent).find('.chat-bot-selects').removeClass('is-hidden');
  }
});

$(document).on('click', '.workflows-settings .trigger-options .accordion .accordion-head b', (e) => {
  const $this = $(e.target);
  const $accordion = $this.closest('.accordion-head');
  let advancedOptionsText = '';
  if ($accordion.hasClass('collapsed')) {
    advancedOptionsText = $accordion.data('collapsed');
  } else {
    advancedOptionsText = $accordion.data('open');
  }
  $this.text(advancedOptionsText);
});

$(window).on('load', () => {
  if ($('form.workflow-form').length) {
    if ($('.workflows-settings #workflows_workflow_event').val()) {
      fillTriggerEventSelect();
    }
  }
});

$(document).on('change', '.workflow_switch_enabled', (e) => {
  const $this = $(e.target);
  const checked = $this.is(':checked');

  $.ajax({
    type: 'PUT',
    dataType: 'script',
    data: { workflow: { checked } },
    url: $this.data('url'),
    success() {},
    error() {},
  });
});

$(document).on('change', '.email_action_destination', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.action-item');

  if ($this.val() === 'recruiters') {
    $($parent).find('.email_action_recruiters').removeClass('is-hidden');
    $($parent).find('.advanced-options').removeClass('is-hidden');
  } else {
    $($parent).find('.input_email_action_send_to_job_teams').prop('checked', false);
    $($parent).find('.select_email_action_company_teams').val('');
    $($parent).find('.input_email_action_external_emails').val('');
    $($parent).find('.email_action_recruiters').addClass('is-hidden');
    $($parent).find('.advanced-options').addClass('is-hidden');

    $($parent).find('.advanced-options .accordion-content').trigger('dom-update');
  }
});

$(document).on('click', '.remove-email-message-translation', (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.messages-translation');
  const $parentId = $parent.data('id');
  const $parentWrapper = $parent.closest('.language-translations');
  const $messageId = $parent.find('.email-message-id').val();

  if ($messageId !== undefined) {
    $(`.tabs-cnt .tabs .tab-item.${$parentId}`).addClass('is-hidden deleted');
    $(`.messages-translation.${$parentId} input.input-remove-email-message-translation`).val('true');
    $(`.messages-translation.${$parentId}`).addClass('is-hidden deleted');

    $(`.messages-translation.${$parentId} .input-default-language`).prop('checked', false);
    $(`.messages-translation.${$parentId} .input-subject`).val('');
    // eslint-disable-next-line no-undef
    if (CKEDITOR !== undefined) {
      const modalId = $(`.messages-translation.${$parentId} .input-body`).attr('id');
      // eslint-disable-next-line no-undef
      CKEDITOR.instances[modalId].setData('');
    }
  } else {
    $(`.messages-translation.${$parentId}`).remove();
    $(`.tabs-cnt .tabs .tab-item.${$parentId}`).remove();
  }

  $parentWrapper.find('.tabs-cnt .tabs .tab-item:not(.deleted):first').click();
  $(`.tabs-cnt .dropdown-menu .menu-${$parentId}`).removeClass('is-disabled');
});

const removePhaseStatus = (e) => {
  const $this = $(e.target);
  const $parent = $this.closest('.row');
  const $phaseStatus = $($parent).closest('.phase-status');
  if ($($parent).find('input.workflow-phase-status-id').length) {
    const $destroyField = $($parent).find('input.workflow-phase-status-destroy');

    $destroyField.val('true');
    $($phaseStatus).addClass('is-hidden');
  } else {
    $phaseStatus.remove();
  }

  const $accordionHead = $('.accordion.phase-statuses .accordion-head');
  $accordionHead.data('number-elements', $accordionHead.data('number-elements') - 1);
  $accordionHead.html(`${$accordionHead.data('title')} (${$accordionHead.data('number-elements')})`);
};

$(document).on('click', '.workflow-remove-phase-status', (e) => { removePhaseStatus(e); });

$(document).ready(() => { changeActionCategory(); });
