import Confirm from '@bizneo/confirm/confirm.svelte';

const SELECTOR = '[data-modal-confirm]';

$(document).on('click', `${SELECTOR}`, (e) => {
  const $el = $(e.target).data().confirm === undefined ? $(e.target).closest('[data-modal-confirm]') : $(e.target);
  const data = $el.data();
  data.confirm = data.modalConfirm;
  const confirm = new Confirm({
    target: $el.parents('body')[0],
    props: data,
  });

  confirm.$on('close', () => confirm.$destroy());
  confirm.$on('confirm', () => {
    if (data.removeNestedFields) {
      $(`#${data.removeNestedFields}`).find('.remove_nested_fields')[0].click();
      confirm.$destroy();
    } else {
      const $link = $(`<a href=${data.to}></a>`).hide();
      if (data.notRemote === undefined) $link.attr('data-remote', 'true').attr('data-method', 'delete');
      if (data.notRemote === undefined && data.actionMethod === undefined) $link.attr('data-method', 'delete');
      if (data.actionMethod) $link.attr('data-method', data.actionMethod);
      $el.after($link);
      $link[0].click();
      confirm.$destroy();
    }
  });
});
