import '@bizneo/table/tables';
import { createCollapsableTable } from '@bizneo/table/table-collapse-columns';

const SELECTOR_DATACOLLAPSABLE = '.table[data-collapsable-table]';
const MOVEMENT = 300;
const OFFSET = 50;

function moveRight($el) {
  const $tableWrap = $el.find('.table-wrap');
  $el.addClass('show-left-scroll');
  const scroll = $tableWrap[0];
  let move = MOVEMENT;
  if (scroll.scrollWidth - (scroll.offsetWidth + scroll.scrollLeft + move) < OFFSET) {
    move += OFFSET;
  }
  $tableWrap.scrollLeft($tableWrap.scrollLeft() + move);
}

function moveLeft($el) {
  const $tableWrap = $el.find('.table-wrap');
  $el.addClass('show-right-scroll');
  let move = MOVEMENT;
  if ($tableWrap.scrollLeft() - move < OFFSET) {
    move += OFFSET;
  }
  $tableWrap.scrollLeft($tableWrap.scrollLeft() - move);
}

$(document).on('dom-update', (e) => {
  $(e.target).find(SELECTOR_DATACOLLAPSABLE).each(createCollapsableTable);

  // eslint-disable-next-line consistent-return
  $(e.target).find('.table-wrap').each((index, element) => {
    const $tableWrap = $(element);
    if (!$tableWrap.length) return false;
    const $table = $tableWrap.find('.table');
    if (!$table.length) return false;
    const checkArrows = () => {
      const cntWidth = $tableWrap.outerWidth();
      if (cntWidth < $table[0].scrollWidth) {
        $tableWrap.closest('.table-with-scroll').addClass('show-right-scroll');
      } else {
        $tableWrap.closest('.table-with-scroll').removeClass('show-right-scroll');
        $tableWrap.closest('.table-with-scroll').removeClass('show-left-scroll');
      }
    };
    checkArrows();
    const observer = new ResizeObserver(() => checkArrows());
    $($tableWrap[0]).scroll(() => {
      if ($tableWrap[0].scrollLeft === 0) {
        $($tableWrap[0]).closest('.table-with-scroll').removeClass('show-left-scroll');
      } else {
        $($tableWrap[0]).closest('.table-with-scroll').addClass('show-left-scroll');
      }
      if ($tableWrap[0].offsetWidth + $tableWrap[0].scrollLeft >= $tableWrap[0].scrollWidth - 1) {
        $($tableWrap[0]).closest('.table-with-scroll').removeClass('show-right-scroll');
      } else {
        $($tableWrap[0]).closest('.table-with-scroll').addClass('show-right-scroll');
      }
    });
    observer.observe($tableWrap[0]);
  });
}).on('click', '.right-scroll', (e) => {
  e.preventDefault();
  moveRight($(e.target).closest('.table-with-scroll'));
}).on('click', '.left-scroll', (e) => {
  e.preventDefault();
  moveLeft($(e.target).closest('.table-with-scroll'));
});
