$(document).on('change', '#copy_template_create_option', (e) => {
  const $this = $(e.target);

  $('.submit-new-template').addClass('is-hidden');
  $('.submit-copy-template').addClass('is-hidden');
  $('.copy-template').addClass('is-hidden');

  if ($this.val() === 'new') {
    $('.submit-new-template').removeClass('is-hidden');
    $('.modal.active #copy_template_id').val('');
    $('.copy-template').trigger('dom-update');
  } else if ($this.val() === 'copy') {
    $('.copy-template').removeClass('is-hidden');
  }
});

$(document).on('change', '#copy_template_id', () => {
  $('.submit-copy-template').removeClass('is-hidden');
});
