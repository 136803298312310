window.Bizneo.recruitment_process_external_status_matchings = {
  fetch_phases() {
    $('#external_status_matching_aggregation_id').on('change', (e) => {
      const companyId = $('#new_external_status_matching').data().company;
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: `/trabajar/${companyId}/recruitment_process/phases/aggregation_phases?aggregation_id=${e.target.value}`,
        success(result) {
          const emptyOption = "<option value=''></option>";
          const options = result.reduce((acc, data) => `${acc}<option value='${data[1]}'>${data[0]}</option>`, emptyOption);
          $('#external_status_matching_phase_id').html(options);
          $('#external_status_matching_phase_id').trigger('chosen:updated');
        },
      });
    });
  },
  fetch_statuses() {
    $('#external_status_matching_phase_id').on('change', (e) => {
      if (e.target.value) {
        const companyId = $('#new_external_status_matching').data().company;
        $.ajax({
          type: 'GET',
          dataType: 'json',
          url: `/trabajar/${companyId}/recruitment_process/phases/${e.target.value}/statuses`,
          success(result) {
            const emptyOption = "<option value=''></option>";
            const options = result.reduce((acc, data) => `${acc}<option value='${data[1]}'>${data[0]}</option>`, emptyOption);
            $('#external_status_matching_status_id').html(options);
            $('#external_status_matching_status_id').trigger('chosen:updated');
          },
        });
      }
    });
  },
};
