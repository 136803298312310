import { reportsData } from './reports';

const SELECTOR = '[data-search-filters]';
const searches = [];

async function loadComponent() {
  const { default: SearchFilters } = await import(/* webpackChunkName: "search-filters" */ '@bizneo/search-filter/search-filters.svelte');

  return SearchFilters;
}

$(document).on('dom-update', (e) => {
  const localeData = document.documentElement.dataset;
  const params = new URLSearchParams(document.location.search.substring(1));
  const obj = JSON.parse(params.get('requisition'));

  if (obj && typeof obj === 'object' && obj.constructor === Object) {
    $('.search-info-message').removeClass('is-hidden');
  } else {
    $('.search-info-message').addClass('is-hidden');
  }

  $(e.target)
    .find(SELECTOR)
    .each((i, el) => {
      loadComponent().then((SearchFilters) => {
        const $el = $(el);
        const elementData = $el.data('search-filters');
        const locales = {
          i18n: $el.data('search-filters-hints'),
        };
        const data = $.extend({}, elementData, locales, localeData);

        const currentSearch = searches.find(search => (el === search.$$.root));
        if (!currentSearch) {
          /* eslint-disable no-new */
          const search = new SearchFilters({
            target: el,
            props: data,
          });
          searches.push(search);
          /* eslint-enable no-new */
        }

        reportsData();
      });
    });
});

$(document).ready(() => {
  const params = new URLSearchParams(document.location.search.substring(1));
  const obj = JSON.parse(params.get('requisition'));
  if (obj && obj.self_actions && obj.self_actions.includes('pending_review')) {
    $('.requisition-self-actions-check input').attr('checked', true);
  }
});
const setFilters = (filters) => searches[searches.length - 1].setFilters(filters);

$(document).on('change', '#requisition_self_actions', (e) => {
  const checked = $(e.target).is(':checked');
  $('.requisition-self-actions-check input').attr('checked', checked);
  const params = new URLSearchParams(document.location.search.substring(1));
  let requisition = JSON.parse(params.get('requisition'));
  requisition = requisition || {};
  if (checked && requisition.self_actions) requisition.self_actions.push('pending_review');
  if (checked && !requisition.self_actions) requisition.self_actions = ['pending_review'];
  if (!checked && requisition.self_actions) {
    if (requisition.self_actions.length < 2) {
      delete requisition.self_actions;
    } else {
      requisition.self_actions = requisition.self_actions.filter((a) => a !== 'pending_review');
    }
  }
  setFilters(requisition);
});

window.set_filters = setFilters;
