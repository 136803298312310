import dragula from 'dragula';

export function actions() {
  $(document).on('click', '.candidacy-status-item .action-icons .collapse-text', (e) => {
    e.preventDefault();
    $(e.target).closest('.candidacy-status-item').find('.edit_phase').slideToggle(500);
  });

  $(document).on('click', 'form.edit_phase .buttons-row .secondary', (e) => {
    e.preventDefault();
    $(e.target).closest('.candidacy-status-item').find('.edit_phase').hide();
  });
}

export function edit() {
  dragula([document.getElementById('status_container')], {
    invalid(el) {
      return $(el).hasClass('not_move');
    },
  // eslint-disable-next-line consistent-return
  }).on('drop', (el, container) => {
    if ($(container).length) {
      const elementPosition = $('div.candidacy-status-item').index($(el));
      if (elementPosition === 0) {
        $(el).addClass('error');
        $('.error-move', $(el)).removeClass('is-hidden');
        return false;
      }

      $(el).removeClass('error');
      $('.error-move', $(el)).addClass('is-hidden');
      if ($('div.candidacy-status-item.error', container).length >= 1) return false;

      let orderIds = '';

      $('.candidacy-status-item', container).each((index, element) => {
        if (orderIds !== '') orderIds += ',';
        orderIds += $(element).data('status-id');
      });

      $.ajax({
        type: 'GET',
        url: $('#reorder_url').val(),
        data: { phase: { order_ids: orderIds } },
        success() {},
        error() {},
      });
    }
  });
}
