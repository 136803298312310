const SELECTOR = '.dropdown';

async function loadComponent() {
  const { default: mountDropdown } = await import(/* webpackChunkName: "dropdown" */ '@bizneo/dropdown/dropdown');

  return mountDropdown;
}

$(document).on('dom-update', (e) => $(e.target)
  .find(SELECTOR)
  .each((_, element) => loadComponent().then((mountDropdown) => mountDropdown(element))));

$(document).on('click', '.tippy-box .menu-item', (e) => {
  if ($(e.target).hasClass('not-close-dropdown')) return;

  const $element = $(e.target).closest('.dropdown');
  if ($element.length) {
    $element.find('.tippy-box').hide();
    $element.find('.dropdown-btn')[0].click();
    setTimeout(() => {
      $element.find('.tippy-box').show();
    }, 0);
  }
});
