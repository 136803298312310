import { dragAndDrop, initializeAlreadyDropped } from '../../features/companies/pipelines';

window.Bizneo.pipelines_controller = {
  new() {
    dragAndDrop();
    initializeAlreadyDropped();
  },
};

jQuery(() => {
  if ($('.pipelines-controller-js').length) {
    window.Bizneo.pipelines_controller.new();
  }
});
