import 'jquery-ui/ui/widgets/autocomplete';
import videojs from 'video.js';

window.isInView = (element, offset = 100) => (($(window).scrollTop() + $(window).height()) - offset) >= $(element).position().top;

window.scrollTo = (element, time = 1000, offset = 0) => {
  if (element.length) {
    $('html, body').animate({ scrollTop: $(element).offset().top - offset }, time);
  }
};

window.counterEffect = (element, duration = 1000) => $({ countNum: $(element).text() }).animate(
  { countNum: $(element).data('target') },
  {
    duration,
    easing: 'linear',
    step() {
      $(element).text(Math.floor(this.countNum));
    },
    complete() {
      $(element).text(this.countNum);
    },
  },
);

window.counterScore = (element, duration = 1000) => $({ countNum: $(element).text() }).animate(
  { countNum: $(element).data('target') },
  {
    duration,
    easing: 'linear',
    step() {
      $(element).attr('data-target', Math.floor(this.countNum));
    },
    complete() {
      $(element).attr('data-target', this.countNum);
    },
  },
);

window.chosen_plugin = () => {
  // Chosen plugin.
  //
  // - Initialize on all selects without ".no-decorator" class
  $('select.deselect:not(.no-decorator):not(.flatpickr-monthDropdown-months)').chosen({
    allow_single_deselect: true,
  });

  $('select.location-suggester:not(.no-decorator):not(.flatpickr-monthDropdown-months), select.job-suggester:not(.no-decorator):not(.flatpickr-monthDropdown-months)').chosen({
    inherit_select_classes: true,
  });

  $('select:not(.no-decorator):not(.flatpickr-monthDropdown-months)').each((index, element) => {
    $(element).chosen({
      allow_single_deselect: !$(element).hasClass('disable-select'),
      search_contains: true,
    }).on('change', (event, info) => {
      if ($(event.target).attr('multiple') === undefined) return;
      if (info.selected) {
        const allSelected = event.target.querySelectorAll('option[selected]');
        const lastSelected = allSelected[allSelected.length - 1];
        const selected = event.target.querySelector(`option[value="${info.selected}"]`);
        selected.setAttribute('selected', '');
        $(lastSelected).length ?
          $(selected).insertAfter($(lastSelected)) : $(event.target).prepend($(selected));
      } else { // info.deselected
        const allSelected = event.target.querySelectorAll('option[selected]');
        const lastSelected = allSelected[allSelected.length - 1];
        const removed = event.target.querySelector(`option[value="${info.deselected}"]`);
        removed.setAttribute('selected', false); // this step is required for Edge
        removed.removeAttribute('selected');
        if ($(lastSelected).length) $(removed).insertAfter($(lastSelected));
      }
      $(event.target).trigger('chosen:updated');
    }).on('chosen:ready', (e, p) => {
      // eslint-disable-next-line no-console
      console.log(e, p);
    });
  });

  return $(document).on('focus', 'select:not(.no-decorator):not(.flatpickr-monthDropdown-months)', () => $(this).closest('.chosen-container').trigger('mousedown.chosen'));
};

window.reload_options_dynamically_chosen_plugin = (selector) => {
  $(selector).trigger('chosen:updated').change();
};

const submitButtonVisibility = (el = '.textarea-note') => {
  // eslint-disable-next-line no-undef
  if (CKEDITOR !== undefined) {
    $(el).each((_index, element) => {
      // eslint-disable-next-line no-undef
      const hasHandlerChangeEvent = CKEDITOR.instances[element.id].hasListeners('change');
      if (!hasHandlerChangeEvent) {
        // eslint-disable-next-line no-undef
        CKEDITOR.instances[element.id].on('change', (e) => {
          const { editor } = e;
          const $this = $(editor.element.$);
          const $btnRow = $this.closest('form').find('.buttons-row');
          editor.getData().length ? $btnRow.removeClass('is-hidden') : $btnRow.addClass('is-hidden');
        });
      }
    });
  }
};

const removeCKeditors = (el = '.js-ckeditor') => {
  $(el).each((index, element) => {
    // eslint-disable-next-line no-undef
    if (CKEDITOR && CKEDITOR.instances[element.id]) CKEDITOR.instances[element.id].destroy();
  });
};

const initializeCKeditor = (el = '.js-ckeditor') => {
  // eslint-disable-next-line no-undef
  if (CKEDITOR !== undefined) {
    $(el).each((_index, element) => {
      const $element = $(element);
      const newRequisitionPage = document.getElementsByClassName('new-request-page').length > 0;
      // eslint-disable-next-line no-undef
      if (!newRequisitionPage && CKEDITOR.instances[element.id]) return;
      const options = {
        toolbar: $element.data('toolbar'),
        placeholder_select: $element.data('placeholder-select'),
        editorplaceholder: $element.prop('placeholder'),
      };
      const mentions = $element.data('mentions');
      if (mentions !== undefined) {
        const dataFeed = (opts, callback) => {
          const matchProperty = 'id';
          let data = mentions.filter((item) => item[matchProperty].indexOf(opts.query.toLowerCase()) === 0);
          data = data.sort((a, b) => a[matchProperty].localeCompare(b[matchProperty], undefined, { sensitivity: 'accent' }));
          callback(data);
        };
        options.mentions = [
          {
            feed: dataFeed,
            itemTemplate: '<li data-id="{id}"><img class="photo" src="{image}" /><strong class="username">{id}</strong><span class="fullname">{name}</span></li>',
            outputTemplate: '@{id}',
            minChars: 0,
          },
        ];
      }
      // eslint-disable-next-line no-undef
      CKEDITOR.replace(element.id, options);
    });
  }
};

const initializeVideoJs = (element) => {
  videojs(element, {
    controls: true,
    bigPlayButton: true,
    width: 500,
    height: 300,
    fluid: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
      volumePanel: true,
      playbackRateMenuButton: true,
    },
  });
};

function initializePlugins() {
  window.chosen_plugin();
  // Form autosubmit
  //
  // - Initialize on all forms without ".no-autosubmit" class
  $('form.autosubmit:not(.no-autosubmit) select').change((e) => $(e.target).parents('form').submit());

  // Input file
  $('.input-upload input:file').change(() => $(this).parents('.module-information').find('.asset-title-module-information').html($(this).val())).css('border-width', () => {
    if (navigator.appName !== 'Microsoft Internet Explorer') return false;
    return 0;
  });

  // JQueryUI autocomplete with accents
  //
  const accentMap = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
  };

  const normalize = (term) => {
    let ret = '';
    let i = 0;

    while (i < term.length) {
      ret += accentMap[term.charAt(i)] || term.charAt(i);
      i += 1;
    }
    return ret;
  };

  // Form autosubmit for all inputs with autocomplete class
  $('form.autosubmit:not(.no-autosubmit) .autocomplete').autocomplete({
    select(event, ui) {
      return $(this).val(ui.item.value).parents('form').submit();
    },
  });

  $('.autocomplete').each(() => {
    const autocompleteArray = $(this).data('autocomplete');
    return $(this).autocomplete({
      delay: 300,
      minLength: 2,
      autoFocus: false,
      source(request, response) {
        const matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), 'i');
        return response($.grep(autocompleteArray, (value) => {
          const val = value.label || value.value || value;
          return matcher.test(val) || matcher.test(normalize(val));
        }).slice(0, 10));
      },
    });
  });

  initializeCKeditor();
  submitButtonVisibility();

  $(document).ready(() => $('a[data-toggle=modal], button[data-toggle=modal]').click((e) => {
    if (typeof $(e.target).data('id') !== 'undefined') {
      const dataId = $(e.target).data('id');
      const dataName = $(e.target).data('name');

      $('#modal-company').html(dataName);
      $('#modal-text').html('Enviar currículum a');

      return $('input[name=referral_company]').attr('value', dataId);
    }
    $('#modal-company').html('');
    $('#modal-text').html('Crear cuenta');
    return $('input[name=referral_company]').attr('value', '');
  }));

  if ($('#registerCompanyModal .error')[0] !== undefined) {
    $('#registerCompanyModal').modal();
  }

  return $('.teaser #scroll-to').click(() => window.scrollTo('.teaser .content', 1000, 0));
}

$(document).ready(() => {
  initializePlugins();
});

$(document).on('nested:fieldAdded', () => initializePlugins());

window.Bizneo.controls = {
  initialize() {
    return initializePlugins();
  },
  removeCK(el = '.js-ckeditor') { removeCKeditors(el); },
  initializeCK(el = '.js-ckeditor') { initializeCKeditor(el); },
  initializeVideoJs(el = '.video-js-visor') { initializeVideoJs(el); },
  submitButton(el = '.textarea-note') { submitButtonVisibility(el); },
  initialize_chose_plugin() {
    return window.chosen_plugin();
  },
};
