import companyTeamsEdit from '../../features/companies/company_teams';

window.Bizneo.company_teams_controller = {
  edit() {
    companyTeamsEdit();
  },
};

jQuery(() => {
  window.Bizneo.company_teams_controller.edit();
});
