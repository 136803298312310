export default function fillTriggerEvent(value) {
  $('#trigger_event option').each((_index, element) => {
    const dataEvents = $(element).attr('data-events');
    if (dataEvents.includes(value)) {
      const $newOption = $(element).clone();
      $('.workflow_trigger_event').append($newOption);
    }
  });

  if ($('.workflow_trigger_event option').length === 1) {
    $('.workflow_trigger_event option:eq(1)').prop('selected', true);
    $('#audience_wrapper').removeClass('is-hidden');
  } else {
    $('.trigger-event-options').removeClass('is-hidden');
  }
}
