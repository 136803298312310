import '@bizneo/pb-iframe/index';

$(document).on('dom-update', () => {
  const notifications = localStorage.getItem('notifications');
  const releaseNumber = $('[data-news-wrapper]').data('news-release');

  if (notifications === null || +notifications !== releaseNumber) {
    $('[data-toggle-news-panel]').addClass('active');
    $('[data-news-wrapper]').addClass('auto-opened-notifications');
  }
});

$(document).on('click', '[data-toggle-news-panel]', () => {
  $('[data-news-wrapper]').removeClass('auto-opened-notifications');
});
