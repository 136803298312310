import { clickOutside } from '@bizneo/core/utils';

const SELECTOR = '.contextual-menu';

function activeMenu(index, element) {
  const $el = $(element);
  const $parent = $el.closest(SELECTOR);
  const $floatingMenu = $el.find('.floating-menu');

  // Active semihover state
  $el.on('click', '.btn', (e) => {
    e.preventDefault();
    e.stopPropagation();

    const ww = window.innerWidth;
    const rect = e.currentTarget.getBoundingClientRect();

    // We must be sure the floating menu container is THE LAST element on the page
    $('#floating-menu-container').remove();
    const $floatingMenuContainer = $('<div id="floating-menu-container">')
      .appendTo($('body'))
      .on('mouseenter', () => {
        $parent.addClass('active');
      })
      .on('mouseleave', () => {
        $parent.removeClass('active');
        $floatingMenuContainer.hide();
      });

    // Right value depends if inside tippy modal
    const css = {
      top: `${rect.top + 40}px`,
      right: `${ww - rect.right}px`,
    };

    const $clone = $floatingMenu.clone().css(css).addClass('is-visible');
    $('#floating-menu-container').html($clone).trigger('dom-update', { source: 'contextual-menu' }).show();

    clickOutside(element, () => {
      $parent.removeClass('active');
      $floatingMenuContainer.hide();
    });
  });

  // Hide menu on menu element click
  $el.on('click', '.menu-item', () => {
    $parent.removeClass('contextual-menu-active');
  });
}

$(document).on('dom-update', e => $(e.target).find(SELECTOR).each(activeMenu));
