import waitForPassRatePromise from './common';

export function successFunnelReport(data) {
  const updateDOM = () => {
    if (Object.keys(data.data).length === 0) {
      $('.pass-rate-funnel-report-data-loading').addClass('is-hidden');
      $('.pass-rate-funnel-report-data-empty').removeClass('is-hidden');
      $('.funnel-chart').html('');
    } else {
      $('.funnel-chart').html(data.html);
      $('.pass-rate-funnel-report-data-loading').addClass('is-hidden');
      $('.pass-rate-funnel-report-data-empty').addClass('is-hidden');
      window.Bizneo.report_charts_controller.init_reports();
      window.Bizneo.dom.update('.charts');
      $('.charts').removeClass('is-hidden');
    }
  };

  waitForPassRatePromise('funnel').then(updateDOM);
}

export function successRecruitersReport(data) {
  const updateDOM = () => {
    if (Object.keys(data.data).length === 0) {
      $('.pass-rate-recruiters-report-data-loading').addClass('is-hidden');
      $('.pass-rate-recruiters-report-data-empty').removeClass('is-hidden');
      $('.recruiters-phases').html('');
    } else {
      $('.recruiters-phases').html(data.html);

      $('.pass-rate-recruiters-report-data-loading').addClass('is-hidden');
      $('.pass-rate-recruiters-report-data-empty').addClass('is-hidden');
      $('.recruiters-phases').removeClass('is-hidden');
    }
  };

  waitForPassRatePromise('recruiters').then(updateDOM);
}

export function successSourcesReport(data) {
  const updateDOM = () => {
    if (Object.keys(data.data).length === 0) {
      $('.pass-rate-sources-report-data-loading').addClass('is-hidden');
      $('.pass-rate-sources-report-data-empty').removeClass('is-hidden');
      $('.sources-phases').html('');
    } else {
      $('.sources-phases').html(data.html);

      $('.pass-rate-sources-report-data-loading').addClass('is-hidden');
      $('.pass-rate-sources-report-data-empty').addClass('is-hidden');
      $('.sources-phases').removeClass('is-hidden');
    }
  };

  waitForPassRatePromise('sources').then(updateDOM);
}

export function errorFunnelReport() {
  $('.pass-rate-funnel-report-data-loading').addClass('is-hidden');
  $('.pass-rate-funnel-report-data-empty').addClass('is-hidden');
  $('.pass-rate-funnel-report-data-error').removeClass('is-hidden');
}

export function errorRecruitersReport() {
  $('.pass-rate-recruiters-report-data-loading').addClass('is-hidden');
  $('.pass-rate-recruiters-report-data-empty').addClass('is-hidden');
  $('.pass-rate-recruiters-report-data-error').removeClass('is-hidden');
}

export function errorSourcesReport() {
  $('.pass-rate-sources-report-data-loading').addClass('is-hidden');
  $('.pass-rate-sources-report-data-empty').addClass('is-hidden');
  $('.pass-rate-sources-report-data-error').removeClass('is-hidden');
}
