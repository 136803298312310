import flatpickrSetLocale from './flatpickr_set_locale';

const defaultOptions = {
  dateFormat: 'd/m/Y',
  animate: false,
  nextArrow: '<i class="huge-icon is-xsmall huge-arrow-right-01"></i>',
  prevArrow: '<i class="huge-icon is-xsmall huge-arrow-left-01"></i>',
  allowInput: true,
  disableMobile: true,
};

function showLoading() {
  $('.report-connection-error').addClass('is-hidden');

  $('.report-data-empty-dates').addClass('is-hidden');

  $('.funnel-report-data-timeout').addClass('is-hidden');
  $('.recruiters-phases-report-data-timeout').addClass('is-hidden');
  $('.sources-phases-report-data-timeout').addClass('is-hidden');
}

let $flatpickr;
export default async function initDatepicker(mode = 'range') {
  await import(/* webpackChunkName: "flatpickr-locale" */ 'flatpickr/dist/plugins/monthSelect/style');
  const { default: flatpickr } = await import(/* webpackChunkName: "flatpickr" */ 'flatpickr');
  const { default: MonthSelectPlugin } = await import(/* webpackChunkName: "flatpickr-locale" */ 'flatpickr/dist/plugins/monthSelect/index');
  const { default: WeekSelectPlugin } = await import(/* webpackChunkName: "flatpickr-locale" */ 'flatpickr/dist/plugins/weekSelect/weekSelect');

  let options = {};

  /* eslint-disable no-param-reassign */
  if (mode === 'range') {
    options = {
      mode: 'range',
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      onChange: (selectedDates, _dateStr, instance) => {
        const params = new URLSearchParams(document.location.search.substring(1));
        let report = JSON.parse(params.get('report'));
        report = report || {};
        if (selectedDates.length === 1) {
          const currentDate = new Date(selectedDates[0]);
          const minDate = new Date(currentDate);
          const maxDate = new Date(currentDate);
          minDate.setFullYear(minDate.getFullYear() - 1);
          maxDate.setFullYear(maxDate.getFullYear() + 1);

          instance.set('minDate', instance.formatDate(minDate, 'd/m/Y'));
          if (maxDate > new Date()) {
            instance.set('maxDate', 'today');
          } else {
            instance.set('maxDate', instance.formatDate(maxDate, 'd/m/Y'));
          }
        }

        if (selectedDates.length === 2) {
          $('#report_min_date').val(instance.formatDate(selectedDates[0], 'd/m/Y'));
          $('#report_max_date').val(instance.formatDate(selectedDates[1], 'd/m/Y'));
          report.min_date = instance.formatDate(selectedDates[0], 'd/m/Y');
          report.max_date = instance.formatDate(selectedDates[1], 'd/m/Y');
          showLoading();
          window.set_filters(report);
        }
      },
      onClose: (_selectedDates, _dateStr, instance) => {
        instance.set('minDate', null);
        instance.set('maxDate', 'today');
      },
    };

    if ($('#report_min_date').val() !== '' && $('#report_max_date').val() !== '') {
      options.defaultDate = [$('#report_min_date').val(), $('#report_max_date').val()];
    }
  }

  if (mode === 'month') {
    options = {
      dateFormat: 'd/m/Y',
      plugins: [
        new MonthSelectPlugin({
          dateFormat: 'F Y',
          altFormat: 'F',
        }),
      ],
      defaultDate: 'today',
      onChange: (selectedDates, _dateStr, instance) => {
        const params = new URLSearchParams(document.location.search.substring(1));
        let report = JSON.parse(params.get('report'));
        report = report || {};
        const currentDate = new Date();
        const currentMm = currentDate.getMonth() + 1; // Months start at 0!
        const currentYyyy = currentDate.getFullYear();
        const date = new Date(selectedDates[0]);
        const mm = date.getMonth() + 1; // Months start at 0!
        const yyyy = date.getFullYear();
        const firstDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDateOfMonth;

        if (mm === currentMm && yyyy === currentYyyy) {
          lastDateOfMonth = new Date();
        } else {
          lastDateOfMonth = new Date(yyyy, mm, 0);
        }

        $('#report_min_date').val(instance.formatDate(firstDateOfMonth, 'd/m/Y'));
        $('#report_max_date').val(instance.formatDate(lastDateOfMonth, 'd/m/Y'));

        showLoading();
        report.min_date = instance.formatDate(firstDateOfMonth, 'd/m/Y');
        report.max_date = instance.formatDate(lastDateOfMonth, 'd/m/Y');
        window.set_filters(report);
      },
    };
  }

  if (mode === 'week') {
    options = {
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      plugins: [new WeekSelectPlugin({})],
      defaultDate: 'today',
      onChange: (_selectedDates, _dateStr, instance) => {
        const params = new URLSearchParams(document.location.search.substring(1));
        let report = JSON.parse(params.get('report'));
        report = report || {};
        instance.input.value = [instance.weekStartDay, instance.weekEndDay].map(d => instance.formatDate(d, 'd/m/Y')).join(' - ');
        const weekEndDay = instance.weekEndDay > new Date() ? new Date() : instance.weekEndDay;
        $('#report_min_date').val(instance.formatDate(instance.weekStartDay, 'd/m/Y'));
        $('#report_max_date').val(instance.formatDate(weekEndDay, 'd/m/Y'));

        showLoading();
        report.min_date = instance.formatDate(instance.weekStartDay, 'd/m/Y');
        report.max_date = instance.formatDate(weekEndDay, 'd/m/Y');
        window.set_filters(report);
      },
      onClose: (_selectedDates, _dateStr, instance) => {
        instance.input.value = [instance.weekStartDay, instance.weekEndDay].map(d => instance.formatDate(d, 'd/m/Y')).join(' - ');
      },
    };
  }
  /* eslint-enable no-param-reassign */

  await flatpickrSetLocale(flatpickr);
  $flatpickr = flatpickr('#report_created_at', $.extend({}, defaultOptions, options));
}

function getMonday(d) {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
}

$(document).on('click', 'form.pass-rate-form .pass-rate-date', (e) => {
  $('.pass-rate-dates a.btn').removeClass('is-selected');
  $(e.target).addClass('is-selected');
  $flatpickr.destroy();
  const params = new URLSearchParams(document.location.search.substring(1));
  let report = JSON.parse(params.get('report'));
  report = report || {};
  const today = new Date();

  showLoading();

  if ($(e.target).data('date') === 'year') {
    $('.flatpickr-select').addClass('is-hidden');
    $('.year-select').removeClass('is-hidden');

    const date = $('#report_year_created_at').val();
    const yyyy = today.getFullYear();
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // Months start at 0!
    if (date !== '' && date !== yyyy) {
      $('#report_min_date').val(`1/1/${date}`);
      $('#report_max_date').val(`31/12/${date}`);
      report.min_date = `1/1/${date}`;
      report.max_date = `31/12/${date}`;
    } else {
      $('#report_min_date').val(`1/1/${date}`);
      $('#report_max_date').val(`${dd}/${mm}/${yyyy}`);
      report.min_date = `1/1/${yyyy}`;
      report.max_date = `${dd}/${mm}/${yyyy}`;
      $('#report_year_created_at').val(yyyy).trigger('chosen:updated');
    }
  } else {
    $('.year-select').addClass('is-hidden');
    $('.flatpickr-select').removeClass('is-hidden');
    const type = $(e.target).data('date');
    if (type === 'week') {
      const firstDate = getMonday(today);
      const minDate = `${firstDate.getDate()}/${firstDate.getMonth() + 1}/${firstDate.getFullYear()}`;
      const lastDate = new Date(firstDate.setDate(firstDate.getDate() + 6));
      const maxDate = `${lastDate.getDate()}/${lastDate.getMonth() + 1}/${lastDate.getFullYear()}`;
      $('#report_min_date').val(minDate);
      $('#report_max_date').val(maxDate);
      report.min_date = minDate;
      report.max_date = maxDate;
      initDatepicker(type);
      // eslint-disable-next-line no-underscore-dangle
      const fp = document.querySelector('#report_created_at')._flatpickr;
      setTimeout(() => {
        fp.input.value = [minDate, maxDate].join(' - ');
      }, 0);
    } else if (type === 'month') {
      const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
      const minDate = `${firstDate.getDate()}/${firstDate.getMonth() + 1}/${firstDate.getFullYear()}`;
      const maxDate = `${lastDate.getDate()}/${firstDate.getMonth() + 1}/${firstDate.getFullYear()}`;
      $('#report_min_date').val(minDate);
      $('#report_max_date').val(maxDate);
      report.min_date = minDate;
      report.max_date = maxDate;
      initDatepicker(type);
    } else {
      const $el = $('#report_created_at');
      const min = $el.data('default-min-range');
      const max = $el.data('default-max-range');
      $('#report_min_date').val(min);
      $('#report_max_date').val(max);
      report.min_date = min;
      report.max_date = max;
      initDatepicker(type);
    }
  }
  window.set_filters(report);
});

$(document).on('change', 'form.pass-rate-form #report_year_created_at', (e) => {
  e.preventDefault();

  const date = $(e.target).val();
  const today = new Date();
  const yyyy = today.getFullYear();
  showLoading();
  const params = new URLSearchParams(document.location.search.substring(1));
  let report = JSON.parse(params.get('report'));
  report = report || {};
  if (date !== yyyy) {
    $('#report_min_date').val(`1/1/${date}`);
    $('#report_max_date').val(`31/12/${date}`);
    report.min_date = `1/1/${date}`;
    report.max_date = `31/12/${date}`;
  } else {
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // Months start at 0!
    $('#report_min_date').val(`1/1/${yyyy}`);
    $('#report_max_date').val(`${dd}/${mm}/${yyyy}`);
    report.min_date = `1/1/${yyyy}`;
    report.max_date = `${dd}/${mm}/${yyyy}`;
  }
  window.set_filters(report);
});

$(document).on('change', 'form.pass-rate-form #report_pipeline_id', (e) => {
  e.preventDefault();
  showLoading();
  const val = $(e.target).val();
  const params = new URLSearchParams(document.location.search.substring(1));
  let report = JSON.parse(params.get('report'));
  report = report || {};
  if (val.length) {
    report.pipeline_id = val;
  } else {
    delete report.pipeline_id;
  }
  window.set_filters(report);
});

$(document).on('click', '.btn-pass-rate-recruiters-total, .btn-pass-rate-sources-total', (e) => {
  e.preventDefault();
  const type = $(e.target).data('type');

  $(`.btn-pass-rate-${type}-total`).addClass('is-selected');
  $(`.btn-pass-rate-${type}-percentage`).removeClass('is-selected');

  $(`.pass-rate-${type}-total`).removeClass('is-hidden');
  $(`.pass-rate-${type}-percentage`).addClass('is-hidden');
});

$(document).on('click', '.btn-pass-rate-recruiters-percentage, .btn-pass-rate-sources-percentage', (e) => {
  e.preventDefault();
  const type = $(e.target).data('type');

  $(`.btn-pass-rate-${type}-total`).removeClass('is-selected');
  $(`.btn-pass-rate-${type}-percentage`).addClass('is-selected');

  $(`.pass-rate-${type}-total`).addClass('is-hidden');
  $(`.pass-rate-${type}-percentage`).removeClass('is-hidden');
});
