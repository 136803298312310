function showOrHiddenAddOpengraphImageLink() {
  $('#site-link-add-opengraph-image').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-remove-opengraph-image', () => {
  showOrHiddenAddOpengraphImageLink();
});

$(document).on('click', '#site-link-add-opengraph-image', () => {
  showOrHiddenAddOpengraphImageLink();
});

function showOrHiddenAddFaviconLink() {
  $('#site-link-add-favicon').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-remove-favicon', () => {
  showOrHiddenAddFaviconLink();
});

$(document).on('click', '#site-link-add-favicon', () => {
  showOrHiddenAddFaviconLink();
});

function showOrHiddenAddListImageLink() {
  $('#site-link-add-list-image').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-add-list-image', () => {
  showOrHiddenAddListImageLink();
});

$(document).on('click', '#site-link-remove-list-image', () => {
  showOrHiddenAddListImageLink();
});

function hiddenLogoLink() {
  $('#site-link-add-image').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-add-image', () => {
  hiddenLogoLink();
});

$(document).on('click', '#site-link-remove-image', () => {
  hiddenLogoLink();
});

function showOrHiddenHeaderMobile() {
  $('#site-link-add-image-mobile').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-add-image-mobile', () => {
  showOrHiddenHeaderMobile();
});

$(document).on('click', '#site-link-remove-image-mobile', () => {
  showOrHiddenHeaderMobile();
});

function showOrHiddenCorporateImage() {
  $('#site-link-add-corporate-image').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-add-corporate-image', () => {
  showOrHiddenCorporateImage();
});

$(document).on('click', '#site-link-remove-corporate-image', () => {
  showOrHiddenCorporateImage();
});

function showOrHiddenHeaderImage() {
  $('#site-link-add-header-image').toggleClass('is-hidden');
}

function showOrHiddenHeaderImageMobile() {
  $('#site-link-add-header-image-mobile').toggleClass('is-hidden');
}

$(document).on('click', '#site-link-add-header-image', () => {
  showOrHiddenHeaderImage();
});

$(document).on('click', '#site-link-add-header-image-mobile', () => {
  showOrHiddenHeaderImageMobile();
});

$(document).on('click', '#site-link-remove-header-image', () => {
  showOrHiddenHeaderImage();
});

$(document).on('click', '#site-link-remove-header-image-mobile', () => {
  showOrHiddenHeaderImageMobile();
});

function showOrHiddenCoorporateImage() {
  const value = $('#career_site_display_options').find(':selected').val();

  if (value !== 'description_image') {
    $('.corporate-image-input').addClass('is-hidden');
  } else {
    $('.corporate-image-input').removeClass('is-hidden');
  }
}

function showOrHiddenCoorporateVideo() {
  const value = $('#career_site_display_options').find(':selected').val();

  if (value !== 'video' && value !== 'description_video') {
    $('.video-input').addClass('is-hidden');
  } else {
    $('.video-input').removeClass('is-hidden');
  }
}

$(document).ready(() => {
  showOrHiddenCoorporateImage();
  showOrHiddenCoorporateVideo();
});

$(document).on('change', '#career_site_display_options', () => {
  showOrHiddenCoorporateImage();
  showOrHiddenCoorporateVideo();
});

$(document).on('change', '#site_favicon_attributes_file', () => {
  $('.preview-container-favicon').toggleClass('is-hidden');
});

$(document).on('change', '#site_list_image_attributes_file', () => {
  $('.preview-container-list-image').toggleClass('is-hidden');
});

$(document).on('change', '#site_opengraph_image_attributes_file', () => {
  $('.preview-container-opengraph-image').toggleClass('is-hidden');
});

$(document).on('change', '#site_header_image_attributes_file', () => {
  $('.preview-container-header-image').toggleClass('is-hidden');
});

$(document).on('click', '.site-form .tabs a.tab', (e) => {
  $('.site-form .tabs a.tab.active').toggleClass('active');
  $('.site-form .description-translation:not(.is-hidden)').toggleClass('is-hidden');
  const descriptionTranslationId = $(e.target).attr('href');
  $(e.target).toggleClass('active');
  $(descriptionTranslationId).toggleClass('is-hidden');
});

$(document).on('change', '.site-form .description-translation select.locale', (e) => {
  $('.site-form .tabs a.tab.active').text($('option:selected', e.target).text());
});

$(document).on('nested:fieldRemoved:translations:not(.remove-workfow)', () => {
  $('.site-form .tabs a.tab.active').remove();
  $('.site-form .description-translation:not(.is-hidden)').toggleClass('is-hidden');

  const $tab = $('.site-form .tabs a.tab').first();
  if ($tab.length) {
    $tab.toggleClass('active');
    $($tab.attr('href')).toggleClass('is-hidden');
  }
});
