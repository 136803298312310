import {
  defineDinamicsTexts, checkedGdprDeleteCandidates,
  showActiveaGdprDeleteCandidates, enabledSaveButton,
  showOrHideEditLegalTextsForm, showOrHideNewLegalTextsForm,
  expandLocaleAmongLegalTexts, showMoreOrLessHistorical,
} from '../../features/companies/legals';

window.Bizneo.legals_controller = {
  index() {
    defineDinamicsTexts();
    checkedGdprDeleteCandidates();
    showActiveaGdprDeleteCandidates();
    enabledSaveButton();
    showMoreOrLessHistorical();
    showOrHideEditLegalTextsForm();
    showOrHideNewLegalTextsForm();
    expandLocaleAmongLegalTexts();
  },
  updateGdpr() {
    showActiveaGdprDeleteCandidates();
    enabledSaveButton();
  },
};

jQuery(() => {
  if ($('.legals-controller-js').length) {
    window.Bizneo.legals_controller.index();
  }
});
