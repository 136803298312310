export function copyToClipboardAction() {
  $.each(['webhook-url', 'webhook-verify-token'], (_index, input) => {
    $(document).on('click', `#copy-${input}`, () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText($(`#${input}`).val()).then(() => {
          $(`#copy-${input} i`).addClass('huge-tick-02').removeClass('huge-copy-01');
          setTimeout(() => {
            $(`#copy-${input} i`).removeClass('huge-tick-02').addClass('huge-copy-01');
          }, 500);
        });
      }
    });
  });
}

export function verificationFlowActions(whatsappBusinessAccountId) {
  $(document).on('click', `a.verification-setup-${whatsappBusinessAccountId}:not(.close-ds-modal)`, (e) => {
    e.preventDefault();
    $(`.verification-setup-${whatsappBusinessAccountId}`).addClass('is-hidden');
    $(`.verification-method-${whatsappBusinessAccountId}`).removeClass('is-hidden');
  });
}
