import {
  dragAndDop, deleteAnswer, fieldTypeChange, currentSelectType, addNewOption,
} from '../../features/companies/requisition_field';

window.Bizneo.requisition_field_controller = {
  index() {
    dragAndDop();
  },
  new() {
    currentSelectType();
    fieldTypeChange();
    deleteAnswer();
    addNewOption();
  },
  edit() {
    currentSelectType();
    deleteAnswer();
    addNewOption();
  },
};

jQuery(() => {
  if ($('.requisition-field-controller-edit-js').length) {
    window.Bizneo.requisition_field_controller.edit();
  }
  if ($('.requisition-field-controller-index-js').length) {
    window.Bizneo.requisition_field_controller.index();
  }
  if ($('.requisition-field-controller-new-js').length) {
    window.Bizneo.requisition_field_controller.new();
  }
});
