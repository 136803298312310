import dragula from 'dragula';

export function actions() {
  $(document).on('click', '.requisition-status-item .action-icons .collapse-text', (e) => {
    e.preventDefault();
    $(e.target).closest('.requisition-status-item').find('form.edit-requisition-status').toggleClass('is-hidden');
  });

  $(document).on('click', 'form.edit-requisition-status .buttons-row .secondary', (e) => {
    e.preventDefault();
    $(e.target).closest('.requisition-status-item').find('form.edit-requisition-status').addClass('is-hidden');
  });
}

export function reorder() {
  dragula([document.getElementsByClassName('settings-requisitions-statuses')[0]], {
    invalid(el) {
      return $(el).hasClass('not_move');
    },
  // eslint-disable-next-line consistent-return
  }).on('drop', (el, container) => {
    if ($(container).length) {
      const elementPosition = $('div.requisition-status-item').index($(el));
      if (elementPosition === 0 || elementPosition === 1) {
        $(el).addClass('error');
        $('.error-move', $(el)).removeClass('is-hidden');
        return false;
      }

      $(el).removeClass('error');
      $('.error-move', $(el)).addClass('is-hidden');
      if ($('div.requisition-status-item.error', container).length >= 1) return false;

      let orderIds = '';

      $('.requisition-status-item', container).each((_, element) => {
        if (orderIds !== '') orderIds += ',';
        orderIds += $(element).data('requisition-status-id');
      });

      $.ajax({
        type: 'POST',
        url: $('#reorder_url').val(),
        data: { requisition_statuses: { order_ids: orderIds } },
        success() {},
        error() {},
      });
    }
  });
}
