import '../../../libs/bootstrap-datepicker/bootstrap-datepicker';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.es';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.es-CL';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.mx';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.fr';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.pt';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.pt-BR';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.hr';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.it';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.tr';
import '../../../libs/bootstrap-datepicker/locales/bootstrap-datepicker.zh-CN';

function recruiterLocale() {
  const locale = $('body').data('locale');

  const localeConversion = {
    mx: 'es',
    zh: 'zh-CN',
  };

  return localeConversion[locale] || locale;
}

function resetMagicBarData() {
  window.set_filters({});
  const $filterPagination = $('.filter-pagination-row');
  $filterPagination.find('.filtering-candidates').removeClass('is-hidden');
  $filterPagination.find('.result-cnt').addClass('is-hidden');
  $('.requisition-self-actions-check input').attr('checked', false);
}

export function cloneRequisition() {
  $(document).on('change', '#requisition_clone_id', (e) => {
    $.ajax({
      type: 'GET',
      url: `/trabajar/${$('#requisition_company_id').val()}/requisitions/clone/new?requisition_clone_id=${$(e.target, 'option:selected').val()}`,
    });
  });
}

export function changeRequisitionTemplate() {
  $(document).on('change', '#requisition_requisition_template_id', (e) => {
    $.ajax({
      type: 'GET',
      url: `/trabajar/${$('#requisition_company_id').val()}/requisitions/template_fields/new?id=${$(e.target, 'option:selected').val()}`,
    });
  });
}

export function resetRadioButton() {
  $(document).on('click', '.radio-reset-value', (e) => {
    $(e.target).closest('.form-radio-cnt').find('input').prop('checked', false);
  });
}

export function detailMembers() {
  $(document).on('click', '.request-show .members', (e) => {
    $(e.target).find('.floating-dropdown').toggle();
  });
}

export function filter() {
  $('#requisition_review_workflow_company_team_id, #requisition_review_workflow_aggregation_name').on('change', () => {
    $('.simple_form.requisition_review_workflow').submit();
  });
}

const datepickerConfiguration = (locale) => ({
  format: 'dd/mm/yyyy',
  language: locale,
  startView: 'months',
  minViewMode: 'days',
  autoclose: true,
});

export function datepicker() {
  $('.datetime').each((index, element) => {
    $(element).datepicker(datepickerConfiguration(recruiterLocale()));
  });
}

function formulaVariables(requisitionFieldId) {
  const variables = { };
  $('input').filter(`[data-formula-variable=${requisitionFieldId}]`).each((_index, element) => {
    const variableValue = $(`#response-${$(element).val()}`).val();
    if (variableValue) {
      variables[$(element).data('formula-variable-name')] = variableValue;
    }
  });
  return variables;
}

export function formula() {
  $('input[data-formula-field-ids]').on('change', (e) => {
    // eslint-disable-next-line array-callback-return
    $(e.target).data('formula-field-ids').map((requisitionFieldId) => {
      const rule = $('input').filter(`[data-formula-rule=${requisitionFieldId}]`).val();
      const variables = formulaVariables(requisitionFieldId);
      const definedVariables = Object.keys(variables).length;
      const numberVariables = $('input').filter(`[data-formula-variable=${requisitionFieldId}]`).length;
      if (definedVariables === numberVariables) {
        $.ajax({
          url: `/trabajar/${$('#requisition_company_id').val()}/requisitions/formulas/${requisitionFieldId}`,
          type: 'GET',
          data: { formula: { rule, variables } },
        });
      } else {
        $(`#response-${requisitionFieldId}`).val('');
        $(`#response-show-${requisitionFieldId}`).val('');
      }
    });
  });
}

export function removeCloneAsset() {
  $(document).on('click', '.remove-clone-asset', (e) => {
    const $this = $(e.target);
    const $parent = $this.closest('.fields');
    $parent.remove();
  });
}

export function highlighterKeywords() {
  const search = window.location.search.substring(1);
  if (search.length) {
    const obj = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
    const r = JSON.parse(decodeURIComponent(obj.requisition));
    if (r.keyword !== undefined) {
      const word = r.keyword[0];
      $('.highlight-js-text').highlight(word, { element: 'mark', className: 'YourHighlightClass' });
    }
  }
}

$(document)
  .on('click', '.conversations .conversations-expand.show-more', (e) => {
    e.preventDefault();
    const $this = $(e.target).closest('a');
    const $parent = $this.closest('.conversations');
    $parent.find('.conversation').addClass('conversation-expanded-info');
    $this.addClass('is-hidden');
    $parent.find('.conversations-expand.show-less').removeClass('is-hidden');
  })
  .on('click', '.conversations .conversations-expand.show-less', (e) => {
    e.preventDefault();
    const $this = $(e.target).closest('a');
    const $parent = $this.closest('.conversations');
    $parent.find('.conversation').removeClass('conversation-expanded-info');
    $parent.find('.conversation:nth-child(-n + 2)').addClass('conversation-expanded-info');
    $this.addClass('is-hidden');
    $parent.find('.conversations-expand.show-more').removeClass('is-hidden');
  }).on('click', '.conversations .messages-expand.show-more', (e) => {
    e.preventDefault();
    const $this = $(e.target).closest('a');
    const $parent = $this.closest('.conversation');
    $parent.find('.message').addClass('message-expanded-info');
    $this.addClass('is-hidden');
    $parent.find('.messages-expand.show-less').removeClass('is-hidden');
  })
  .on('click', '.conversations .messages-expand.show-less', (e) => {
    e.preventDefault();
    const $this = $(e.target).closest('a');
    const $parent = $this.closest('.conversation');
    $parent.find('.message').removeClass('message-expanded-info');
    $parent.find('.message:nth-child(-n + 3)').addClass('message-expanded-info');
    $this.addClass('is-hidden');
    $parent.find('.messages-expand.show-more').removeClass('is-hidden');
  })
  .on('click', '.reset-requisitions-form', (e) => {
    e.preventDefault();
    resetMagicBarData();
  });

$(document).on('submit', '.search-filters-form-requisition', () => {
  const $infoMessage = $('.search-info-message');
  $infoMessage.removeClass('is-hidden');
  $infoMessage.find('.filtering-candidates').removeClass('is-hidden');
  $infoMessage.find('.text').addClass('is-hidden');
});
