const modals = [];

async function loadComponent() {
  const { default: Modal } = await import(/* webpackChunkName: "modal" */ '@bizneo/modal/modal.svelte');

  return Modal;
}

export function open(content) {
  loadComponent().then((Modal) => {
    const modal = new Modal({
      target: document.querySelector('body'),
      props: { content },
    });

    modal.$on('close', () => {
      $('.modal .modal-js-ckeditor').each((index, element) => {
        // eslint-disable-next-line no-undef
        if (CKEDITOR && CKEDITOR.instances[element.id]) CKEDITOR.instances[element.id].destroy();
      });
      modal.$destroy();
      modals.pop();
    });

    modals.push(modal);
    $(modal.getContainer()).trigger('dom-update', { source: 'modal' });
    if ($('.modal').find('.modal-js-ckeditor')) {
      $('.modal.active .modal-js-ckeditor').each((_index, element) => {
        const $this = $(element);
        $this.attr('id', `${element.id}-modal`);
        window.Bizneo.controls.initializeCK(`#${$this.attr('id')}`);
      });
    }
    if ($('.modal').find('.video-js-modal')) {
      $('.video-js-modal', $(modal.getContainer())).each((_index, element) => {
        window.Bizneo.controls.initializeVideoJs(element);
      });
    }
  });
}

export function replace(content) {
  const modal = modals[modals.length - 1];

  if (modal) {
    modal.replace(content);
    setTimeout(() => {
      $(modal.getContainer()).trigger('dom-update', { source: 'modal' });
    }, 0);
  } else {
    open(content);
  }
}

export const close = () => modals[modals.length - 1].close();

window.close_modal = close;
window.open_modal = open;
