function updateUsersCounter($element) {
  const value = $element.parents('.form-wrapper').find('input').val();
  const period = $element.parents('.form-wrapper').find('select').val();
  const type = $element.parents('.form-wrapper').attr('id');
  const company = $('#legal_company_id').val();

  if (value && period && type) {
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: Routes.company_delete_users_counter_index_path(company, { value, period, type }),
      success() {
        $.rails.enableElement($('.show-delete-users-counter'));
      },
    });
  }
}

export function defineDinamicsTexts() {
  $(document).on('change', '.form-wrapper input[id^=legal_], .form-wrapper select[id^=legal_period_]', (e) => {
    const $element = $(e.target);
    const typePeriod = $element.parents('.form-wrapper').attr('id');
    $(`.link-delete-users-counter-${typePeriod}`).removeClass('is-hidden');
    $(`.delete-users-counter-${typePeriod}`).addClass('is-hidden');
  });

  $(document).on('click', '.show-delete-users-counter', (e) => {
    updateUsersCounter($(e.target));
  });
}

export function checkedGdprDeleteCandidates() {
  $(document).on('click', '.gdpr_delete_candidates_checkbox', (e) => {
    if ($(e.target).is(':checked')) {
      $(e.target).parents('.wrapper-status').find('.second-step').removeClass('is-hidden');
    } else {
      $(e.target).parents('.wrapper-status').find('.second-step').addClass('is-hidden');
    }
  });
}

export function showActiveaGdprDeleteCandidates() {
  $('input:checked').parents('.wrapper-status').find('.second-step').removeClass('is-hidden');
}

export function enabledSaveButton() {
  $(document).on('change', '.edit_legal input[id^=legal_]', () => {
    $('.submmit-gdpr-form').removeAttr('disabled');
  });
  $(document).on('change', '.edit_legal select[id^=legal_period_]', () => {
    $('.submmit-gdpr-form').removeAttr('disabled');
  });
}

export function showOrHideEditLegalTextsForm() {
  $(document).on('click', '.collapse-form', (e) => {
    e.preventDefault();
    $(e.target).closest('tr').next().toggleClass('is-hidden');
  });
}

export function showOrHideNewLegalTextsForm() {
  $(document).on('click', '#link-new-translation', () => {
    $('#new_legal_translation').toggleClass('is-hidden');
  });
  $(document).on('click', '.link-cancel-translation', (e) => {
    $(e.target).parents('.legal-translation-form').toggleClass('is-hidden');
  });
}

export function expandLocaleAmongLegalTexts() {
  $(document).on('change', 'select.legal-translation-select-locale', (e) => {
    $('input.locale-selected').val($(e.target).val());
  });
}

export function showMoreOrLessHistorical() {
  $(document).on('click', '.expand-button', (e) => {
    const $parent = $(e.target).closest('.expand-parent');
    const $child = $parent.find('.expanded-info').first();
    const $linkMore = $parent.find('.show-more.link').first();
    const $linkLess = $parent.find('.show-less.link').first();
    const $angle = $parent.find('.show-more-less').first();

    $linkMore.toggle();
    $linkLess.toggle();

    $angle.toggleClass('huge-arrow-up-01');
    $angle.toggleClass('huge-arrow-down-01');

    $child.toggle();
  });
}
