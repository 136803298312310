export function questionTypeChange() {
  $(document).on('change', "input[name='company_question[question_type]']", (e) => {
    const $this = $(e.target);
    const $formInputs = $this.closest('.form-inputs');
    const $fields = $formInputs.find('.new-answer .fields');
    const $link = $this.closest('.cnt').find('.add-fields');
    $formInputs.find('.add-fields').hide();
    $formInputs.find('.new-answer .fields').remove();
    $link.show();
    $fields.insertBefore($link);
  });
}

export function deleteAnswer() {
  $(document).on('click', '.new-killer-question a.remove_nested_fields', (e) => {
    $(e.target).parent().remove();
  });
}

export function edit() {
  $('.company-question-question-type:not(:checked)').each((index, element) => {
    $(element).closest('.cnt').find('.fields').remove();
  });

  $("label[for^='company_question_question_type_'] input:checked").closest('.cnt').find('.add-fields').show();
}
