import { open } from '../../../services/modal';

$(document).on('change', '.requisition-company-team', (e) => {
  const $button = $('#submit-company-question-response-set');
  const $buttonPrevent = $('.prevent-team-submit');
  if (e.target.value.length) {
    $button.removeClass('is-hidden');
    $buttonPrevent.addClass('is-hidden');
  } else {
    $button.addClass('is-hidden');
    $buttonPrevent.removeClass('is-hidden');
  }
}).on('click', '.prevent-team-submit', (e) => {
  e.preventDefault();
  const $form = $('.new-candidates-form form');

  if (!$form[0].checkValidity() || $('.requisition-company-team').val().length) {
    $form.find(':submit').click();
  } else {
    open($('#modal-new-candidates-form').html(), $(e.target).parents('body')[0]);
  }
}).on('click', '.submit-company-question-response-set', (e) => {
  e.preventDefault();
  const $this = $(e.target);

  $this.addClass('disabled').text($this.data('disable-with'));

  $('.prevent-team-submit').addClass('is-hidden');
  $('#submit-company-question-response-set').removeClass('is-hidden').click();
});
