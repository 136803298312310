function statusOptions(result) {
  const emptyOption = "<option value=''></option>";
  const options = result.reduce(
    (acc, data) => `${acc}<option value='${data[1]}'>${data[0]}</option>`,
    emptyOption,
  );

  return options;
}

function fetchStatusesUrl(companyId, phaseId) {
  return `/trabajar/${companyId}/recruitment_process/phases/${phaseId}/statuses`;
}

$(document).on('change', '#qualifier_requirement_phase_id', (e) => {
  const value = $(e.target).val();

  if (value) {
    const companyId = $('#new-qualifier-requirement-modal-form').data().company.id;

    $.ajax({
      type: 'GET',
      url: fetchStatusesUrl(companyId, value),
      dataType: 'json',
      success(result) {
        const options = statusOptions(result);

        $('.modal.active').find('#qualifier_requirement_status_id').html(options);
        $('.modal.active').find('#qualifier_requirement_status_id').chosen('destroy');
        $('.modal.active').find('#qualifier_requirement_status_id').chosen();
      },
    });
  }
});

$(document).on('change', '#requisition-finalist-phase-id', (e) => {
  const value = $(e.target).val();

  if (value) {
    const companyId = $('#new-finalist-phase-status-modal-form').data().company.id;

    $.ajax({
      type: 'GET',
      url: fetchStatusesUrl(companyId, value),
      dataType: 'json',
      success(result) {
        const options = statusOptions(result);

        const statusIdSelector = $('.settings-finalist-phase-statuses-form').find('#requisition-finalist-status-id');
        statusIdSelector.html(options);
        statusIdSelector.chosen('destroy');
        statusIdSelector.chosen();
      },
    });
  }
});
