import fetchStatuses from '../../features/companies/recruitment_process_workflow_messages';

window.Bizneo.recruitment_process_workflow_messages_controller = {
  new() {
    fetchStatuses();
  },
};

jQuery(() => {
  if ($('.recruitment-process-workflow-messages-controller-js').length) {
    window.Bizneo.recruitment_process_workflow_messages_controller.new();
  }
});
