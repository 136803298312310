export default function waitForPassRatePromise(promiseName) {
  return new Promise(resolve => {
    const checkPromise = () => {
      if (window.passRatePromises && window.passRatePromises[promiseName]) {
        resolve(window.passRatePromises[promiseName]);
      } else {
        setTimeout(checkPromise, 500);
      }
    };
    checkPromise();
  });
}
