/* eslint-disable */
import Confirm from '@bizneo/confirm/confirm.svelte';

let initPreventData;

$(() => {
  if ($('.prevent-form-out').length) {
    initPreventData = $('.prevent-form-out').serialize();
    if (CKEDITOR.instances) {
      CKEDITOR.on("instanceReady", (e)  =>{
        $.each( CKEDITOR.instances, ( key ) => {
          CKEDITOR.instances[key].updateElement();
        });
        if (!e.editor.element.$.classList.contains('modal-js-ckeditor')) {
          initPreventData = $('.prevent-form-out').serialize();
        }
      });
    }
  }
});

$(document).on('click', '.menu-link:not(.link-submenu), .module-nav .tab-item, .logo, .return-link', (event) => {
  if ($('.prevent-form-out').length) {
    event.preventDefault();
    const $el = $(event.target);
    const href = event.target.tagName === 'a' ? $el.attr('href') : $el.closest('a').attr('href');
    const {
      icon, title, description, ok, cancel,
    } = $('.prevent-form-out').data();

    if (CKEDITOR.instances) {
      $.each( CKEDITOR.instances, ( key ) => {
        CKEDITOR.instances[key].updateElement();
      });
    }

    if (initPreventData === $('.prevent-form-out').serialize()) {
      window.location.href = href;
    } else {
      const data = {
        icon,
        confirm: title,
        description,
        ok,
        cancel,
      };
      const confirm = new Confirm({
        target: $el.parents('body')[0],
        props: data,
      });

      confirm.$on('close', () => confirm.$destroy());
      confirm.$on('confirm', () => {
        window.location.href = href;
      });
    }
  }
});
