export function recruitmentSourcesTimeoutError() {
  if (!$('body').hasClass('reports-page-recruitment_sources')) { return; }

  $('.recruitment-source-data').addClass('is-hidden');
  $('.report-data-timeout a').each((_, item) => {
    const itemUrl = $(item).attr('data-to');
    if (itemUrl !== undefined) {
      const newItemUrl = `${itemUrl.split('?')[0]}?${$('form.recruitment-sources-form').serialize()}`;
      $(item).attr('data-to', newItemUrl);
    }
  });
  $('.report-data-loading').addClass('is-hidden');
  $('.report-data-timeout').removeClass('is-hidden');
}

export function requisitionsTimeoutError() {
  if (!$('body').hasClass('reports-page-requisitions')) { return; }

  $('.requisition-report').addClass('is-hidden');
  $('.report-data-timeout a').each((_, item) => {
    const itemUrl = $(item).attr('data-to');
    if (itemUrl !== undefined) {
      const newItemUrl = `${itemUrl.split('?')[0]}?${$('form.requisitions-form').serialize()}`;
      $(item).attr('data-to', newItemUrl);
    }
  });
  $('.report-data-loading').addClass('is-hidden');
  $('.report-data-timeout').removeClass('is-hidden');
}

export function reportAjaxError(report) {
  $(document).ajaxError((a, request) => {
    if (request.statusText !== 'timeout') { return; }

    switch (report) {
      case 'recruitment_sources':
        recruitmentSourcesTimeoutError();
        break;
      case 'requisitions':
        requisitionsTimeoutError();
        break;
      default:
        break;
    }
  });
}

function waitForReportsChannel() {
  return new Promise(resolve => {
    const check = () => {
      if (window.reportsChannelConnected) {
        resolve();
      } else {
        setTimeout(check, 100);
      }
    };
    check();
  });
}

export function reportsData() {
  const companySlug = window.location.pathname.split('/')[2];

  if ($('body').hasClass('reports-page-recruitment_sources')) {
    reportAjaxError('recruitment_sources');
    $.ajax({
      type: 'GET',
      url: `/trabajar/${companySlug}/reports/recruitment_sources`,
      timeout: 30000,
      dataType: 'script',
      data: $('form.recruitment-sources-form').serialize(),
      success() {},
    });
  } else if ($('body').hasClass('reports-page-requisitions')) {
    reportAjaxError('requisitions');
    $.ajax({
      type: 'GET',
      url: `/trabajar/${companySlug}/reports/requisitions`,
      timeout: 30000,
      dataType: 'script',
      data: $('form.requisitions-form').serialize(),
      success() {},
    });
  } else if ($('body').hasClass('reports-page-pass_rate')) {
    waitForReportsChannel().then(() => {
      $.ajax({
        type: 'GET',
        url: `/trabajar/${companySlug}/reports/pass_rate`,
        timeout: 30000,
        dataType: 'script',
        data: $('form.pass-rate-form').serialize(),
        success() {},
        error() {},
      });
    });
  } else if ($('body').hasClass('reports-page-gender')) {
    waitForReportsChannel().then(() => {
      $.ajax({
        type: 'GET',
        url: `/trabajar/${companySlug}/reports/pass_rate/gender`,
        timeout: 30000,
        dataType: 'script',
        data: $('form.pass-rate-form').serialize(),
        success() {},
        error() {},
      });
    });
  }
}
