import _ from 'lodash';

import dragula from 'dragula';
import { open } from '../services/modal';
import {
  changeConditionalType, changeConditionalField, changeConditionalOptions,
  changeSystemOrCustomField, loadConditionalFields, removeRelatedConditionalFields,
  removeConditionalOptions, conditionalRemoveFieldConfirmation,
  conditionalRemoveOptionsConfirmation, conditionalTooltip,
  updateModalFieldRemoveConfirmationMessage,
} from './chat_bot/conditionals';

$(document).on('change', '#chat_bot_chat_locale', (e) => {
  const $this = $(e.target);

  $.ajax({
    type: 'GET',
    dataType: 'script',
    data: { chat_bot_gdpr: { locale: $this.val() } },
    url: $this.data('url'),
    success() { },
    error() { },
  });
});

$(document).on('change', '#gdpr', (e) => {
  const $this = $(e.target);

  $('.modal.active .inner-card').html($this.find(':selected').data('chatBotCheckbox'));
});

$(document).on('change', '#chat_bot_chat_button_color', (e) => {
  const $this = $(e.target);

  $('#gdpr-modal .accept-button button').css({ 'background-color': $this.val(), 'border-color': $this.val() });
});

$(document).on('change', '#chat_bot_chat_globe_color', (e) => {
  const $this = $(e.target);

  $('#gdpr-modal .legal-gdpr .inner-card').css({ 'background-color': $this.val() });
});

$(document).on('change', '.switch_enabled', (e) => {
  const $this = $(e.target);
  const checked = $this.is(':checked');

  $.ajax({
    type: 'PUT',
    dataType: 'script',
    data: { copy_chat_bot: { checked } },
    url: $this.data('url'),
    success() { },
    error() { },
  });
});

$(document).on('change', '#copy_chat_bot_create_option', (e) => {
  const $this = $(e.target);

  $('.submit-new-chat-bot').addClass('is-hidden');
  $('.submit-copy-chat-bot').addClass('is-hidden');
  $('.copy-chat-bot').addClass('is-hidden');

  if ($this.val() === 'new') {
    $('.submit-new-chat-bot').removeClass('is-hidden');
    $('.modal.active #copy_chat_bot_category_id').val('');
    $('.modal.active #copy_chat_bot_chat_id').val('');
    $('.copy-chat-bot').trigger('dom-update');
  }

  if ($this.val() === 'copy') {
    $('.copy-chat-bot').removeClass('is-hidden');
  }
});

$(document).on('change', '#copy_chat_bot_category_id', (e) => {
  const $this = $(e.target);
  $('.submit-copy-chat-bot').addClass('is-hidden');

  if ($this.val()) {
    $('.modal.active #copy_chat_bot_chat_id').prop('disabled', false);
    $('.modal.active #copy_chat_bot_chat_id').html('<option value="" selected></option>');

    $(`.modal.active #copy_chat_bot_hidden_chat_id option[data-category-id="${$this.val()}"]`).each((_index, element) => {
      const $newOption = $(element).clone();
      $('.modal.active #copy_chat_bot_chat_id').append($newOption);
    });

    $('.select-chat-bots').trigger('dom-update');
  }
});

$(document).on('change', '#copy_chat_bot_chat_id', () => {
  $('.submit-copy-chat-bot').removeClass('is-hidden');
});

const conditionalFieldValidMove = (conditionalFieldId, sibling) => {
  if (sibling === null) return true;

  const conditionalFieldIdentifier = `chat-bot-field-${conditionalFieldId}`;
  let conditionalFieldPosition = document.getElementById(conditionalFieldIdentifier).getElementsByClassName('hidden-fields')[0].querySelector('input.position').value;
  conditionalFieldPosition = parseInt(conditionalFieldPosition, 10);
  let currentFieldPosition = sibling.getElementsByClassName('hidden-fields')[0].querySelector('input.position').value;
  currentFieldPosition = parseInt(currentFieldPosition, 10);
  return currentFieldPosition > conditionalFieldPosition;
};

const fieldWithOptionsValidMove = (relatedFields, sibling) => {
  if (sibling === null) return false;

  const fieldPositions = [];
  relatedFields.forEach((relatedFieldId) => {
    const conditionalFieldIdentifier = `chat-bot-field-${relatedFieldId}`;
    let conditionalFieldPosition = document.getElementById(conditionalFieldIdentifier).getElementsByClassName('hidden-fields')[0].querySelector('input.position').value;
    conditionalFieldPosition = parseInt(conditionalFieldPosition, 10);
    fieldPositions.push(conditionalFieldPosition);
  });
  let currentFieldPosition = sibling.getElementsByClassName('hidden-fields')[0].querySelector('input.position').value;
  currentFieldPosition = parseInt(currentFieldPosition, 10);

  return fieldPositions.every(fieldPosition => fieldPosition >= currentFieldPosition);
};

const dragAndDropFields = () => {
  dragula([document.getElementsByClassName('fields-ordered')[0]], {
    accepts(el, _target, _source, sibling) {
      if (el.id === sibling?.id) return true;

      // Javascript does not catch data update, that's why JQuery is used
      const conditionalFields = $('.chat-bot-fields-table').data('conditional-fields');

      if (!_.size(conditionalFields)) return true;

      const fieldId = parseInt(el.id.split('-').pop(), 10);

      const relatedFields = [];
      Object.entries(conditionalFields).forEach(([relatedFieldId, conditionalFieldId]) => {
        if (fieldId === conditionalFieldId) relatedFields.push(relatedFieldId);
      });

      const conditionalFieldId = conditionalFields[fieldId];

      if (!relatedFields.length && conditionalFieldId === undefined) return true;

      if (!relatedFields.length && conditionalFieldId !== undefined) {
        return conditionalFieldValidMove(conditionalFieldId, sibling);
      }

      if (relatedFields.length && conditionalFieldId === undefined) {
        return fieldWithOptionsValidMove(relatedFields, sibling);
      }

      return conditionalFieldValidMove(conditionalFieldId, sibling) &&
             fieldWithOptionsValidMove(relatedFields, sibling);
    },
  // eslint-disable-next-line consistent-return
  }).on('drop', () => {
    $('.chat-bot-fields-table .chat-bot-item.ordered').not('.is-hidden').each((index, element) => {
      $(element).find('.hidden-fields input.position').val(index);
    });
  });
};

const modalAutoOpen = ($el) => {
  const localS = localStorage.getItem($el.attr('id'));
  if (localS === null) {
    const body = $el.parents('body')[0];

    setTimeout(() => {
      open($el.html(), body);
    }, 500);
  }
};

const calculateScore = () => {
  const $enableModal = $('#new-chat-bot-created');
  if ($enableModal.length) modalAutoOpen($enableModal);

  if ($('.chat-bot-fields').length > 0) {
    let totalWeight = 0;
    $('.chat-bot-item.with-options span.percentage').each((_index, element) => {
      totalWeight += parseInt($(element).attr('data-weight'), 10);
    });

    $('.chat-bot-item.with-options span.percentage').each((_index, element) => {
      const fieldWeight = parseInt($(element).attr('data-weight'), 10);
      const percentage = Math.round((100 * fieldWeight) / totalWeight);

      $(element).html(`${percentage}%`);
    });
  }
};

const insufficientCreditsModal = () => {
  const $enableModal = $('#insufficient-credits-modal');
  if ($enableModal.length) modalAutoOpen($enableModal);
};

const aiChatBotErrorModal = () => {
  const $enableModal = $('#ai-new-chatbot-modal.open-modal-with-errors');
  if ($enableModal.length) modalAutoOpen($enableModal);
};

$(document).ready(() => {
  calculateScore();
  dragAndDropFields();
  changeConditionalType();
  changeConditionalField();
  changeConditionalOptions();
  changeSystemOrCustomField();
  insufficientCreditsModal();
  aiChatBotErrorModal();
});

$(document).on('click', '#chat-bot-upload-avatar', (e) => {
  e.preventDefault();
  $('#chat_bot_chat_avatar').trigger('click');
});

$(document).on('change', '#chat_bot_chat_avatar', (e) => {
  const file = $(e.target).get(0).files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      $('#chat-bot-avatar-preview').attr('src', event.target.result);
    };
    reader.readAsDataURL(file);

    $('#chat-bot-avatar-preview').removeClass('is-hidden');
  }
});

$(document).on('click', '.open-field-form', () => {
  $('.chat-bot-settings').addClass('is-hidden');
  $('.chat-bot-return-link').addClass('is-hidden');

  $('.field-form-return-link').removeClass('is-hidden');
  $('.new-field-forms').removeClass('is-hidden');
});

$(document).on('click', '.close-field-form', () => {
  $('.chat-bot-settings').removeClass('is-hidden');
  $('.chat-bot-return-link').removeClass('is-hidden');

  $('.field-form-return-link').addClass('is-hidden');
  $('.new-field-forms').addClass('is-hidden');

  window.Bizneo.controls.removeCK('.new-field-forms .js-ckeditor');

  $('.new-field-forms').html('');
});

$(document).on('click', '.field-option .remove_nested_fields', (e) => {
  const $optionContent = $(e.currentTarget).closest('div.field-option');
  const optionId = $optionContent.prop('id').split('option-')[1];
  const inputOptionId = $("<input type='hidden' multiple />")
    .attr('id', 'removed_option_ids')
    .attr('name', 'removed_option_ids[]')
    .val(optionId);

  if (!$(e.currentTarget).data('new-option')) {
    $optionContent.addClass('is-hidden');
    $optionContent.find('.option-weight input').prop('required', false);
    $optionContent.find('.destroy').val('1');
    $optionContent.append(inputOptionId);
  } else if ($optionContent.hasClass('edit-option')) {
    $optionContent.replaceWith(inputOptionId);
  } else {
    $optionContent.remove();
  }

  $('form #field-options .field-option').not('.is-hidden').each((index, element) => {
    $(element).find('.field-option-position').val(index);
  });
});

$(document).on('click', '.add-field-assets .remove_nested_fields', (e) => {
  $(e.target).closest('div.inner-card').addClass('is-hidden');
  $(e.target).closest('div.inner-card').find('.destroy').val('1');
});

$(document).on('click', '#chat-bot-remove-asset', (e) => {
  const $this = $(e.target);
  const $form = $this.closest('form');

  $form.find('#candidate_questions_field_video_url').val('');
  $form.find('#candidate_questions_field_gif_url').val('');
  $form.find('.add-field-assets').toggleClass('is-hidden');
});

$(document).on('click', '#chat-bot-add-asset', (e) => {
  const $this = $(e.target);

  $this.closest('form').find('.add-field-assets').toggleClass('is-hidden');
});

$(document).on('click', '.modal.active .candidate-field .form-check.in-modal label, .edit-modal .edit-option .form-check.in-modal label', (e) => {
  const $element = $(`.modal.active #${$(e.target).attr('for')}`);

  if ($element.prop('checked')) {
    $element.prop('checked', false);
    $element.closest('.custom-form-check').removeClass('checked');
  } else {
    $element.prop('checked', true);
    $element.closest('.custom-form-check').addClass('checked');
  }
});

$(document).on('change', '.feedback-to-candidate-field', (e) => {
  const $this = $(e.target);
  const $feedbackElement = $(`.${$this.attr('id')}-message`);

  if ($this.prop('checked')) {
    $feedbackElement.removeClass('is-hidden');
  } else {
    $feedbackElement.addClass('is-hidden');
    // eslint-disable-next-line no-undef
    if (CKEDITOR !== undefined) {
      const modalId = $feedbackElement.find('.js-ckeditor').attr('id');
      // eslint-disable-next-line no-undef
      CKEDITOR.instances[modalId].setData('');
    }
  }
});

$(document).on('click', '.add-tags', (e) => {
  const $parent = $(e.target).closest('.option-tags');

  $parent.find('.tag-select').toggleClass('is-hidden');
});

$(document).on('change', '.save-to-candidate-field', (e) => {
  const $this = $(e.target);
  const $selectParent = $(`.${$this.attr('id')}-select`);
  const $selectElement = $(`.${$this.attr('id')}-select select`);

  const $form = $this.closest('form');

  if ($this.prop('checked')) {
    $selectParent.removeClass('is-hidden');
    $this.closest('.custom-form-check').addClass('checked');
    const fieldId = $this.closest('.candidate-field').attr('data-field_id');
    const option = $form.find('#candidate_questions_field_type :selected');

    if (option.val()) {
      $.ajax({
        type: 'GET',
        dataType: 'script',
        data: {
          candidate_fields: {
            field_type: option.val(),
            field_id: fieldId,
          },
        },
        url: $('#candidate_field_url').val(),
        success() { },
        error() { },
      });
    }
  } else {
    $selectParent.addClass('is-hidden');
    $selectElement.val('');

    $form.find('.matching-field-select .matching-field-system-field select').find('option').remove();
    $form.find('.matching-field-select .matching-field-custom-field select').find('option').remove();

    $form.find('.matching-field-select .matching-field-system-field').addClass('is-hidden');
    $form.find('.matching-field-select .matching-field-custom-field').addClass('is-hidden');

    $form.find('.matching-field-select').addClass('is-hidden');
  }
  setTimeout(() => {
    window.Bizneo.dom.update('.candidate-field');
  }, 500);
});

$(document).on('change', '.candidate-field-select', (e) => {
  const $this = $(e.target);
  const $form = $this.closest('form');

  const selectedValue = $this.val();
  const fieldTypeValue = $form.find('#candidate_questions_field_type :selected').val();

  if (selectedValue && (fieldTypeValue === 'CandidateQuestions::Fields::RadioType' ||
    fieldTypeValue === 'CandidateQuestions::Fields::SelectType' ||
    fieldTypeValue === 'CandidateQuestions::Fields::MultiSelectType')) {
    const fieldId = $this.closest('.candidate-field').attr('data-field_id');
    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: {
        candidate_options: {
          field_type: fieldTypeValue,
          field_id: fieldId,
          candidate_field: selectedValue,
        },
      },
      url: $('#candidate_options_url').val(),
      success() { },
      error() { },
    });
  }
});

$(document).on('change', '#candidate_questions_field_type', (e) => {
  const $this = $(e.target);
  const $form = $this.closest('form');

  $form.find('.link-to-add-options, .field-mandatory').addClass('is-hidden');
  const fieldId = $form.find('.candidate-field').attr('data-field_id');

  if ($this.val() === 'CandidateQuestions::Fields::RadioType' ||
    $this.val() === 'CandidateQuestions::Fields::SelectType' ||
    $this.val() === 'CandidateQuestions::Fields::MultiSelectType') {
    $.ajax({
      type: 'GET',
      dataType: 'script',
      data: {
        candidate_questions_field_option: {
          field_type: $this.val(),
          field_id: fieldId,
          new_option: true,
          change_type: true,
        },
      },
      url: $('#option_url').val(),
      success() {
        if ($this.val() === 'CandidateQuestions::Fields::SelectType' ||
          $this.val() === 'CandidateQuestions::Fields::MultiSelectType') {
          $form.find('.link-to-add-options').removeClass('is-hidden');
        }
      },
      error() { },
    });
  } else {
    if ($this.val() === 'CandidateQuestions::Fields::FileType') {
      $form.find('.field-mandatory').removeClass('is-hidden');
    } else {
      $form.find('.field-mandatory').addClass('is-hidden');
    }
    $form.find('.for-field-with-options .field-option').remove();
    $form.find('.for-field-with-options').addClass('is-hidden');
  }

  if ($this.val()) {
    $form.find('.candidate-field').removeClass('is-hidden');
    $form.find('.candidate-field .form-check').removeClass('checked');
    $form.find('.candidate-field .save-to-candidate-field').prop('checked', false);
    $form.find('.candidate-field .row:last').html('');
    $form.find('.candidate-field .row:last').addClass('is-hidden');
  }
});

$(document).on('change', '#test-modal-site-id', (e) => {
  const $this = $(e.target);

  const $link = $this.closest('div.modal').find('#test-modal-open-link');
  const siteId = $this.val();
  const testURL = new URL($link.prop('href'));
  testURL.searchParams.set('site_id', siteId);
  const baseTranslation = $link.data('text');
  const siteTranslation = baseTranslation.replace('{{site_name}}', $this.find('option:selected').text());
  $link.prop('href', testURL.href.replace(testURL.origin, ''));
  $link.find('span').text(siteTranslation);
});

$(document).on('click', '.advaced-options.accordion .accordion-head b', (e) => {
  const $this = $(e.target);
  const $accordion = $this.closest('.accordion-head');
  let advacedOptionsText = '';
  if ($accordion.hasClass('collapsed')) {
    advacedOptionsText = $accordion.data('collapsed');
  } else {
    advacedOptionsText = $accordion.data('open');
  }
  $this.text(advacedOptionsText);
});

$(document).on('change', '.for-field-with-options .candidate_questions_field_weight input', (e) => {
  const $this = $(e.target);

  const options = $('form #field-options .field-option').not('.is-hidden');
  if (options.length) {
    const firstOptionIsHidden = options[0].getElementsByClassName('option-weight')[0].classList.contains('is-hidden');
    if ($this.val() && firstOptionIsHidden) {
      const inputs = $('.option-weight input', options);
      inputs.val('0');
      inputs.prop('required', true);
      $('.option-weight', options).removeClass('is-hidden');
    } else if (!$this.val() && !firstOptionIsHidden) {
      $('.option-weight', options).addClass('is-hidden');
      const inputs = $('.option-weight input', options);
      inputs.val('');
      inputs.prop('required', false);
    }
  }
});

$(document).on('change', '.new-field-forms select.option-tag-ids', (e) => {
  const selectedTags = $(e.target).val();
  const includedTags = [];
  const $optionTags = $(e.target).closest('.option-tags').find('.option-tags-attributes');
  $optionTags.find('input.option-tag-tag-id').each((_index, option) => {
    const tagValue = $(option).val();
    const $fields = $(option).closest('.fields');
    if (selectedTags.includes(tagValue)) {
      $fields.find('input.option-tag-destroy').val('0');
      includedTags.push(tagValue);
    } else if ($fields.find('input.option-tag-id').length) {
      $fields.find('input.option-tag-destroy').val('1');
    } else {
      $fields.remove();
    }
  });

  const optionId = $(e.target).closest('.field-option').prop('id').split('option-')[1];
  _.difference(selectedTags, includedTags).forEach((tagId) => {
    const $fields = $("<div class='fields' />");
    const optionTagIndex = $optionTags.find('.fields').length;
    const $inputTagId = $("<input type='hidden' />")
      .attr('id', `candidate_questions_field_options_attributes_${optionId}_option_tags_attributes_${optionTagIndex}_tag_id`)
      .attr('name', `candidate_questions_field[options_attributes][${optionId}][option_tags_attributes][${optionTagIndex}][tag_id]`)
      .attr('class', 'option-tag-tag-id')
      .val(tagId);
    $fields.append($inputTagId);
    $optionTags.append($fields);
  });
});

const reloadTable = () => {
  $('form .chat-bot-fields-table .chat-bot-item.ordered').not('.is-hidden').each((index, element) => {
    $(element).find('input.position').val(index);
  });

  let totalWeight = 0;
  $('.chat-bot-item.with-options:not(.is-hidden) span.percentage').each((_index, element) => {
    totalWeight += parseInt($(element).data('weight'), 10);
  });

  $('.chat-bot-item.with-options:not(.is-hidden) span.percentage').each((_index, element) => {
    const fieldWeight = parseInt($(element).data('weight'), 10);
    const percentage = Math.round((100 * fieldWeight) / totalWeight);

    $(element).html(`${percentage}%`);
  });

  $('.chat-bot-settings').removeClass('is-hidden');
  $('.new-field-forms').addClass('is-hidden');
  window.Bizneo.controls.removeCK('.new-field-forms .js-ckeditor');
  $('.new-field-forms').html('');
};

$(document).on('submit', 'form.ai_chat_bot', () => {
  $('.new-chat-bot-ia-modal-form-wrapper').addClass('is-hidden');
  $('.modal-viewport').addClass('not-click').find('.close-button').remove();
  $('.modal-footer').remove();
  $('.modal-content').addClass('submitting');
  $('.new-chat-bot-ia-modal-wrapper').removeClass('is-hidden');
});

window.chatBotSettings = {
  reloadTable() { reloadTable(); },
  loadConditionalFields(fieldId, conditionalFieldId, conditionalOptionIds) {
    loadConditionalFields(fieldId, conditionalFieldId, conditionalOptionIds);
  },
  removeRelatedConditionalFields(fieldId, newRecord) {
    removeRelatedConditionalFields(fieldId, newRecord);
  },
  removeConditionalOptions(fieldId, removedOptionIds) {
    removeConditionalOptions(fieldId, removedOptionIds);
  },
  updateModalFieldRemoveConfirmationMessage(fieldId) {
    updateModalFieldRemoveConfirmationMessage(fieldId);
  },
  conditionalRemoveFieldConfirmation(fieldId) { conditionalRemoveFieldConfirmation(fieldId); },
  conditionalRemoveOptionsConfirmation(fieldId) { conditionalRemoveOptionsConfirmation(fieldId); },
  conditionalTooltip(fielId, conditionalFieldId, conditionalOptionIds) {
    conditionalTooltip(fielId, conditionalFieldId, conditionalOptionIds);
  },
};
