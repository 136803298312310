import consumer from './consumer';
import {
  successFunnelReport, successRecruitersReport, successSourcesReport,
  errorFunnelReport, errorRecruitersReport, errorSourcesReport,
} from './reports/pass_rate';

import {
  successGenderRecruitersReport, errorGenderRecruitersReport,
  successGenderSourcesReport, errorGenderSourcesReport,
} from './reports/pass_rate_gender';

if (document.querySelector('.reports-page-pass_rate') || document.querySelector('.reports-page-gender')) {
  window.reportsChannelConnected = false;
  const currentCompanyId = document.querySelector('meta[name="current-company-id"]').content;

  consumer.subscriptions.create(
    {
      channel: 'ReportsChannel',
      company_id: currentCompanyId,
    },
    {
      connected() {
        window.reportsChannelConnected = true;
        $('.report-connection-error').addClass('is-hidden');
      },
      disconnected() {
        $('.report-connection-error').removeClass('is-hidden');
        window.reportsChannelConnected = false;
      },
      received(data) {
        if (data.status === 'success') {
          if (data.action === 'pass_rate_funnel') { successFunnelReport(data); }
          if (data.action === 'pass_rate_recruiters') { successRecruitersReport(data); }
          if (data.action === 'pass_rate_sources') { successSourcesReport(data); }
          if (data.action === 'pass_rate_gender_recruiters') { successGenderRecruitersReport(data); }
          if (data.action === 'pass_rate_gender_sources') { successGenderSourcesReport(data); }
        } else {
          if (data.action === 'pass_rate_funnel') { errorFunnelReport(); }
          if (data.action === 'pass_rate_recruiters') { errorRecruitersReport(); }
          if (data.action === 'pass_rate_sources') { errorSourcesReport(); }
          if (data.action === 'pass_rate_gender_recruiters') { errorGenderRecruitersReport(); }
          if (data.action === 'pass_rate_gender_sources') { errorGenderSourcesReport(); }
        }
      },
    },
  );
}
