/* eslint max-len: ["error", { "code": 330 }] */
import { open, close } from './modal';

const SELECTOR = '[data-modal-content]';

$(document).on('click', `${SELECTOR}`, (e) => {
  e.preventDefault();
  const $el = $(e.target).data().content === undefined ? $(e.target).closest('[data-modal-content]') : $(e.target);
  if (!$el.hasClass('is-disabled')) {
    const { target } = $el.data();
    const body = $el.parents('body')[0];

    $(target).find('select:not(.no-decorator):not(.flatpickr-monthDropdown-months)').chosen('destroy');
    setTimeout(() => {
      if ($(target).html() !== undefined) open($(target).html(), body);
    }, 500);
  }
});

$(document).on('dom-update', (e, data) => {
  const $el = $(e.target);

  if (data !== undefined && data.source === 'modal') {
    $('.modal-body').find('select:not(.no-decorator):not(.flatpickr-monthDropdown-months)').chosen();

    if ($el.find('#addTeamsModalBody').length) $el.addClass('not-overflow');

    if ($el.find('.recruitment_group_source_ids').length > 0) {
      window
        .Bizneo
        .recruitment_sources_suggester_controller
        .initialize_recruitment_sources_suggesters();
    }

    $('.modal-body').find('select:not(.no-decorator):not(.flatpickr-monthDropdown-months)').chosen();
  }

  if ($el.attr('id') === 'ownAccountModal') {
    const body = $el.parents('body')[0];
    const dataId = $(data.html).attr('id');
    if (dataId !== 'new-channel') {
      open(
        `<div class="affiliated-body" id="affiliated-body">${$el.html()}${data.html}</div>`,
        body,
      );
    } else {
      open(`<div id="affiliated-body">${data.html}</div>`, body);
    }
  }
});

$(document).on('submit', '#affiliated-body form, #new-qualifier-modal-form', () => {
  $(document).keydown((e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
}).on('click', '.close-ds-modal', () => {
  close();
}).on('submit', '.requisition_review_phase, .new_requisition_review_phase, .edit_requisition_review_phase, .candidate-registration-field-form, .recruitment-groups-form, .requisition-phase-rule-conditions, .requisition-phase-group-rules, .modal-shared-candidates-field-form, .modal-shared-candidates-section-form', () => {
  setTimeout(() => { close(); }, 500);
});

$(document).on('click', '#test-modal-open-link', () => {
  setTimeout(() => { close(); }, 500);
});
