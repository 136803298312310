import actions from '../../features/companies/assessments';

window.Bizneo.assessments_controller = {
  index() {
    actions();
  },
};

jQuery(() => {
  if ($('.assessments-controller-js').length) {
    window.Bizneo.assessments_controller.index();
  }
});
