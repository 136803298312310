import {
  showStepOne, checkSelectType, changeSelectType,
  clickToStepTwo, deleteOptions, showStepTwo,
  navTranslationsTabs, enableOrDisableSectionSelect,
  changeSelectLocale, changeCustomFieldDefaultTranslation,
  removeCustomFieldTranslation, validateEditCustomFieldTranslations,
} from '../../features/companies/custom_fields';

window.Bizneo.custom_fields_controller = {
  new() {
    showStepOne();
    checkSelectType();
    changeSelectType();
    clickToStepTwo();
    deleteOptions();
    enableOrDisableSectionSelect();
    changeSelectLocale();
  },
  create() {
    showStepTwo();
    deleteOptions();
  },
  edit() {
    showStepTwo();
    checkSelectType();
    deleteOptions();
    enableOrDisableSectionSelect();
    changeSelectLocale();
  },
  translations() {
    navTranslationsTabs();
    changeCustomFieldDefaultTranslation();
    removeCustomFieldTranslation();
    validateEditCustomFieldTranslations();
  },
};

jQuery(() => {
  if ($('.custom-fields-controller-edit-js').length) {
    window.Bizneo.custom_fields_controller.edit();
  }
  if ($('.custom-fields-controller-edit-translations-js').length) {
    window.Bizneo.custom_fields_controller.translations();
  }
  if ($('.custom-fields-controller-new-js').length) {
    window.Bizneo.custom_fields_controller.new();
  }
  if ($('.custom-fields-controller-new-errors-js').length) {
    window.Bizneo.custom_fields_controller.create();
  }
});
