import dragula from 'dragula';

window.Bizneo.qualifiers = {
  edit() {
    dragula([document.getElementById('qualifier_container')], {
      invalid(el, handle) {
        return handle.classList.contains('handle');
      },
    // eslint-disable-next-line consistent-return
    }).on('drop', (el, container) => {
      if (container) {
        let orderIds = '';
        $('.qualifier-item', container).each((index, element) => {
          if (orderIds !== '') {
            orderIds += ',';
          }
          orderIds += $(element).data('qualifier-id');
        });
        $.ajax({
          type: 'GET',
          url: $('#reorder_url').val(),
          data: { qualifier: { order_ids: orderIds } },
          success() {},
          error() {},
        });
      }
    });
  },
};

jQuery(() => {
  if ($('.qualifiers-js').length) {
    window.Bizneo.qualifiers.edit();
  }
});
